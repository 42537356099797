/* eslint-disable no-bitwise */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Row, Col, ButtonToolbar } from 'react-bootstrap';
import { getFormValues } from 'redux-form/immutable';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { FaTrash } from 'react-icons/fa';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import formMessages from 'components/ReduxForm/messages';
import render from 'components/ReduxForm/renderField';
import layoutStyle from '../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import validations from '../../ReduxForm/validations';
import messages from './messages';
import s from './EditForm.scss';
import Loading from '../../Loading';

const validate = values => {
  const errors = {};
  if (!validations.required(values.name)) {
    errors.name = <FormattedMessage {...formMessages.required} />;
  }
  return errors;
};

class EditForm extends React.Component {
  static propTypes = {
    managedProject: PropTypes.shape({
      id: PropTypes.string,
      totalHoursOffered: PropTypes.string,
      isActive: PropTypes.bool,
      hourlyRate: PropTypes.number,
      deadline: PropTypes.string,
      name: PropTypes.string,
      offeredPrice: PropTypes.number,
      managedProjectPosition: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          visibility: PropTypes.string,
          isLinked: PropTypes.bool,
          managedProjectPositionCategories: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string,
              description: PropTypes.string,
              repositoryUrl: PropTypes.string,
              isLinked: PropTypes.bool,
              active: PropTypes.bool,
            }),
          ),
        }),
      ),
    }).isRequired,
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        visibility: PropTypes.string,
        isLinked: PropTypes.bool,
      }),
    ),
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    change: PropTypes.func.isRequired,
    editFormValues: PropTypes.shape({
      id: PropTypes.string,
      totalHoursOffered: PropTypes.string | PropTypes.number,
      isActive: PropTypes.bool,
      hourlyRate: PropTypes.string | PropTypes.number,
      deadline: PropTypes.string,
      name: PropTypes.string,
      offeredPrice: PropTypes.string | PropTypes.number,
      managedProjectPosition: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          visibility: PropTypes.string,
          isLinked: PropTypes.bool,
          managedProjectPositionCategories: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string,
              description: PropTypes.string,
              repositoryUrl: PropTypes.string,
              isLinked: PropTypes.bool,
              active: PropTypes.bool,
            }),
          ),
        }),
      ),
    }),
  };

  static defaultProps = {
    errors: [],
    editFormValues: null,
    projects: null,
  };

  static renderProjectPositions({
    projects,
    fields,
    meta: { error },
    editFormValues,
    reduxChange,
  }) {
    return (
      <table className={`table table-striped ${s.projectPositionTable}`}>
        <thead>
          <tr>
            <th>
              <FormattedMessage {...messages.projectSelectHeading} />
            </th>
            <th>
              <FormattedMessage {...messages.projectCategorySelectHeading} />
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {fields.map((projectPos, index) => {
            if (!editFormValues) return <Loading />;
            const { managedProjectPositions } = editFormValues;

            const {
              includeAllCategories,
              managedProjectPosition,
            } = managedProjectPositions[index];
            const projectData =
              managedProjectPosition && projects
                ? projects.find(
                    project => project.id === managedProjectPosition.value,
                  )
                : null;
            const projectCategoriesOptions =
              projectData && projectData.categories.length !== 0
                ? projectData.categories.map(category => ({
                    label: category.name,
                    value: category.id,
                  }))
                : null;

            if (includeAllCategories) {
              reduxChange(
                `${projectPos}.managedProjectPosition.managedProjectPositionCategories`,
                projectCategoriesOptions,
              );
            }
            return (
              <tr key={projectPos} className={s.projectPosition}>
                <td className={s.projectPositionField}>
                  <Field
                    name={`${projectPos}.managedProjectPosition`}
                    placeholder={
                      <FormattedMessage {...messages.projectSelect} />
                    }
                    options={
                      projects
                        ? projects.map(project => ({
                            label: project.name,
                            value: project.id,
                          }))
                        : null
                    }
                    component={render.renderSelectProject}
                  />
                </td>
                <td className={s.projectPositionField}>
                  <Field
                    name={`${projectPos}.managedProjectPosition.managedProjectPositionCategories`}
                    placeholder={
                      _.isEmpty(managedProjectPosition) ||
                      _.isEmpty(projectCategoriesOptions) ? (
                        <FormattedMessage {...messages.noCategory} />
                      ) : (
                        <FormattedMessage {...messages.projectCategorySelect} />
                      )
                    }
                    options={projectCategoriesOptions}
                    disabled={
                      _.isEmpty(managedProjectPosition) ||
                      _.isEmpty(projectCategoriesOptions) ||
                      includeAllCategories
                    }
                    component={render.renderMultiSelect}
                  />
                </td>
                <td className={s.projectPositionFieldCheckBox}>
                  <Field
                    name={`${projectPos}.includeHoursBookedOnProject`}
                    disabled={_.isEmpty(managedProjectPosition)}
                    label={
                      <FormattedMessage
                        {...messages.includeHoursBookedOnProject}
                      />
                    }
                    component={render.renderCheckbox}
                  />
                </td>
                <td className={s.projectPositionFieldCheckBox}>
                  <Field
                    name={`${projectPos}.includeAllCategories`}
                    disabled={
                      _.isEmpty(managedProjectPosition) ||
                      _.isEmpty(projectCategoriesOptions)
                    }
                    label={<FormattedMessage {...messages.includeAll} />}
                    component={render.renderCheckbox}
                  />
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => {
                      // remove deleted row from state
                      fields.remove(index);
                    }}
                    className={`btn btn-danger ${s.trash}`}
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            );
          })}
          {error && <tr className="error">{error}</tr>}
          <tr>
            <td colSpan="5">
              <hr />
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  fields.push({});
                }}
              >
                <i className="fa fa-plus fa--prepended" />
                <FormattedMessage {...formMessages.add} />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  render() {
    const { errors, projects, managedProject } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <fieldset>
          <Row className={layoutStyle.noMargin}>
            <Col xs={12} md={3} style={{ paddingLeft: '0px' }}>
              <Field
                id="name"
                name="name"
                label={<FormattedMessage {...messages.nameHeading} />}
                component={render.renderInput}
              />
            </Col>
            <Col xs={12} md={3} style={{ paddingLeft: '0px' }}>
              <Field
                id="totalHoursOffered"
                name="totalHoursOffered"
                label={
                  <FormattedMessage {...messages.totalHoursOfferedHeading} />
                }
                component={render.renderNumber}
              />
            </Col>
            <Col xs={12} md={3} style={{ paddingLeft: '0px' }}>
              <Field
                id="hourlyRate"
                name="hourlyRate"
                currency="€"
                label={<FormattedMessage {...messages.hourlyRateHeading} />}
                component={render.renderCurrency}
              />
            </Col>
            <Col xs={12} md={3} style={{ paddingLeft: '0px' }}>
              <Field
                id="offeredPrice"
                name="offeredPrice"
                currency="€"
                label={<FormattedMessage {...messages.offeredPriceHeading} />}
                component={render.renderCurrency}
              />
            </Col>
            <Col xs={12} md={3} style={{ paddingLeft: '0px' }}>
              <Field
                id="deadline"
                name="deadline"
                label={<FormattedMessage {...messages.deadlineHeading} />}
                component={render.renderDate}
              />
            </Col>
          </Row>
          <hr />
          <FieldArray
            name="managedProjectPositions"
            projects={projects}
            component={EditForm.renderProjectPositions}
            managedProject={managedProject}
            editFormValues={this.props.editFormValues}
            reduxChange={this.props.change}
          />
          {errors.length === 0 ? null : (
            <ul style={{ padding: '0', listStyleType: 'none' }}>
              {errors.map(err => (
                <li key={err} className="bg-danger">
                  {err}
                </li>
              ))}
            </ul>
          )}
          <hr />
          <ButtonToolbar>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={this.props.submitting}
            >
              <FormattedMessage {...messages.submit} />
            </button>
          </ButtonToolbar>
        </fieldset>
      </form>
    );
  }
}

export default compose(
  connect(state => ({
    editFormValues: getFormValues('editForm')(state),
  })),
  reduxForm({
    form: 'editForm',
    validate,
    enableReinitialize: true,
  }),
)(injectIntl(withStyles(layoutStyle, s)(EditForm)));
