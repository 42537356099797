import { defineMessages } from 'react-intl';

const messages = defineMessages({
  successTitle: {
    id: 'alert.successTitle',
    defaultMessage: 'Success',
    description: 'Title for success alert',
  },
  successMessage: {
    id: 'alert.successMessage',
    defaultMessage: 'Operation was successful!',
    description: 'Message for success alert',
  },
  errorTitle: {
    id: 'alert.errorTitle',
    defaultMessage: 'Error',
    description: 'Title for error alert',
  },
  errorMessage: {
    id: 'alert.errorMessage',
    defaultMessage: 'An error occured.',
    description: 'Message for error alert',
  },
});

export default messages;
