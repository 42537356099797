exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2x-C9{padding-top:10px;height:100px;width:400px}.HUHE4{padding:0;height:80px;width:400px}._23STk{width:420px;margin-left:0}._23STk ._3CEPm{margin-top:20px!important;margin-bottom:0!important}._23STk ._3CEPm h3{display:inline-block;font-size:1.2em;margin:0}._23STk .xpIz5{background-color:#fff}.subIe{border-bottom:3px solid #00dcb2!important;border-right:3px solid #00dcb2!important;background-color:rgba(0,220,178,.1)!important}._2Hcbs{border-bottom:3px solid #dc0000!important;border-right:3px solid #dc0000!important;background-color:rgba(220,0,0,.5)!important}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"chartWrapper": "_2x-C9",
	"workCategoryCharts": "HUHE4",
	"totalChart": "_23STk",
	"ratioHeader": "_3CEPm",
	"ratioContent": "xpIz5",
	"underMinimum": "subIe",
	"overMaximum": "_2Hcbs"
};