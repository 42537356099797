import { defineMessages } from 'react-intl';

const messages = defineMessages({
  name: {
    id: 'project.name',
    defaultMessage: 'Name',
    description: 'Label for name in project',
  },
  editHeading: {
    id: 'project.edit.heading',
    defaultMessage: 'Edit',
    description: 'Heading for Edit',
  },
  visibility: {
    id: 'project.visibility',
    defaultMessage: 'Visibility',
    description: 'Label for visibility in Project',
  },
  addHeading: {
    id: 'project.add.heading',
    defaultMessage: 'Add Project',
    description: 'Heading for Add',
  },
  addProject: {
    id: 'project.addProject',
    defaultMessage: 'Project',
    description: 'Label for Add Project button',
  },
  categories: {
    id: 'project.categories.heading',
    defaultMessage: 'Categories',
    description: 'Heading for category column in table',
  },
  active: {
    id: 'project.active',
    defaultMessage: 'active',
    description: 'Label for active flag',
  },
});

export default messages;
