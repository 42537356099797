import React from 'react';
import PropTypes from 'prop-types';
import reactTableStyle from 'react-table/react-table.css';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import Issues from './IssueList';
// eslint-disable-next-line css-modules/no-unused-class
import s from './IssueList.scss';

class IssueTable extends React.Component {
  static propTypes = {
    gitlabUser: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      avatar_url: PropTypes.string,
    }).isRequired,
  };

  render() {
    const { gitlabUser } = this.props;
    return (
      <div>
        <table className={`table table-striped ${s.issueListTable}`}>
          <thead>
            <tr>
              <th>
                <FormattedMessage {...messages.title} />
              </th>
              <th>
                <FormattedMessage {...messages.labels} />
              </th>
              <th>
                <FormattedMessage {...messages.totalTime} />
              </th>
              <th>
                <FormattedMessage {...messages.created} />
              </th>
              <th>
                <FormattedMessage {...messages.due} />
              </th>
              <th>
                <FormattedMessage {...messages.link} />
              </th>
              <th>
                <FormattedMessage {...messages.mergeRequest} />
              </th>
              <th className={`${s.description} ${s.hideOnSmall}`}>
                <FormattedMessage {...messages.mileStone} />
              </th>
              <th className={`${s.description} ${s.hideOnSmall}`}>
                <FormattedMessage {...messages.desc} />
              </th>
            </tr>
            <tr key={`${gitlabUser.id}__separator`} className={s.separator}>
              <td colSpan={10}>
                <img
                  key="avatar"
                  src={gitlabUser.avatar_url}
                  style={{ height: 25 }}
                  alt=""
                />
                <div>{gitlabUser.name}</div>
              </td>
            </tr>
          </thead>
          <Issues gitlabUser={gitlabUser} callBackPageCount={this.callback} />
        </table>
      </div>
    );
  }
}

export default withStyles(s, reactTableStyle)(IssueTable);
