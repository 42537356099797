import { defineMessages } from 'react-intl';

const messages = defineMessages({
  billable: {
    id: 'filter.billable',
    defaultMessage: 'Billable',
    description: 'Label for Billable Hours',
  },
  nonBillable: {
    id: 'filter.nonBillable',
    defaultMessage: 'non-Billable',
    description: 'Label for non Billable Hours',
  },
  abrBillable: {
    id: 'filter.abrBillable',
    defaultMessage: 'B',
    description: 'Label for abreviated Billable Hours',
  },
  abrNonBillable: {
    id: 'filter.abrNonBillable',
    defaultMessage: 'non-B',
    description: 'Label for abreviated non Billable Hours',
  },
  heading: {
    id: 'filter.heading',
    defaultMessage: 'Filter',
    description: 'Heading for filter',
  },
  show: {
    id: 'controlling.show',
    defaultMessage: 'Show',
    description: 'Label for Show button of statistics',
  },
});

export default messages;
