/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import {
  Row,
  Col,
  Modal,
  Dropdown,
  Glyphicon,
  MenuItem,
} from 'react-bootstrap';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import qs from 'qs';
import Loading from 'components/Loading';
import {
  FormattedMessage,
  defineMessages,
  injectIntl,
  intlShape,
} from 'react-intl';
import AuthorizationWrapper from 'components/Auth/AuthorizationWrapper';
import roles from 'core/roles';

import Filter from './Filter';
import RatioPanel from './RatioPanel';
import layoutStyle from '../../../styles/base/layout.scss';
import { messages as filterFormMessages } from '../../Filter/FilterForm';
import authMessages from '../../Auth/messages';
import accountMessages from '../../Account/messages';
import { getErrorsFromApollo } from '../../../core/errors/util';
import errorMessages from '../../../core/errors/messages';
import {
  stringToDateOnly,
  DATEONLY_STRING_FMT_DB,
} from '../../../core/dateonly';
import variables from '../../../styles/base/variables.scss';

const messages = defineMessages({
  compensations: {
    id: 'ratios.compensations',
    defaultMessage: 'Compensations',
    description: 'Header for Compensations modal',
  },
  belowMinimum: {
    id: 'ratios.belowMinimum',
    defaultMessage: 'Missing {hoursBelowMinimum}h to minimum hours',
    description: 'Shortwork status below minimum',
  },
  toMaximum: {
    id: 'ratios.toMaximum',
    defaultMessage:
      '{hoursOverMinimum}h over Minimum and {hoursbelowMaximum}h to the Maximum',
    description: 'Shortwork status above minimum, below maximum',
  },
  overMaximum: {
    id: 'ratios.overMaximum',
    defaultMessage: '{hoursOverMaximum}h over the Maximum',
    description: 'Shortwork status above maximum',
  },
  exportHomeOfficeDays: {
    id: 'ratios.exportHomeOfficeDays',
    defaultMessage: 'Export home office days',
    description: 'Home office hours export button label',
  },
});

const handleExport = ({ formValues, userId }, format) => {
  const filterString = qs.stringify({
    filter: formValues,
  });
  const exportTab = window.open('', '_blank');
  const exportUrl = `/export/userWithShortWork/${userId}?${filterString}&format=${format}`;
  exportTab.location.href = exportUrl;
  exportTab.focus();
};

const handleHomeOfficeDaysExport = ({ formValues }, format) => {
  const filterString = qs.stringify({
    filter: formValues,
  });
  const exportTab = window.open('', '_blank');
  const exportUrl = `/export/homeOfficeDays/all?${filterString}&format=${format}`;
  exportTab.location.href = exportUrl;
  exportTab.focus();
};

const DropdownMenu = Dropdown.Menu;
const DropdownToggle = Dropdown.Toggle;

class Ratios extends React.Component {
  static propTypes = {
    dataQuery: PropTypes.shape({
      totalStatistics: PropTypes.shape({
        billableHours: PropTypes.number.isRequired,
        nonBillableHours: PropTypes.number.isRequired,
      }),
      users: PropTypes.arrayOf(
        PropTypes.shape({
          hourStatistics: PropTypes.shape({
            billableHours: PropTypes.number.isRequired,
            nonBillableHours: PropTypes.number.isRequired,
          }),
        }),
      ),
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    showWorkCategory: PropTypes.bool.isRequired,
    addCompensations: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.applyFilters = this.applyFilters.bind(this);
    this.applyCompensation = this.applyCompensation.bind(this);

    this.state = {
      showCompensationResults: false,
      compensationResults: [],
      filter: {
        from: moment()
          .startOf('month')
          .format(DATEONLY_STRING_FMT_DB),
        to: moment()
          .endOf('month')
          .format(DATEONLY_STRING_FMT_DB),
      },
      errors: [],
    };
  }

  getShortWorkStatus(shortWorkBalance) {
    const { intl } = this.props;
    let shortWorkStatus;
    if (shortWorkBalance.done < shortWorkBalance.minHours) {
      shortWorkStatus = intl.formatMessage(messages.belowMinimum, {
        hoursBelowMinimum: (
          shortWorkBalance.minHours - shortWorkBalance.done
        ).toFixed(2),
      });
      shortWorkStatus = (
        <div
          style={{
            color: variables.orange,
            marginTop: '20px',
          }}
        >
          {shortWorkStatus}
        </div>
      );
    } else if (
      shortWorkBalance.done >= shortWorkBalance.minHours &&
      shortWorkBalance.done <= shortWorkBalance.maxHours
    ) {
      shortWorkStatus = intl.formatMessage(messages.toMaximum, {
        hoursOverMinimum: (
          shortWorkBalance.done - shortWorkBalance.minHours
        ).toFixed(2),
        hoursbelowMaximum: (
          shortWorkBalance.maxHours - shortWorkBalance.done
        ).toFixed(2),
      });
      shortWorkStatus = (
        <div
          style={{
            color: variables.teal,
            marginTop: '20px',
          }}
        >
          {shortWorkStatus}
        </div>
      );
    } else if (shortWorkBalance.done > shortWorkBalance.maxHours) {
      shortWorkStatus = intl.formatMessage(messages.toMaximum, {
        hoursOverMaximum: (
          shortWorkBalance.done - shortWorkBalance.maxHours
        ).toFixed(2),
      });
      shortWorkStatus = (
        <div
          style={{
            color: variables.orange,
            marginTop: '20px',
          }}
        >
          {shortWorkStatus}
        </div>
      );
    }
    return shortWorkStatus;
  }

  async applyCompensation() {
    try {
      const addedEntries = await this.props.addCompensations(this.state.filter);
      let compensationResults = [];
      if (addedEntries.data) {
        compensationResults = addedEntries.data.addCompensations;
      }
      this.setState({
        showCompensationResults: true,
        compensationResults,
      });
    } catch (e) {
      const errors = getErrorsFromApollo(e);
      this.setState({
        showCompensationResults: true,
        errors: this.state.errors.concat(
          errors.map(err =>
            this.props.intl.formatMessage(
              errorMessages[err.message],
              err.messageValues,
            ),
          ),
        ),
      });
    }
  }

  async applyFilters({ filter }) {
    this.setState({ filter });
    this.props.dataQuery.refetch({
      filter,
    });
  }

  render() {
    if (this.props.dataQuery.loading) return <Loading />;
    if (!this.props.dataQuery.totalStatistics) return <div />;
    const {
      dataQuery: {
        totalStatistics: { billableHours, nonBillableHours, projectHours },
        users,
        shortWorkList,
      },
    } = this.props;
    const totalData = { billableHours, nonBillableHours };
    const {
      filter,
      compensationResults,
      showCompensationResults,
      errors,
    } = this.state;
    const exportFormats = [
      {
        label: 'PDF',
        value: 'pdf',
      },
      {
        label: 'XLSX',
        value: 'xlsx',
      },
    ];
    const formats = exportFormats.map(fmt => (
      <MenuItem key={fmt.value} eventKey={fmt.value}>
        {fmt.label}
      </MenuItem>
    ));
    return (
      <div>
        <div className="h3">Ratios</div>
        <Row className={layoutStyle.noMargin} style={{ marginTop: '15px' }}>
          <Col xs={12} style={{ padding: 0 }}>
            <Filter
              onFilterApply={this.applyFilters}
              appliedValues={filter}
              shortWorkList={shortWorkList}
              onApplyCompensation={this.applyCompensation}
            />
          </Col>
          <Col xs={12} className={layoutStyle.noPadding}>
            <Dropdown
              id="exportDropdown"
              onSelect={value =>
                handleHomeOfficeDaysExport(
                  { formValues: { to: filter.to, from: filter.from } },
                  value,
                )
              }
            >
              <DropdownToggle className="btn dropdown-toggle">
                <Glyphicon glyph="export" />
                <FormattedMessage {...messages.exportHomeOfficeDays} />
              </DropdownToggle>
              <DropdownMenu>{formats}</DropdownMenu>
            </Dropdown>
          </Col>
          <Col xs={12} className={layoutStyle.noPadding}>
            <RatioPanel
              totalData={totalData}
              projectData={projectHours}
              title="total"
              initialShow
              showWorkCategory={this.props.showWorkCategory}
            />
          </Col>
          {users &&
            users.map(user => {
              const userStat = user.hoursStatistics;
              const { shortWorkBalance } = user;
              const total = {
                billableHours: userStat.billableHours,
                nonBillableHours: userStat.nonBillableHours,
              };
              if (total.billableHours === 0 && total.nonBillableHours === 0) {
                return null;
              }

              const shortWork = shortWorkList.find(
                entry => entry.id === filter.shortWork,
              );
              let shortWorkSettings = null;
              let shortWorkUser;
              if (shortWork) {
                shortWorkUser = shortWork.shortWorkUsers.find(
                  entry => entry.user.id === user.id,
                );
                if (shortWorkUser) {
                  shortWorkSettings = {
                    start: stringToDateOnly(filter.from) || shortWork.start,
                    end: stringToDateOnly(filter.to) || shortWork.end,
                    maxWeeklyHours: shortWorkUser.maxWeeklyHours,
                    minWeeklyHours: shortWorkUser.minWeeklyHours,
                  };
                }
              }
              let shortWorkStatus;
              if (shortWorkUser && shortWorkBalance) {
                shortWorkStatus = this.getShortWorkStatus(shortWorkBalance);
              }
              return (
                <Col
                  key={user.username}
                  style={{ width: '800px' }}
                  className={layoutStyle.noPadding}
                >
                  <Col md={7} xs={12} className={layoutStyle.noPadding}>
                    <RatioPanel
                      totalData={total}
                      projectData={userStat.projectHours}
                      title={user.username}
                      shortWorkSettings={shortWorkSettings}
                      showWorkCategory={this.props.showWorkCategory}
                    />
                  </Col>
                  {shortWorkUser && (
                    <Col
                      md={2}
                      xs={2}
                      className={layoutStyle.noPadding}
                      style={{ marginTop: '25px', paddingRight: '0' }}
                    >
                      <Dropdown
                        id="exportDropdown"
                        onSelect={value =>
                          handleExport(
                            { formValues: filter, userId: user.id },
                            value,
                          )
                        }
                      >
                        <DropdownToggle className="btn dropdown-toggle">
                          <Glyphicon glyph="export" />
                          <FormattedMessage
                            {...filterFormMessages.exportFormat}
                          />
                        </DropdownToggle>
                        <DropdownMenu>{formats}</DropdownMenu>
                      </Dropdown>
                    </Col>
                  )}
                  {shortWorkStatus && (
                    <Col md={4} xs={11} style={{ padding: '0' }}>
                      {shortWorkStatus}
                    </Col>
                  )}
                </Col>
              );
            })}
        </Row>
        <Modal
          show={showCompensationResults}
          onHide={() =>
            this.setState({
              showCompensationResults: false,
              compensationResults: [],
            })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage {...messages.compensations} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage {...authMessages.username} />
                  </th>
                  <th>
                    <FormattedMessage {...accountMessages.hours} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {compensationResults &&
                  errors.length === 0 &&
                  compensationResults.map(r => {
                    if (!r || !r.user) return null;
                    return (
                      <tr>
                        <td>{r.user.username}</td>
                        <td>{r.hours}</td>
                      </tr>
                    );
                  })}
                {errors.length > 0 &&
                  errors.map(e => (
                    <tr>
                      <td colSpan="2">{e}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const addCompensationsMutation = gql`
  mutation addCompensations($filter: HoursEntryFilter) {
    addCompensations(filter: $filter) {
      user {
        username
      }
      hours
    }
  }
`;

const dataQuery = gql`
  query dataQuery($filter: HoursEntryFilter) {
    shortWorkList {
      id
      name
      start
      end
      shortWorkUsers {
        id
        minWeeklyHours
        maxWeeklyHours
        user {
          id
          username
        }
      }
    }
    totalStatistics(filter: $filter) {
      billableHours
      nonBillableHours
      projectHours {
        name
        hours
        billable
        workCategory {
          name
          color
        }
      }
    }
    users {
      id
      username
      hoursStatistics(filter: $filter) {
        billableHours
        nonBillableHours
        projectHours {
          name
          hours
          billable
          workCategory {
            name
            color
          }
        }
      }
      active
      shortWorkBalance(filter: $filter) {
        minHours
        maxHours
        done
      }
    }
  }
`;

export default compose(
  injectIntl,
  graphql(dataQuery, {
    name: 'dataQuery',
    options: () => ({
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          from: moment()
            .startOf('month')
            .format(DATEONLY_STRING_FMT_DB),
          to: moment()
            .endOf('month')
            .format(DATEONLY_STRING_FMT_DB),
          active: true,
        },
      },
    }),
  }),
  graphql(addCompensationsMutation, {
    props: ({ mutate }) => ({
      addCompensations: filter =>
        mutate({
          variables: { filter },
        }),
    }),
  }),
)(
  AuthorizationWrapper([roles.PROJECT_MANAGER, roles.ADMIN])(
    withStyles(layoutStyle)(Ratios),
  ),
);
