import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Loading from 'components/Loading';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reset } from 'redux-form';

import messages from './messages';
import EditForm from './EditForm';
import { getErrorsFromApollo } from '../../core/errors/util';
import errorMessages from '../../core/errors/messages';
import NotFound from '../NotFound';

class ProjectEdit extends React.Component {
  static propTypes = {
    projectQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      project: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        mandatoryIssue: PropTypes.bool.isRequired,
        mandatoryCategory: PropTypes.bool.isRequired,
        billable: PropTypes.bool.isRequired,
        repositoryUrl: PropTypes.string,
        categories: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
            repositoryUrl: PropTypes.string,
            isLinked: PropTypes.bool,
            active: PropTypes.bool,
          }),
        ),
      }),
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    updateProject: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(formValues) {
    await this.setState({ errors: [] });
    const payloadWithTypenames = { ...formValues };
    if (formValues.type) payloadWithTypenames.type = formValues.type.value;
    let categories = [];
    if (payloadWithTypenames.categories) {
      categories = payloadWithTypenames.categories.map(category => {
        const categoryWithoutTypeName = _.omit(category, [
          '__typename',
          'isLinked',
        ]);
        return { ...categoryWithoutTypeName };
      });
    }
    const payload = _.omit(payloadWithTypenames, [
      '__typename',
      'categories',
      'users',
      'visibility',
    ]);

    const payloadWithCategories = { ...payload, categories };
    // const updatedProject = { ...this.props.projectQuery.project, categories: payloadWithProjectId}
    try {
      await this.props.updateProject(payloadWithCategories);
    } catch (e) {
      const errors = getErrorsFromApollo(e);
      this.props.dispatch(reset('editForm'));
      this.setState({
        errors: this.state.errors.concat(
          errors.map(err =>
            this.props.intl.formatMessage(
              errorMessages[err.message],
              err.messageValues,
            ),
          ),
        ),
      });
    }
  }

  render() {
    const { projectQuery: { loading, project } } = this.props;
    if (loading) return <Loading />;
    if (!project) {
      return <NotFound />;
    }
    return (
      <div>
        <h2>
          <FormattedMessage {...messages.editHeading} />&nbsp;
          {project.name}
        </h2>
        <EditForm
          project={project}
          onSubmit={this.handleSubmit}
          initialValues={{
            ...project,
            type: project.type
              ? {
                  value: project.type,
                  label: project.type,
                }
              : null,
          }}
          errors={this.state.errors}
        />
      </div>
    );
  }
}

const projectQuery = gql`
  query projectQuery($id: String!) {
    project(id: $id, showInactive: true) {
      id
      name
      mandatoryIssue
      mandatoryCategory
      billable
      repositoryUrl
      type
      visibility
      categories {
        id
        name
        description
        repositoryUrl
        isLinked
        active
      }
    }
  }
`;

const updateProjectMutation = gql`
  mutation updateProjectMutation($patch: ProjectInput!) {
    updateProject(patch: $patch) {
      id
      name
      mandatoryIssue
      mandatoryCategory
      billable
      type
      repositoryUrl
      categories {
        id
        name
        description
        repositoryUrl
        active
      }
    }
  }
`;

export default compose(
  injectIntl,
  graphql(projectQuery, {
    name: 'projectQuery',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
  }),
  graphql(updateProjectMutation, {
    props: ({ mutate }) => ({
      updateProject: project =>
        mutate({
          variables: { patch: project },
          refetchQueries: ['projectQuery', 'projectsQuery'],
        }),
    }),
  }),
  connect(),
)(ProjectEdit);
