import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ResetPassword from '../../components/Password/ResetPassword';
import SetPassword from '../../components/Password/SetPassword';
import ChangePassword from '../../components/Password/ChangePassword';

class PasswordWrapper extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          key="ResetPassword"
          path="/password/reset/"
          component={ResetPassword}
          exact
        />
        <Route
          key="SetPassword"
          path="/password/reset/:token"
          component={SetPassword}
        />
        <Route
          key="ChangePassword"
          path="/password/change/"
          component={ChangePassword}
          exact
        />
      </Switch>
    );
  }
}

export default PasswordWrapper;
