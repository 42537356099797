exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1HC0W tbody{position:relative}._1HC0W tbody ._3UAb9{display:flex;position:absolute;top:0;left:0;bottom:0;right:0;align-items:center;justify-content:center;background-color:#fbfbfb;opacity:.5}._1HC0W tbody tr:hover td{background-color:#efefef}._2gNC0 td{background-color:#f9f9f9!important}tr._1JJre td{color:#fbfbfb;font-weight:700;background-color:#ef4040!important}tr._1JJre td img{float:left;margin-right:9px}tr.hW2GU:hover td,tr.hW2GU td{color:#fbfbfb;font-weight:700;background-color:#333!important}tr.hW2GU:hover td img,tr.hW2GU td img{float:left;margin-right:9px}._2pVPn{margin:0;width:200px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap}._3E7JH .PELMP{margin:0;width:7%!important}._3E7JH ._1Rupf{margin:0;width:15%!important}@media(max-width:1310px){._2rEWH{display:none}}._343LW{align-items:center!important;width:100%}._343LW svg{vertical-align:middle!important}._343LW ul{list-style:none!important;padding:0!important;align-items:center!important}._343LW li{display:inline!important;margin-right:12px!important}._343LW li span{padding-left:5px}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"issueListTable": "_1HC0W",
	"loaderWrapper": "_3UAb9",
	"hoverDisabled": "_2gNC0",
	"seperatorEmpty": "_1JJre",
	"separator": "hW2GU",
	"description": "_2pVPn",
	"issueRow": "_3E7JH",
	"smallColumn": "PELMP",
	"bigColumn": "_1Rupf",
	"hideOnSmall": "_2rEWH",
	"paginationWrapper": "_343LW"
};