import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reset } from 'redux-form';

import messages from './messages';
import EditForm from './EditForm';
import { getErrorsFromApollo } from '../../../core/errors/util';
import errorMessages from '../../../core/errors/messages';
import Loading from '../../Loading';
import NotFound from '../../NotFound';

class WorkCategoryEdit extends React.Component {
  static propTypes = {
    updateWorkCategory: PropTypes.func.isRequired,
    workCategoriesQuery: PropTypes.shape({
      loading: PropTypes.bool,
      workCategories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          isActive: PropTypes.bool,
          name: PropTypes.string,
          color: PropTypes.string,
          description: PropTypes.string,
        }),
      ),
    }).isRequired,
    workCategoryQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      workCategory: PropTypes.shape({
        id: PropTypes.string,
        isActive: PropTypes.bool,
        name: PropTypes.string,
        color: PropTypes.string,
        description: PropTypes.string,
      }),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(formValues) {
    await this.setState({ errors: [] });
    const payload = _.omit(formValues, ['__typename']);
    try {
      await this.props.updateWorkCategory(payload);
    } catch (e) {
      const errors = getErrorsFromApollo(e);
      this.props.dispatch(reset('editForm'));
      this.setState({
        errors: this.state.errors.concat(
          errors.map(err =>
            this.props.intl.formatMessage(
              errorMessages[err.message],
              err.messageValues,
            ),
          ),
        ),
      });
    }
  }

  render() {
    const { workCategoryQuery: { loading, workCategory } } = this.props;
    if (loading) return <Loading />;
    if (!workCategory) {
      return <NotFound />;
    }

    return (
      <div>
        <h2>
          <FormattedMessage {...messages.editWorkCategory} />
        </h2>
        <EditForm
          workCategory={workCategory}
          onSubmit={this.handleSubmit}
          errors={this.state.errors}
          initialValues={{
            ...workCategory,
          }}
        />
      </div>
    );
  }
}

export const updateWorkCategory = gql`
  mutation updateWorkCategory($workCategory: EditWorkCategoryInput!) {
    updateWorkCategory(workCategory: $workCategory) {
      id
      name
      isActive
      description
      color
    }
  }
`;

export const workCategoryQuery = gql`
  query workCategory($id: String!) {
    workCategory(id: $id) {
      id
      name
      description
      color
    }
  }
`;

export const workCategoriesQuery = gql`
  query workCategories {
    workCategories(showInactive: true) {
      id
      name
      isActive
      isDefault
      description
      color
    }
  }
`;

export default compose(
  injectIntl,
  graphql(workCategoriesQuery, {
    name: 'workCategoriesQuery',
  }),
  graphql(workCategoryQuery, {
    name: 'workCategoryQuery',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
  }),
  graphql(updateWorkCategory, {
    props: ({ mutate }) => ({
      updateWorkCategory: workCategory =>
        mutate({
          variables: { workCategory },
          update: (proxy, data) => {
            const updatedWorkCategory = data.data
              ? data.data.createWorkCategory
              : null;
            if (updatedWorkCategory) {
              const workCategoriesData = proxy.readQuery({
                query: workCategoriesQuery,
              });
              workCategoriesData.workCategories.push({
                ...updatedWorkCategory,
              });
              workCategoriesData.workCategories = workCategoriesData.workCategories.sort(
                (a, b) => (a.name < b.name ? -1 : 1),
              );
              proxy.writeQuery({
                query: workCategoriesQuery,
                data: workCategoriesData,
              });
            }
          },
        }),
    }),
  }),
  connect(),
)(WorkCategoryEdit);
