/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { connect } from 'react-redux';
import { change, untouch, stopSubmit } from 'redux-form';
import gql from 'graphql-tag';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment';
import qs from 'qs';
import _ from 'lodash';
import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';

import Loading from 'components/Loading';
import Filter from 'components/Filter';
import meQuery from 'components/Auth/queries/meQuery';
import SyncProjectsButton from 'components/SyncProjectsButton';
import validations from 'components/ReduxForm/validations';
import validationMessages from 'components/ReduxForm/messages';
import hoursEntryTypes from 'core/hoursEntryTypes';
import s from './HoursList.scss'; // eslint-disable-line css-modules/no-unused-class
import AddOrUpdateHoursEntryForm from './AddOrUpdateHoursEntryForm';
import List from './List';
import EmployeeBalance from '../EmployeeBalance';
import messages from './messages';
import { getErrorsFromApollo } from '../../core/errors/util';
import errorMessages from '../../core/errors/messages';
import { handleIssueInputChangeAsync, handleProjectSelect } from './util';
import {
  DATEONLY_STRING_FMT,
  DATEONLY_STRING_FMT_DB,
  stringToDateOnly,
} from '../../core/dateonly';

class HoursList extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      me: PropTypes.shape({
        id: PropTypes.string.isRequired,
        projects: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
          }),
        ).isRequired,
      }),
    }).isRequired,
    oAuthProviderQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      currentOAuthProvider: PropTypes.string,
    }).isRequired,
    maxBulkAdditionHoursQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      maxBulkAdditionHours: PropTypes.number,
    }).isRequired,
    descriptionRequirementQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      descriptionRequirement: PropTypes.string,
    }).isRequired,
    showHomeOfficeQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      showHomeOffice: PropTypes.bool,
    }).isRequired,
    showWorkCategory: PropTypes.bool.isRequired,
    defaultWorkCategoryQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      defaultWorkCategory: PropTypes.bool,
    }).isRequired,
    addHoursEntry: PropTypes.func.isRequired,
    addHoursEntryIfNotExist: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.handleExport = this.handleExport.bind(this);

    this.state = {
      filter: {
        from: moment()
          .startOf('month')
          .format(DATEONLY_STRING_FMT_DB),
        to: moment()
          .endOf('month')
          .format(DATEONLY_STRING_FMT_DB),
      },
      serverValidation: null,
      formErrors: {},
      // eslint-disable-next-line react/no-unused-state
      projectId: null, // is used in util method
      issues: [],
    };
  }

  static cacheFormValues(formValues) {
    localStorage.setItem('formValues', JSON.stringify(formValues));
  }

  static getCacheFormValues() {
    const initialValues = JSON.parse(localStorage.getItem('formValues'));

    return initialValues;
  }

  static deleteCacheFormValues() {
    localStorage.removeItem('formValues');
  }

  async handleSubmit(formValues) {
    this.setState({ formErrors: {} });
    const { issueValues, ...payload } = formValues;
    const {
      data: { me: { projects } },
      showWorkCategory,
      dispatch,
    } = this.props;
    const projectOption = _.get(formValues, 'project.value');
    const projectAndCategory = projectOption.split('_');
    payload.project = projectAndCategory[0];
    if (projectAndCategory.length === 2) {
      payload.category = projectAndCategory[1];
    } else {
      payload.category = null;
    }

    if (_.get(formValues, 'type')) {
      payload.type = _.get(formValues, 'type').value;
    }

    if (_.get(formValues, 'issue.value')) {
      const title = _.get(formValues, 'issue.label').split(
        `#${_.get(formValues, 'issue.value')} `,
      );
      const issue = {
        ticketId: _.get(formValues, 'issue.value'),
        title: title[1],
      };
      payload.issue = { ...issue };
    } else {
      payload.issue = null;
    }
    const { timeTo } = payload;
    delete payload.timeTo;
    payload.timeFrom = moment(payload.timeFrom.value, 'HH:mm');

    // format date
    if (_.get(formValues, 'date')) {
      payload.date = stringToDateOnly(formValues.date);
    }
    if (showWorkCategory) payload.workCategory = formValues.workCategory.id;
    else payload.workCategory = null;

    try {
      // project category validation
      //   unfortunately cannot do this directly in the form, because
      //   redux-form's validation function has no access to the
      //   component's props
      if (
        !validations.projectCategoryRequired({
          projects,
          projectId: payload.project,
          category: payload.category,
        })
      ) {
        const errors = {
          project: (
            <FormattedMessage {...validationMessages.projectCategoryRequired} />
          ),
        };
        dispatch(stopSubmit('addOrUpdateHoursEntryForm', errors));
        this.setState({ formErrors: errors });
        return false;
      }
      if (
        payload.type === hoursEntryTypes.SICK ||
        payload.type === hoursEntryTypes.VACATION
      ) {
        const from = moment(payload.date, DATEONLY_STRING_FMT_DB);
        const to = moment(payload.dateTo, DATEONLY_STRING_FMT);
        const days = to.diff(from, 'days');
        for (let i = 0; i <= days; i += 1) {
          payload.timeFrom = moment('09:00', 'HH:mm');
          // eslint-disable-next-line no-await-in-loop
          await this.props.addHoursEntryIfNotExist(payload, this.state.filter);
          payload.date = from.add(1, 'days').format(DATEONLY_STRING_FMT_DB);
        }
      } else {
        await this.props.addHoursEntry(payload, this.state.filter);
        HoursList.deleteCacheFormValues();
      }
      this.props.dispatch(
        change('addOrUpdateHoursEntryForm', 'timeFrom', {
          value: timeTo.value,
          label: timeTo.label,
        }),
      );
      if (
        payload.type !== hoursEntryTypes.SICK &&
        payload.type !== hoursEntryTypes.VACATION
      ) {
        this.props.dispatch(untouch('addOrUpdateHoursEntryForm', 'hours'));
        this.props.dispatch(change('addOrUpdateHoursEntryForm', 'hours', 0));
      }
      this.setState({ serverValidation: null });

      return true;
    } catch (e) {
      HoursList.cacheFormValues(formValues);
      const errors = getErrorsFromApollo(e);
      this.setState({
        serverValidation: this.props.intl.formatMessage(
          errorMessages[errors[0].message],
          errors[0].messageValues,
        ),
      });
      return false;
    }
  }

  async applyFilters({ filter }) {
    this.setState({ filter });
    this.props.data.refetch({
      filter,
    });
  }

  handleExport({ filter, format }) {
    const filterString = qs.stringify({ filter });
    const exportTab = window.open('', '_blank');
    const userId = this.props.data.me.id;
    // eslint-disable-next-line prettier/prettier
    const exportUrl = `/export/user/${userId}?${filterString}&format=${format}`;
    exportTab.location.href = exportUrl;
    exportTab.focus();
  }

  render() {
    const {
      data: { loading, me },
      defaultWorkCategoryQuery,
      showWorkCategory,
    } = this.props;
    const { filter } = this.state;
    const { currentOAuthProvider } = this.props.oAuthProviderQuery;
    if (
      !me ||
      loading ||
      this.props.oAuthProviderQuery.loading ||
      this.props.maxBulkAdditionHoursQuery.loading ||
      this.props.descriptionRequirementQuery.loading ||
      this.props.showHomeOfficeQuery.loading ||
      (showWorkCategory && defaultWorkCategoryQuery.loading)
    ) {
      return <Loading />;
    }

    const { projects, shortWorkEntries } = me;

    return (
      <Row className={s.hoursList}>
        <Col xs={12}>
          <Filter
            onFilterApply={this.applyFilters}
            appliedValues={filter}
            projects={projects}
            shortWorkEntries={shortWorkEntries}
            onExport={this.handleExport}
          />
        </Col>
        <Col xs={12}>
          <EmployeeBalance user={me} />
        </Col>
        <Col xs={12}>
          <Row className={s.listHeader}>
            <Col xs={5}>
              <h2>
                <FormattedMessage {...messages.hourListHeading} />
              </h2>
            </Col>
          </Row>
        </Col>
        <Col
          xs={12}
          md={3}
          lg={3}
          xl={4}
          className="pull-right"
          style={{ marginTop: '10px' }}
        >
          <SyncProjectsButton filter={filter} />
          <AddOrUpdateHoursEntryForm
            serverValidation={this.state.serverValidation}
            projects={projects}
            handleProjectSelect={({ value, isCategory }) => {
              handleProjectSelect(this, { value, isCategory });
            }}
            issues={this.state.issues || []}
            onIssueInputChange={value => {
              handleIssueInputChangeAsync(this, { value });
            }}
            currentOAuthProvider={currentOAuthProvider}
            initialValues={
              localStorage.getItem('formValues') !== null
                ? HoursList.getCacheFormValues()
                : {
                    type: { value: 'work', label: 'work' },
                    date: moment().format(DATEONLY_STRING_FMT),
                    ...(showWorkCategory && {
                      workCategory: {
                        ...defaultWorkCategoryQuery.defaultWorkCategory,
                      },
                    }),
                  }
            }
            maxBulkAdditionHours={
              this.props.maxBulkAdditionHoursQuery.maxBulkAdditionHours
            }
            descriptionRequirement={
              this.props.descriptionRequirementQuery.descriptionRequirement
            }
            showHomeOffice={this.props.showHomeOfficeQuery.showHomeOffice}
            showWorkCategory={showWorkCategory}
            errors={this.state.formErrors}
            onSubmit={this.handleSubmit}
          />
          <hr />
        </Col>
        <Col xs={12} md={9} lg={9} xl={8}>
          <List user={me} filter={filter} withBalance={false} />
        </Col>
      </Row>
    );
  }
}

const oAuthProviderQuery = gql`
  query oAuthProviderQuery {
    currentOAuthProvider
  }
`;

const maxBulkAdditionHoursQuery = gql`
  query maxBulkAdditionHoursQuery {
    maxBulkAdditionHours
  }
`;

const descriptionRequirementQuery = gql`
  query descriptionRequirementQuery {
    descriptionRequirement
  }
`;

const showHomeOfficeQuery = gql`
  query showHomeOfficeQuery {
    showHomeOffice
  }
`;

const defaultWorkCategoryQuery = gql`
  query defaultWorkCategory {
    defaultWorkCategory {
      value
      label
      id
      color
    }
  }
`;

const addHoursEntryIfNotExist = gql`
  mutation addHoursEntryIfNotExist(
    $payload: HoursEntryInput!
    $filter: HoursEntryFilter
  ) {
    addHoursEntryIfNotExist(payload: $payload) {
      id
      hoursEntries(filter: $filter) {
        id
        date
        timeFrom
        hours
        type
        category {
          id
          name
        }
        project {
          id
          name
          mandatoryCategory
        }
        issue {
          id
          ticketId
          title
        }
        description
        homeOffice
        workCategory {
          id
          name
          isActive
          isDefault
          description
          color
        }
        createdAt
      }
      dailyBalance {
        total
        done
        balance
      }
      weeklyBalance(filter: $filter) {
        total
        done
        balance
      }
      monthlyBalance(filter: $filter) {
        total
        done
        balance
      }
      totalBalance(filter: $filter) {
        total
        done
        balance
      }
      vacationBalance(filter: $filter) {
        total
        done
        balance
      }
    }
  }
`;

const addHoursEntryMutation = gql`
  mutation addHoursEntry(
    $payload: HoursEntryInput!
    $filter: HoursEntryFilter
  ) {
    addHoursEntry(payload: $payload) {
      id
      hoursEntries(filter: $filter) {
        id
        date
        timeFrom
        hours
        type
        homeOffice
        workCategory {
          id
          name
          isActive
          isDefault
          description
          color
        }
        category {
          id
          name
        }
        project {
          id
          name
          mandatoryCategory
        }
        issue {
          id
          ticketId
          title
        }
        description
        createdAt
      }
      dailyBalance {
        total
        done
        balance
      }
      weeklyBalance(filter: $filter) {
        total
        done
        balance
      }
      monthlyBalance(filter: $filter) {
        total
        done
        balance
      }
      totalBalance(filter: $filter) {
        total
        done
        balance
      }
      vacationBalance(filter: $filter) {
        total
        done
        balance
      }
    }
  }
`;

export default AuthenticationWrapper(
  compose(
    injectIntl,
    connect(),
    graphql(meQuery, {
      name: 'data',
      options: () => ({
        variables: {
          filter: {
            from: moment()
              .startOf('month')
              .format(DATEONLY_STRING_FMT_DB),
            to: moment()
              .endOf('month')
              .format(DATEONLY_STRING_FMT_DB),
          },
        },
      }),
    }),
    graphql(oAuthProviderQuery, {
      name: 'oAuthProviderQuery',
    }),
    graphql(maxBulkAdditionHoursQuery, {
      name: 'maxBulkAdditionHoursQuery',
    }),
    graphql(descriptionRequirementQuery, {
      name: 'descriptionRequirementQuery',
    }),
    graphql(showHomeOfficeQuery, {
      name: 'showHomeOfficeQuery',
    }),
    graphql(defaultWorkCategoryQuery, {
      name: 'defaultWorkCategoryQuery',
      skip: props => !props.showWorkCategory,
    }),
    graphql(addHoursEntryMutation, {
      props: ({ mutate }) => ({
        addHoursEntry: (hoursEntry, filter) =>
          mutate({
            variables: { payload: { ...hoursEntry }, filter },
          }),
      }),
    }),
    graphql(addHoursEntryIfNotExist, {
      props: ({ mutate }) => ({
        addHoursEntryIfNotExist: (hoursEntry, filter) =>
          mutate({
            variables: { payload: { ...hoursEntry }, filter },
          }),
      }),
    }),
  )(withStyles(s)(HoursList)),
);
