/* eslint-disable no-bitwise */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, ButtonToolbar } from 'react-bootstrap';
import { getFormValues } from 'redux-form/immutable';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { Field, reduxForm, change } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import formMessages from 'components/ReduxForm/messages';
import render from 'components/ReduxForm/renderField';

import layoutStyle from '../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import validations from '../../ReduxForm/validations';
import messages from './messages';

const validate = values => {
  const errors = {};
  if (!validations.required(values.name)) {
    errors.name = <FormattedMessage {...formMessages.required} />;
  }
  if (!validations.required(values.color)) {
    errors.color = <FormattedMessage {...formMessages.required} />;
  }
  return errors;
};

class EditForm extends React.Component {
  static propTypes = {
    workCategory: PropTypes.shape({
      id: PropTypes.string,
      isActive: PropTypes.bool,
      color: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    change: PropTypes.func.isRequired,
    editFormValues: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string,
      description: PropTypes.string,
    }),
  };

  static defaultProps = {
    errors: [],
    editFormValues: null,
  };

  render() {
    const { errors } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <fieldset>
          <Row className={layoutStyle.noMargin}>
            <Col xs={12} md={3} style={{ paddingLeft: '0px' }}>
              <Field
                id="name"
                name="name"
                label={<FormattedMessage {...messages.nameHeading} />}
                component={render.renderInput}
              />
            </Col>
            <Col xs={12} md={3} style={{ paddingLeft: '0px' }}>
              <Field
                id="color"
                name="color"
                disabled
                label={<FormattedMessage {...messages.colorHeading} />}
                component={render.renderColoPicker}
              />
            </Col>
            <Col xs={12} md={3} style={{ paddingLeft: '0px' }}>
              <Field
                id="description"
                name="description"
                label={<FormattedMessage {...messages.descriptionHeading} />}
                component={render.renderInput}
              />
            </Col>
          </Row>
          {errors.length === 0 ? null : (
            <ul style={{ padding: '0', listStyleType: 'none' }}>
              {errors.map(err => (
                <li key={err} className="bg-danger">
                  {err}
                </li>
              ))}
            </ul>
          )}
          <hr />
          <ButtonToolbar>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={this.props.submitting}
            >
              <FormattedMessage {...messages.submit} />
            </button>
          </ButtonToolbar>
        </fieldset>
      </form>
    );
  }
}

export default compose(
  connect(state => ({
    editFormValues: getFormValues('editForm')(state),
  })),
  reduxForm({
    form: 'editForm',
    validate,
    enableReinitialize: true,
  }),
)(injectIntl(withStyles(layoutStyle)(EditForm)));
