/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';
import { Row, Col, ToggleButton } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Loading from 'components/Loading';
import tableMessages from 'components/Table/messages';
import Table from 'components/Table';

import { getErrorsFromApollo } from '../../../core/errors/util';
import errorMessages from '../../../core/errors/messages';
import layoutStyle from '../../../styles/base/layout.scss';
import s from './Table.scss';
import messages from './messages';
import { calcColorBrightness } from '../../../util';
class WorkCategoryManagementTable extends React.Component {
  static propTypes = {
    workCategoriesQuery: PropTypes.shape({
      loading: PropTypes.bool,
      refetch: PropTypes.func.isRequired,
      workCategories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          isActive: PropTypes.bool,
          name: PropTypes.string,
          color: PropTypes.string,
          description: PropTypes.string,
        }).isRequired,
      ),
    }),
    deleteWorkCategory: PropTypes.func.isRequired,
    updateActiveWorkCategory: PropTypes.func.isRequired,
    updateDefaultWorkCategory: PropTypes.func.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
  };


  constructor(props) {
    super(props);

    this.createColumns = this.createColumns.bind(this);
    this.createToggleButton = this.createToggleButton.bind(this);
    this.invertActive = this.invertActive.bind(this);
    this.invertDefault = this.invertDefault.bind(this);
    this.deleteWorkCategory = this.deleteWorkCategory.bind(this);
    this.setErrors = this.setErrors.bind(this);
    this.state = {
      errors: [],
    };
  }

  setErrors(e) {
    const errors = getErrorsFromApollo(e);
    this.setState({
      errors: this.state.errors.concat(
        errors.map(err =>
          this.props.intl.formatMessage(
            errorMessages[err.message],
            err.messageValues,
          ),
        ),
      ),
    });
    setTimeout(() => {
      this.setState({ errors: [] });
    }, '3000');
  }

  async invertActive(args) {
    const userActiveInput = {
      id: args.id,
      isActive: !args.isActive,
    };
    await this.setState({ errors: [] });
    try {
      await this.props.updateActiveWorkCategory(userActiveInput);
    } catch (e) {
      this.setErrors(e);
    }
  }

  async invertDefault(args) {
    const userDefaultInput = { id: args.id, isDefault: !args.isDefault };
    await this.setState({ errors: [] });
    try {
      await this.props.updateDefaultWorkCategory(userDefaultInput);
    } catch (e) {
      this.setErrors(e);
    }
  }

  async deleteWorkCategory(id) {
    await this.setState({ errors: [] });

    try {
      await this.props.deleteWorkCategory(id);
      this.props.workCategoriesQuery.refetch();
    } catch (e) {
      this.setErrors(e);
    }
  }

  createColumns() {
    const { match: { path } } = this.props;

    const columns = [
      {
        Header: <FormattedMessage {...messages.nameHeading} />,
        accessor: 'name',
        width: 200,
        filterable: true,
        Cell: ({ original }) => <span>{original.name}</span>,
        filterMethod: (filter, row) => {
          const filterString = filter.value.toLowerCase();
          const name = row._original.name.toLowerCase(); // eslint-disable-line no-underscore-dangle

          return name.indexOf(filterString) !== -1;
        },
      },
      {
        Header: <FormattedMessage {...messages.colorHeading} />,
        accessor: 'color',
        width: 150,
        filterable: false,
        Cell: ({ original }) => (
          <div
            style={{
              backgroundColor: original.color,
              color: calcColorBrightness(
                original.color,
              ),
            }}
            className={s.colorBox}
          >
            <span>{original.color}</span>
          </div>
        ),
        Filter: () => null,
      },
      {
        Header: <FormattedMessage {...messages.descriptionHeading} />,
        accessor: 'description',
        filterable: false,
        width: 350,
        Cell: ({ original }) => <span>{original.description}</span>,
        Filter: () => null,
      },
      {
        Header: <FormattedMessage {...messages.activeHeading} />,
        width: 200,
        filterable: false,
        Cell: ({ original }) => this.createToggleButton(original),
      },
      {
        Header: <FormattedMessage {...messages.defaultHeading} />,
        width: 200,
        filterable: false,
        Cell: ({ original }) => this.createIsDefaultToggleButton(original),
      },
      {
        Header: <FormattedMessage {...tableMessages.actions} />,
        width: 100,
        filterable: false,
        Cell: ({ original }) => (
          <>
            <Row>
              <Col xs={1}>
                <button
                  className={s.trashcan}
                  onClick={() => this.deleteWorkCategory(original.id)}
                >
                  <FaTrash />
                </button>
              </Col>
              <Col xs={1}>
                <Link to={`${path}${original.id}/edit`}>
                  <FaEdit />
                </Link>
              </Col>
            </Row>
          </>
        ),
        Filter: () => null,
      },
    ];

    return columns;
  }

  createToggleButton(args) {
    const message = args.isActive
      ? tableMessages.active
      : tableMessages.inactive;
    return (
      <ToggleButton
        className="tgl-btn"
        checked={args.isActive}
        type="checkbox"
        value={args.isActive}
        onChange={() => this.invertActive(args)}
        style={{ width: '125px' }}
      >
        {<FormattedMessage {...message} />}
      </ToggleButton>
    );
  }

  createIsDefaultToggleButton(args) {
    const { isDefault } = args;
    const message = isDefault
      ? tableMessages.default
      : tableMessages.notDefault;
    return (
      <ToggleButton
        className="tgl-btn"
        checked={isDefault}
        type="checkbox"
        value={isDefault}
        onChange={() => this.invertDefault(args)}
        style={{ width: '125px' }}
      >
        {<FormattedMessage {...message} />}
      </ToggleButton>
    );
  }

  render() {
    const {
      workCategoriesQuery: { loading, workCategories },
      match: { path },
    } = this.props;
    const { errors } = this.state;
    if (loading) return <Loading />;
    const columns = this.createColumns();
    return (
      <div className={s.tableWrapper}>
        <Row className={layoutStyle.noMargin} style={{ marginBottom: '15px' }}>
          <Col xs={12} className={layoutStyle.noPadding}>
            <Link to={`${path}new`} className="btn btn-primary">
              <FaPlus className="icon--prepended icon-button" />
              <FormattedMessage {...messages.createWorkCategory} />
            </Link>
          </Col>
        </Row>
        <Row className={layoutStyle.noMargin}>
          <Col xs={12} className={layoutStyle.noPadding}>
            <Table
              keyField="name"
              data={workCategories}
              columns={columns}
              filterable
            />
          </Col>
        </Row>
        {errors.length !== 0 && (
          <div className={s.errorWrapper}>
            <ul className={s.errorBox}>
              {errors.map(err => (
                <li key={err} className="bg-danger">
                  {err}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export const workCategoriesQuery = gql`
  query workCategories {
    workCategories(showInactive: true) {
      id
      name
      isActive
      isDefault
      description
      color
    }
  }
`;

export const updateActiveMutation = gql`
  mutation updateIsActiveWorkCategory($workCategory: WorkCategoryActiveInput!) {
    updateIsActiveWorkCategory(workCategory: $workCategory) {
      id
      isActive
    }
  }
`;

export const updateDefaultMutation = gql`
  mutation updateIsDefaultWorkCategory(
    $workCategory: WorkCategoryDefaultInput!
  ) {
    updateIsDefaultWorkCategory(workCategory: $workCategory) {
      id
      isDefault
    }
  }
`;

export const deleteWorkCategory = gql`
  mutation deleteWorkCategory($id: String!) {
    deleteWorkCategory(id: $id)
  }
`;

export default compose(
  graphql(workCategoriesQuery, {
    name: 'workCategoriesQuery',
  }),
  graphql(deleteWorkCategory, {
    props: ({ mutate }) => ({
      deleteWorkCategory: id =>
        mutate({
          variables: { id },
        }),
    }),
  }),
  graphql(updateActiveMutation, {
    props: ({ mutate }) => ({
      updateActiveWorkCategory: workCategory =>
        mutate({
          variables: { workCategory },
        }),
    }),
  }),
  graphql(updateDefaultMutation, {
    props: ({ mutate }) => ({
      updateDefaultWorkCategory: workCategory =>
        mutate({
          variables: { workCategory },
        }),
    }),
  }),
  injectIntl,
)(withStyles(layoutStyle, s)(WorkCategoryManagementTable));
