exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".btn{border-radius:0}.dropdown-toggle,.dropdown-toggle:active,.dropdown-toggle:focus{border-color:#ddd;background-color:#ddd}.tgl-btn.active,.tgl-btn.active:active,.tgl-btn.active:focus,.tgl-btn.active:hover{background-color:#00dcb2;border:none}.tgl-btn>input{visibility:hidden}.tgl-btn,.tgl-btn:active,.tgl-btn:focus,.tgl-btn:hover{padding-left:0;background-color:#ccc;border:none}.btn-small{padding:0;border:0;background:none;color:#ff5722}.btn-primary{background-color:#00dcb2;border-color:#00dcb2}.btn-primary:active,.btn-primary:focus,.btn-primary:hover{color:#00dcb2;background-color:#fff;border-color:#00dcb2}.btn-link,.btn-link:active,.btn-link:focus,.btn-link:hover{color:#00dcb2;background:none;border:0}.btn-link:active,.btn-link:focus,.btn-link:hover{color:#66ead0}.btn-white-rnd,.btn-white-rnd:active,.btn-white-rnd:focus{background-color:#fff;color:#00dcb2;border:1.2px solid #00dcb2;border-radius:0;font-weight:400;text-align:center;outline:none}.btn-white-rnd:hover{text-decoration:none;color:#00dcb2;border:1.2px solid #00dcb2}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"btn": "btn",
	"dropdown-toggle": "dropdown-toggle",
	"tgl-btn": "tgl-btn",
	"active": "active",
	"btn-small": "btn-small",
	"btn-primary": "btn-primary",
	"btn-link": "btn-link",
	"btn-white-rnd": "btn-white-rnd"
};