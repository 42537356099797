import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { defineMessages, FormattedMessage } from 'react-intl';
import { FaGitlab, FaJira } from 'react-icons/fa';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import meQuery from 'components/Auth/queries/meQuery';
import s from './SyncProjectsButton.scss';

const messages = defineMessages({
  syncGitLabProjects: {
    id: 'syncProjectsButton.syncGitLabProjects',
    defaultMessage: 'Sync GitLab Projects',
    description: 'Label for sync projects button',
  },
  syncJiraAppProjects: {
    id: 'syncProjectsButton.syncJiraAppProjects',
    defaultMessage: 'Sync Jira Projects',
    description: 'Label for sync projects button',
  },
});

class SyncProjectsButton extends React.Component {
  static propTypes = {
    syncProjects: PropTypes.func.isRequired,
    filter: PropTypes.shape({
      month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      project: PropTypes.string,
    }),
    oAuthProviderQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      currentOAuthProvider: PropTypes.string,
    }).isRequired,
    appliedValues: PropTypes.shape({}),
  };

  static defaultProps = {
    appliedValues: {},
    filter: null,
  };

  constructor(props) {
    super(props);

    this.sync = this.sync.bind(this);

    this.state = {
      submitting: false,
    };
  }

  async sync() {
    const { filter } = this.props;
    this.setState({ submitting: true });
    try {
      await this.props.syncProjects({ filter });
    } catch (e) {
      // do nothing
    }
    this.setState({ submitting: false });
  }

  render() {
    const { submitting } = this.state;
    const { currentOAuthProvider } = this.props.oAuthProviderQuery;
    if (currentOAuthProvider === 'local') {
      return null;
    }
    return (
      <button
        // eslint-disable-next-line prettier/prettier
        className={currentOAuthProvider === 'gitlab' ? `btn btn-primary ${s.syncProjectsButton} ${s.syncGitLabProjectsButton}` 
          : `btn btn-primary ${s.syncProjectsButton} ${s.syncJiraAppProjectsButton}` 
        }
        onClick={this.sync}
        disabled={submitting}
      >
        {currentOAuthProvider === 'gitlab' ? (
          <React.Fragment>
            <FaGitlab
              className="icon--prepended"
              style={{ position: 'relative', top: '2px' }}
            />
            <FormattedMessage {...messages.syncGitLabProjects} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <FaJira
              className="icon--prepended"
              style={{ position: 'relative', top: '2px' }}
            />
            <FormattedMessage {...messages.syncJiraAppProjects} />
          </React.Fragment>
        )}
      </button>
    );
  }
}

const syncProjectsMutation = gql`
  mutation syncProjects {
    syncProjects {
      id
      name
    }
  }
`;

const oAuthProviderQuery = gql`
  query oAuthProviderQuery {
    currentOAuthProvider
  }
`;

export default compose(
  graphql(syncProjectsMutation, {
    props: ({ mutate }) => ({
      syncProjects: ({ filter }) =>
        mutate({
          refetchQueries: [
            {
              query: meQuery,
              variables: { filter },
            },
          ],
        }),
    }),
  }),
  graphql(oAuthProviderQuery, {
    name: 'oAuthProviderQuery',
  }),
)(withStyles(s)(SyncProjectsButton));
