import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import RouteBundler from 'components/RouteBundler';
import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';

import Ratios from './Ratios';
import ProjectManagement from './ProjectManagement';
import IssueManagement from './IssueManagement';
import WorkCategoryManagement from './WorkCategoryManagement';

/* eslint-disable prettier/prettier */
const routes = [
  { path: 'ratios', component: Ratios, exact: true },
  { path: 'projectManagement', component: ProjectManagement },
  { path: 'issueManagement', component: IssueManagement, exact: true },
];
/* eslint-enable prettier/prettier */

class Controlling extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    showWorkCategory: PropTypes.bool.isRequired,
  };

  componentWillMount() {
    if (this.props.showWorkCategory)
      routes.push({
        path: 'workCategoryManagement',
        component: WorkCategoryManagement,
      });
  }

  render() {
    return (
      <RouteBundler
        prefix={`${this.props.match.path}/`}
        routes={routes}
        me={this.props.me}
      />
    );
  }
}

export default compose()(AuthenticationWrapper(Controlling));
