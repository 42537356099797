import { defineMessages } from 'react-intl';

const messages = defineMessages({
  actions: {
    id: 'table.actions',
    defaultMessage: 'Actions',
    description: 'Label for actions column in tables',
  },
  active: {
    id: 'table.active',
    defaultMessage: 'active',
    description: 'Label for toggle button "active" status',
  },
  inactive: {
    id: 'table.inactive',
    defaultMessage: 'inactive',
    description: 'Label for toggle button "inactive" status',
  },
  default: {
    id: 'table.default',
    defaultMessage: 'default',
    description: 'Label for toggle button "default" status',
  },
  notDefault: {
    id: 'table.notDefault',
    defaultMessage: 'not default',
    description: 'Label for toggle button "not default" status',
  },
});

export default messages;
