import genericErrors from './generic';
import projectErrors from './project';
import hoursEntryErrors from './hoursEntry';
import userErrors from './user';
import shortWorkErrors from './shortWork';
import managedProject from './managedProject';
import workCategories from './workCategories';

const errorMessages = {
  ...genericErrors,
  ...projectErrors,
  ...hoursEntryErrors,
  ...userErrors,
  ...shortWorkErrors,
  ...managedProject,
  ...workCategories,
};

export default errorMessages;
