/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';
export const SET_SESSION_EXPIRED = 'SET_SESSION_EXPIRED';
export const RESET_SESSION_EXPIRED = 'RESET_SESSION_EXPIRED';
export const VISIBILITY_PUBLIC = 'public';
export const SET_LICENSE_STATE = 'SET_LICENSE_STATE';
export const NOT_AUTHENTICATED = '401: User is not authenticated';

export const PROJECT_TYPE_STANDARD = 'standard';
export const PROJECT_TYPE_JIRA = 'jira';
export const PROJECT_TYPE_GITLAB = 'gitlab';
