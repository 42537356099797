exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1wbNJ{margin-bottom:15px;font-size:.9em}._2CdDr{background-color:#ff4e32;border-color:#ff4e32}._2CdDr:active,._2CdDr:focus,._2CdDr:hover{color:#ff4e32;border-color:#ff4e32}._1HooE{background-color:#3f51b5;border-color:#3f51b5}._1HooE:active,._1HooE:focus,._1HooE:hover{color:#3f51b5;border-color:#3f51b5}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"syncProjectsButton": "_1wbNJ",
	"syncGitLabProjectsButton": "_2CdDr",
	"syncJiraAppProjectsButton": "_1HooE"
};