exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3MCJo h1{font-size:2rem;margin-top:100px;text-transform:uppercase}@media(max-width:768px){._3MCJo h1{margin-top:30px}}@media(max-height:480px){._3MCJo h1{margin-top:30px}}._3MCJo fieldset{margin-top:-10px}._3MCJo .vI0kY{font-size:1.8rem;display:block;font-weight:400;line-height:normal}@media(max-width:480px){._3MCJo .vI0kY{font-size:1.4rem}}._3MCJo ._21tsx,._3MCJo ._21tsx:active,._3MCJo ._21tsx:focus{float:right;font-style:normal;padding:6px 20px;font-size:20px;font-weight:800;text-transform:uppercase;outline:none;text-decoration:none;margin:10px 0 10px 10px}@media(max-width:768px){._3MCJo ._21tsx,._3MCJo ._21tsx:active,._3MCJo ._21tsx:focus{padding:10px;width:100%;right:0}}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"resetPasswordForm": "_3MCJo",
	"subline": "vI0kY",
	"button": "_21tsx"
};