exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2lVCm h1,._2lVCm p{margin:0 auto}._2lVCm h1{margin-bottom:15px}", ""]);

// exports
exports.locals = {
	"expiredWrapper": "_2lVCm"
};