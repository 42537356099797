import React from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import render from 'components/ReduxForm/renderField';

class ColorPicker extends React.Component {
  static propTypes = {
    input: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleChangeColor = this.handleChangeColor.bind(this);
    this.state = { selectedColor: [] };
  }

  handleChangeColor(color) {
    this.setState({ selectedColor: color.hex });
    this.props.input.onChange(color.hex);
  }

  render() {
    const { input: { value } } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div style={{ width: '100%' }}>
          {render.renderInput({ ...this.props })}
        </div>
        <SketchPicker
          color={value || this.state.selectedColor}
          onChange={this.handleChangeColor}
          presetColors={[
            '#D0021B',
            '#F5A623',
            '#F8E71C',
            '#8B572A',
            '#7ED321',
            '#417505',
            '#BD10E0',
            '#9013FE',
            '#4A90E2',
            '#50E3C2',
            '#B8E986',
            '#000000',
            '#4A4A4A',
            '#9B9B9B',
            '#5690CF',
            '#FFFFFF',
          ]}
          disableAlpha
        />
      </div>
    );
  }
}

export default ColorPicker;
