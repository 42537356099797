exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "body{background-color:#fbfbfb}._1Zydm{float:left;margin-top:100px;margin-bottom:25px}@media(max-width:1200px){._1Zydm{margin-top:75px}}.zrdfI{margin-top:20px;margin-bottom:20px;margin-left:250px;width:calc(100vw - 270px)}@media(max-width:1200px){.zrdfI{width:100vw;margin-left:0;padding:0 15px}}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"layoutContainer": "_1Zydm",
	"layoutContent": "zrdfI"
};