const validations = {
  required: value => value,
  email: value =>
    !(value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)),
  slug: value => value && /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(value),
  greaterZero: value => value > 0,
  projectCategoryRequired: ({ projects, projectId, category }) => {
    const projectIndex = projects.findIndex(
      project => project.id === projectId,
    );
    const project = projects[projectIndex];
    if (project && project.mandatoryCategory) {
      return typeof category !== 'undefined' && category !== null;
    }
    return true;
  },
};

export default validations;
