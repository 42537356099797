import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import moment from 'moment';
import Loading from 'components/Loading';
import hoursEntryTypes from 'core/hoursEntryTypes';

import render from '../ReduxForm/renderField';
import validations from '../ReduxForm/validations';
import validationMessages from '../ReduxForm/messages';
import hoursEntryMessages from './messages';

import {
  TIME_ENTRY_DESCRIPTION_REQUIREMENT_ALL,
  TIME_ENTRY_DESCRIPTION_REQUIREMENT_BILLABLE,
  TIME_ENTRY_DESCRIPTION_REQUIREMENT_NONE,
} from './variables';

const messages = defineMessages({
  submit: {
    id: 'addOrUpdateHoursEntryForm.submit',
    defaultMessage: 'Submit',
    description: 'Submit button text in add hours entry form',
  },
});

const validate = (values, props) => {
  const errors = {};

  if (!validations.required(values.project)) {
    errors.project = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.greaterZero(values.hours)) {
    errors.hours = <FormattedMessage {...validationMessages.greaterZero} />;
  }
  if (
    (props.descriptionRequirement === TIME_ENTRY_DESCRIPTION_REQUIREMENT_ALL ||
      (props.descriptionRequirement ===
        TIME_ENTRY_DESCRIPTION_REQUIREMENT_BILLABLE &&
        values.project &&
        values.project.projectProps &&
        values.project.projectProps.billable)) &&
    (values.description == null || values.description.trim() === '')
  ) {
    errors.description = (
      <FormattedMessage {...validationMessages.descriptionRequired} />
    );
  }

  return errors;
};

class AddOrUpdateHoursEntryForm extends React.Component {
  static propTypes = {
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    issues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
      }),
    ).isRequired,
    meQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      me: PropTypes.shape({
        id: PropTypes.string.isRequired,
        weeklyHours: PropTypes.number.isRequired,
      }),
    }).isRequired,
    workCategoriesQuery: PropTypes.shape({
      loading: PropTypes.bool,
      refetch: PropTypes.func.isRequired,
      workCategories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          isActive: PropTypes.bool,
          name: PropTypes.string,
          color: PropTypes.string,
          description: PropTypes.string,
        }).isRequired,
      ),
    }).isRequired,
    onIssueInputChange: PropTypes.func,
    currentOAuthProvider: PropTypes.string.isRequired,
    timeFrom: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    timeTo: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    form: PropTypes.string,
    project: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleProjectSelect: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    initialValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    maxBulkAdditionHours: PropTypes.number.isRequired,
    showHomeOffice: PropTypes.bool.isRequired,
    showWorkCategory: PropTypes.bool.isRequired,
    descriptionRequirement: PropTypes.oneOf([
      TIME_ENTRY_DESCRIPTION_REQUIREMENT_ALL,
      TIME_ENTRY_DESCRIPTION_REQUIREMENT_BILLABLE,
      TIME_ENTRY_DESCRIPTION_REQUIREMENT_NONE,
    ]).isRequired,
    disabled: PropTypes.bool,
    serverValidation: PropTypes.string,
    submitting: PropTypes.bool.isRequired,
    errors: PropTypes.shape({
      project: PropTypes.node,
    }),
  };

  static defaultProps = {
    onIssueInputChange: () => {},
    disabled: false,
    timeFrom: null,
    timeTo: null,
    project: null,
    initialValues: {},
    form: 'addOrUpdateHoursEntryForm',
    serverValidation: null,
    errors: {},
  };

  constructor(props) {
    super(props);

    this.waitForUpdateHours = false;
    this.updateType = this.updateType.bind(this);

    this.updateHours = this.updateHours.bind(this);
    this.blockUpdateHours = this.blockUpdateHours.bind(this);

    this.typeSelectRef = React.createRef();
    this.workCategorySelectRef = React.createRef();
  }

  state = {
    isTypeWork: true,
    isTypeVacationOrSick: false,
  };

  componentDidMount() {
    const { initialValues } = this.props;
    if (Object.prototype.hasOwnProperty.call(initialValues, 'project')) {
      this.props.handleProjectSelect(this.props.initialValues.project);
    }
    if (this.props.showWorkCategory) this.props.workCategoriesQuery.refetch();
  }

  componentWillReceiveProps(nextProps) {
    const updateHours = this.waitForUpdateHours;
    if (updateHours) {
      const {
        timeFrom,
        timeTo,
        dispatch,
        showWorkCategory,
        workCategoriesQuery,
      } = nextProps;
      if (timeFrom && timeTo) {
        const timeFromMoment = moment(timeFrom.value, 'HH:mm');
        const timeToMoment = moment(timeTo.value, 'HH:mm');
        const minutes = timeToMoment.diff(timeFromMoment, 'minutes', true);
        const roundedHours = Math.round(minutes / 15) * 15 / 60;
        dispatch(change(this.props.form, 'hours', roundedHours.toFixed(2)));
      }
      if (
        showWorkCategory &&
        workCategoriesQuery.workCategories &&
        workCategoriesQuery.workCategories.length === 1
      )
        dispatch(
          change(this.props.form, 'workCategory', {
            value: workCategoriesQuery.workCategories[0].name,
            label: workCategoriesQuery.workCategories[0].name,
            color: workCategoriesQuery.workCategories[0].color,
            id: workCategoriesQuery.workCategories[0].id,
          }),
        );
      this.waitForUpdateHours = false;
    }
  }

  componentDidUpdate(prevProps) {
    const { project: prevProject } = prevProps;
    const { project, dispatch } = this.props;

    // reset issue if project gets changed
    if (prevProject != null && prevProject !== project) {
      dispatch(change('addOrUpdateHoursEntryForm', 'issue', ''));
    }
  }

  // tells componentWillReceiveProps to update hours after props are set
  updateHours() {
    this.waitForUpdateHours = true;
  }

  blockUpdateHours() {
    this.waitForUpdateHours = false;
  }

  updateType(event) {
    if (event.value !== hoursEntryTypes.WORK) {
      this.setState({ isTypeWork: false });
    } else {
      this.setState({ isTypeWork: true });
    }

    if (
      event.value === hoursEntryTypes.VACATION ||
      event.value === hoursEntryTypes.SICK
    ) {
      this.setState({ isTypeVacationOrSick: true });
      this.props.dispatch(
        change(
          'addOrUpdateHoursEntryForm',
          'hours',
          this.props.meQuery.me.weeklyHours / 5,
        ),
      );
    } else {
      this.setState({ isTypeVacationOrSick: false });
    }
  }

  render() {
    const {
      projects,
      disabled,
      submitting,
      errors,
      issues,
      onIssueInputChange,
      currentOAuthProvider,
      meQuery: { loading },
      initialValues,
      workCategoriesQuery,
      showWorkCategory,
      showHomeOffice,
    } = this.props;
    if (loading || (showWorkCategory && workCategoriesQuery.loading))
      return <Loading />;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <fieldset>
          <Field
            id="project"
            name="project"
            disabled={disabled}
            label={<FormattedMessage {...hoursEntryMessages.project} />}
            onChange={values => {
              this.props.handleProjectSelect(values);
            }}
            component={render.renderProjectSelect}
            projects={projects}
            customError={errors ? errors.project : null}
          />
          <Field
            id="type"
            name="type"
            disabled={disabled}
            onChange={this.updateType}
            label={<FormattedMessage {...hoursEntryMessages.type} />}
            component={render.renderSelectAlt}
            options={[
              { value: 'work', label: 'work' },
              { value: 'holiday', label: 'holiday' },
              { value: 'vacation', label: 'vacation' },
              { value: 'sick', label: 'sick' },
            ]}
            selectRef={this.typeSelectRef}
          />
          {showWorkCategory &&
            workCategoriesQuery.workCategories.length > 1 && (
              <Field
                id="workCategory"
                name="workCategory"
                disabled={disabled}
                label={
                  <FormattedMessage {...hoursEntryMessages.workCategory} />
                }
                component={render.renderSelectAlt}
                options={workCategoriesQuery.workCategories.map(
                  workCategory => ({
                    value: workCategory.name,
                    label: workCategory.name,
                    color: workCategory.color,
                    id: workCategory.id,
                  }),
                )}
                withColor
                selectRef={this.workCategorySelectRef}
              />
            )}
          {currentOAuthProvider === 'gitlab' && this.state.isTypeWork ? (
            <Field
              id="issue"
              name="issue"
              disabled={disabled}
              label={<FormattedMessage {...hoursEntryMessages.issue} />}
              component={render.renderIssueSelect}
              issues={issues}
              customError={errors ? errors.issue : null}
              onInputChange={onIssueInputChange}
            />
          ) : null}
          <Field
            id="date"
            name="date"
            disabled={disabled}
            label={
              <FormattedMessage
                {...(this.state.isTypeVacationOrSick
                  ? hoursEntryMessages.dateFrom
                  : hoursEntryMessages.date)}
              />
            }
            component={render.renderDate}
          />
          {!this.state.isTypeVacationOrSick ? (
            <Field
              id="timeFrom"
              name="timeFrom"
              disabled={disabled}
              label={<FormattedMessage {...hoursEntryMessages.timeFrom} />}
              component={render.renderTime}
              formName={this.props.form}
              interval={15}
              handleUpdate={this.updateHours}
              manualUpdate
              initialValue={initialValues.timeFrom || { value: null }}
            />
          ) : null}
          {!this.state.isTypeVacationOrSick ? (
            <Field
              id="timeTo"
              name="timeTo"
              disabled={disabled}
              label={<FormattedMessage {...hoursEntryMessages.timeTo} />}
              component={render.renderTime}
              formName={this.props.form}
              interval={15}
              handleUpdate={this.updateHours}
              initialValue={initialValues.timeTo || { value: null }}
              manualUpdate={false}
            />
          ) : null}
          {this.state.isTypeVacationOrSick ? (
            <Field
              id="dateTo"
              name="dateTo"
              disabled={disabled}
              label={<FormattedMessage {...hoursEntryMessages.dateTo} />}
              component={render.renderDate}
            />
          ) : null}
          <Field
            id="hours"
            name="hours"
            pattern="[0-9]+([\.,][0-9]+)?"
            step={this.state.isTypeVacationOrSick ? '0.1' : '0.25'}
            max={this.props.maxBulkAdditionHours}
            min={0}
            disabled={disabled}
            label={
              <FormattedMessage
                {...(this.state.isTypeVacationOrSick
                  ? hoursEntryMessages.dailyHours
                  : hoursEntryMessages.hours)}
              />
            }
            component={render.renderNumber}
            onFocus={this.blockUpdateHours}
          />
          <Field
            id="description"
            name="description"
            disabled={disabled}
            label={<FormattedMessage {...hoursEntryMessages.description} />}
            component={render.renderTextarea}
            fixWidth
          />
          {showHomeOffice &&
            this.state.isTypeWork && (
              <Field
                id="homeOffice"
                name="homeOffice"
                disabled={disabled}
                label={
                  <FormattedMessage {...hoursEntryMessages.isHomeOffice} />
                }
                component={render.renderCheckbox}
                fixWidth
              />
            )}
          <p className="bg-danger">{this.props.serverValidation}</p>
          {!disabled && (
            <button
              type="submit"
              className="btn btn-primary"
              disabled={disabled || submitting}
            >
              <FormattedMessage {...messages.submit} />
            </button>
          )}
        </fieldset>
      </form>
    );
  }
}

const AddOrUpdateHoursEntryReduxForm = reduxForm({
  form: 'addOrUpdateHoursEntryForm',
  validate,
})(injectIntl(AddOrUpdateHoursEntryForm));

const userQuery = gql`
  query meQuery {
    me {
      id
      weeklyHours
    }
  }
`;

const workCategoriesQuery = gql`
  query workCategories {
    workCategories(showInactive: false) {
      id
      name
      isActive
      isDefault
      description
      color
    }
  }
`;

export default compose(
  connect((state, ownProps) => {
    const selector = formValueSelector(
      ownProps.form || 'addOrUpdateHoursEntryForm',
    );
    return {
      date: selector(state, 'date'),
      timeFrom: selector(state, 'timeFrom'),
      timeTo: selector(state, 'timeTo'),
      project: selector(state, 'project'),
    };
  }),
  graphql(userQuery, {
    name: 'meQuery',
  }),
  graphql(workCategoriesQuery, {
    name: 'workCategoriesQuery',
    skip: props => !props.showWorkCategory,
  }),
)(AddOrUpdateHoursEntryReduxForm);
