import { defineMessages } from 'react-intl';

const messages = defineMessages({
  required: {
    id: 'validation.required',
    defaultMessage: 'This field is required',
    description: 'Validation error message for required fields',
  },
  password: {
    id: 'validation.password',
    defaultMessage:
      'Your password must be at least 8 characters long, containing an upper case letter, digit and special character',
    description: 'Validation error message for password',
  },
  email: {
    id: 'validation.email',
    defaultMessage: 'Not a valid e-mail address',
    description: 'Validation error message for malformed e-mail addresses',
  },
  passwordConfirmation: {
    id: 'validation.passwordConfirmation',
    defaultMessage: 'Passwords do not match',
    description: 'Validation error message for password Confirmation',
  },
  greaterZero: {
    id: 'validation.greaterZero',
    defaultMessage: 'Field Hours must be greater than zero',
    description: 'Validation error message for greater zero fields',
  },
  overlappingTimes: {
    id: 'validation.overlappingTimes',
    defaultMessage: 'The entry must not overlap with another time entry',
    description: 'Validation error message for overlapping time entries',
  },
  exceedingMaxHours: {
    id: 'validation.exceedingMaxHours',
    defaultMessage: `
      There cannot be any time span of more than {maxTimespanHours} hours without any break of at least {minBreakMinutes} minutes in it.
    `,
    description: 'Validation error message for overlapping time entries',
  },
  exceedingMaxBulkHours: {
    id: 'validation.exceedingMaxBulkHours',
    defaultMessage: `
      Bulk additions cannot be greather than {hours} hours.
    `,
    description: 'Validation error message for overlapping time entries',
  },
  exceedingMaxNonBulkHours: {
    id: 'validation.exceedingMaxNonBulkHours',
    defaultMessage: `
      Non bulk additions cannot be greather than 24 hours.
    `,
    description: 'Validation error message for overlapping time entries',
  },
  descriptionRequired: {
    id: 'validation.descriptionRequired',
    defaultMessage: `
      A description is required for time entries of this project.
    `,
    description: 'Validation error message for overlapping time entries',
  },
  projectCategoryRequired: {
    id: 'validation.projectCategoryRequired',
    defaultMessage: 'This project only allows entries for categories',
    description: 'Validation error message for a missing project category',
  },
  submit: {
    id: 'form.submit',
    defaultMessage: 'Save',
    description: 'Default submit button label',
  },
  cancel: {
    id: 'form.cancel',
    defaultMessage: 'Cancel',
    description: 'Default cancel button label',
  },
  unsavedChanges: {
    id: 'form.unsavedChanges',
    defaultMessage: 'This form contains unsaved changes',
    description: 'Info message if form contains unsaved changes',
  },
  add: {
    id: 'form.add',
    defaultMessage: 'Add',
    description: 'Default value for add (entry) buttons',
  },
  remove: {
    id: 'form.remove',
    defaultMessage: 'Delete',
    description: 'Default value for remove (entry) buttons',
  },
  pleaseChoose: {
    id: 'form.pleaseChoose',
    defaultMessage: 'Please choose ...',
    description: 'Default value for empty option for select fields',
  },
});

export default messages;
