import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './Footer.scss';
import version from '../../version';

class Footer extends React.Component {
  render() {
    return (
      <div className={s.footer}>
        <div className={s.version}>{version.buildInfo}</div>
      </div>
    );
  }
}

export default withStyles(s)(Footer);
