import { defineMessages } from 'react-intl';

// NOTE: These messages have to be unique among the error message files

const messages = defineMessages({
  shortWorkNotFound: {
    id: 'errors.shortWork.shortWorkNotFound',
    defaultMessage: 'ShortWork not found.',
    description: 'Error message for ShortWork not found',
  },
  shortWorkUserNotFound: {
    id: 'errors.shortWork.shortWorkUserNotFound',
    defaultMessage: 'ShortWorkUser not found',
    description: 'Error message for ShortWorkUser not found',
  },
  shortWorkActive: {
    id: 'errors.shortWork.shortWorkActive',
    defaultMessage: 'Short Work is active and cannot be deleted!',
    description: 'Error message on delete for ShortWork is active',
  },
  shortWorkUserExists: {
    id: 'errors.shortWork.shortWorkUserExists',
    defaultMessage: 'ShortWorkUser exist and cannot be deleted!',
    description: 'Error message on delete for existing ShortWorkUser',
  },
});

export default messages;
