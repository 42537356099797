import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FaAtlassian } from 'react-icons/fa';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

// eslint-disable-next-line css-modules/no-unused-class
import s from './LoginForm.scss';

import messages from './messages';

class LoginAtlassian extends React.Component {
  render() {
    return (
      <div className={s.loginForm}>
        <h1 style={{ margin: '0 0 5px 0' }}>
          <FormattedMessage {...messages.heading} />
        </h1>
        <span className={s.subline}>
          <FormattedMessage {...messages.infoAtlassian} />
        </span>
        <form className="default-form">
          <fieldset>
            <div
              className="btn-group btn-group-justified"
              style={{ marginTop: '40px' }}
            >
              <div className="btn-group">
                <a
                  href="/jira/oauth/connect"
                  className={`btn btn-primary ${s.atlassianLoginButton}`}
                >
                  <FaAtlassian className="icon--prepended" />
                  <FormattedMessage
                    {...messages.submit}
                    values={{ provider: 'Jira' }}
                  />
                </a>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(LoginAtlassian));
