/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { change, untouch, stopSubmit } from 'redux-form';
import moment from 'moment';
import { FaEdit, FaEye, FaTrashAlt, FaHome } from 'react-icons/fa';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import _ from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';

import validations from 'components/ReduxForm/validations';
import validationMessages from 'components/ReduxForm/messages';
import Loading from 'components/Loading';

import s from './HoursList.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';
import EmployeeBalance from '../EmployeeBalance';
import AddOrUpdateHoursEntryForm from './AddOrUpdateHoursEntryForm';
import { DB_DATE_FORMAT } from '../../util';
import { dateOnlyToString, stringToDateOnly } from '../../core/dateonly';
import {
  getIssuesForProject,
  handleIssueInputChangeAsync,
  handleProjectSelect,
} from './util';

const getProjectOption = (projects, hoursEntry) => {
  const projectOption = {
    value: projects[0].id,
    label: projects[0].name,
  };
  if (hoursEntry) {
    if (hoursEntry.category) {
      projectOption.value = `${hoursEntry.project.id}_${
        hoursEntry.category.id
      }`; // eslint-disable-line prettier/prettier
      projectOption.label = hoursEntry.category.name;
      projectOption.isCategory = true;
      projectOption.projectName = hoursEntry.project.name;
      projectOption.projectProps = hoursEntry.project;
    } else {
      projectOption.value = hoursEntry.project.id;
      projectOption.label = hoursEntry.project.name;
    }
  }
  return projectOption;
};

const getIssueOption = (issues, hoursEntry) => {
  let value = [];
  let label = [];
  if (issues && issues.length > 0) {
    value = issues[0].iid;
    label = issues[0].title;
  }
  if (hoursEntry && !hoursEntry.issue) {
    value = null;
    label = null;
  }
  if (hoursEntry && hoursEntry.issue && issues.length > 0) {
    value = hoursEntry.issue.ticketId;
    label = `#${hoursEntry.issue.ticketId} ${hoursEntry.issue.title}`;
  }
  return { value, label };
};

class List extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    editable: PropTypes.bool,
    withBalance: PropTypes.bool,
    filter: PropTypes.shape({
      month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      project: PropTypes.string,
      type: PropTypes.string,
    }),
    oAuthProviderQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      currentOAuthProvider: PropTypes.string,
    }).isRequired,
    maxBulkAdditionHoursQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      maxBulkAdditionHours: PropTypes.number,
    }).isRequired,
    descriptionRequirementQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      descriptionRequirement: PropTypes.string,
    }).isRequired,
    showHomeOfficeQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      showHomeOffice: PropTypes.bool,
    }).isRequired,
    showWorkCategoryQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      showWorkCategory: PropTypes.bool,
    }).isRequired,
    updateHoursEntry: PropTypes.func.isRequired,
    deleteHoursEntry: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    editable: false,
    withBalance: true,
    filter: {},
  };

  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteHoursEntry = this.deleteHoursEntry.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);

    this.state = {
      hoursEntry: null,
      modals: {
        editHoursEntry: {
          show: false,
          disabled: false,
        },
      },
      alertOpen: false,
      serverValidation: null,
      formErrors: {},
      projectId: null,
      issues: [],
    };
  }

  setHoursEntryToEditAndOpenModal({ hoursEntry, disabled }) {
    this.setState({ hoursEntry }, () =>
      this.openModal('editHoursEntry', disabled),
    );
  }

  setEntryAndOpenAlert({ hoursEntry }) {
    this.setState({
      hoursEntry,
    });
    this.toggleAlert();
  }

  deleteHoursEntry() {
    if (this.state.hoursEntry) {
      this.props.deleteHoursEntry(this.state.hoursEntry.id, this.props.filter);
    }
    this.toggleAlert();
  }

  async openModal(key, disabled) {
    const update = { ...this.state };
    update.modals[key].show = true;
    update.modals[key].disabled = disabled;

    if (key === 'editHoursEntry') {
      update.issues = await getIssuesForProject(this, {
        projectId: update.hoursEntry.project.id,
      });
    }

    this.setState(update);
  }

  closeModal(key) {
    const update = { ...this.state };
    update.modals[key].show = false;
    this.setState(update);
  }

  toggleAlert() {
    this.setState({
      alertOpen: !this.state.alertOpen,
    });
  }

  async handleSubmit(formValues) {
    const { id, ...patch } = formValues;
    const { timeTo } = patch;
    const values = _.omit(patch, [
      'timeTo',
      'issue',
      'createdAt',
      '__typename',
    ]);
    const {
      user,
      dispatch,
      showWorkCategoryQuery: { showWorkCategory },
    } = this.props;
    const { projects } = user;

    const projectOption = _.get(values, 'project.value');
    const projectAndCategory = projectOption.split('_');
    values.project = projectAndCategory[0];
    if (projectAndCategory.length === 2) {
      values.category = projectAndCategory[1];
    } else {
      values.category = null;
    }

    if (_.get(formValues, 'type')) {
      values.type = _.get(formValues, 'type').value;
    }

    if (_.get(formValues, 'issue.value')) {
      const title = _.get(formValues, 'issue.label').split(
        `#${_.get(formValues, 'issue.value')} `,
      );
      const issue = {
        ticketId: _.get(formValues, 'issue.value'),
        title: title[1],
      };
      values.issue = { ...issue };
    } else {
      values.issue = null;
    }

    // format date
    if (_.get(values, 'date')) {
      values.date = stringToDateOnly(values.date);
    }

    values.timeFrom = moment(values.timeFrom.value, 'HH:mm');
    if (showWorkCategory) values.workCategory = formValues.workCategory.id;

    try {
      // project category validation
      //   unfortunately cannot do this directly in the form, because
      //   redux-form's validation function has no access to the
      //   component's props
      if (
        !validations.projectCategoryRequired({
          projects,
          projectId: values.project,
          category: values.category,
        })
      ) {
        const errors = {
          project: (
            <FormattedMessage {...validationMessages.projectCategoryRequired} />
          ),
        };
        dispatch(stopSubmit('updateHoursEntryForm', errors));
        this.setState({ formErrors: errors });
        return false;
      }
      await this.props.updateHoursEntry(id, values, this.props.filter);

      this.props.dispatch(
        change('updateHoursEntryForm', 'timeFrom', {
          value: timeTo.value,
          label: timeTo.label,
        }),
      );
      this.props.dispatch(untouch('updateHoursEntryForm', 'hours'));
      this.props.dispatch(change('updateHoursEntryForm', 'hours', 0));
      this.setState({ serverValidation: null });
      this.closeModal('editHoursEntry');
      return true;
    } catch (e) {
      const validation = e.message.substring(e.message.indexOf(':') + 1);
      this.setState({ serverValidation: validation });
      return false;
    }
  }

  render() {
    if (
      this.props.oAuthProviderQuery.loading ||
      this.props.maxBulkAdditionHoursQuery.loading ||
      this.props.descriptionRequirementQuery.loading ||
      this.props.showHomeOfficeQuery.loading ||
      this.props.showWorkCategoryQuery.loading
    ) {
      return <Loading />;
    }

    const {
      user,
      editable,
      withBalance,
      showWorkCategoryQuery: { showWorkCategory },
    } = this.props;
    const { currentOAuthProvider } = this.props.oAuthProviderQuery;
    const { projects } = user;
    const { hoursEntry, issues } = this.state;
    let previousDay = null;
    let dayTotal = 0;
    return (
      <div>
        {withBalance && <EmployeeBalance user={user} />}
        <table className={`table table-striped ${s.hoursListTable}`}>
          <thead>
            <tr>
              <th>
                <FormattedMessage {...messages.time} />
              </th>
              <th>
                <FormattedMessage {...messages.hours} />
              </th>
              <th>
                <FormattedMessage {...messages.type} />
              </th>
              {showWorkCategory && (
                <th className={s.hideOnSmall}>
                  <FormattedMessage {...messages.workCategory} />
                </th>
              )}
              <th>
                <FormattedMessage {...messages.project} />
              </th>
              <th className={s.hideOnSmall}>
                <FormattedMessage {...messages.category} />
              </th>
              <th>
                <FormattedMessage {...messages.issue} />
              </th>
              <th className={s.hideOnSmall}>
                <FormattedMessage {...messages.description} />
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {user.hoursEntries.map(entry => {
              const row = [];
              const entryWasCreatedToday = moment().isSame(
                moment(entry.createdAt, DB_DATE_FORMAT),
                'day',
              );
              if (previousDay !== entry.date) {
                row.push(
                  <tr key={`${entry.id}__separator`} className={s.separator}>
                    <td colSpan={editable || entryWasCreatedToday ? 10 : 9}>
                      {dateOnlyToString(entry.date)}
                    </td>
                  </tr>,
                );

                row.unshift(
                  <tr key={`${entry.id}__total`}>
                    <td colSpan="2" style={{ textAlign: 'right' }}>
                      <strong>
                        <FormattedMessage {...messages.dayTotal} />
                      </strong>
                    </td>
                    <td>
                      <strong>{dayTotal.toString()}</strong>
                    </td>
                    <td colSpan={editable ? 7 : 6} />
                  </tr>,
                );
                dayTotal = 0;
              }
              previousDay = entry.date;
              dayTotal += parseFloat(entry.hours, 10);
              row.push(
                <tr key={entry.id} className={s[entry.type]}>
                  <td className={s.timeSpanColumn}>
                    {`${moment(entry.timeFrom, 'HH:mm:ss').format(
                      'HH:mm',
                    )} - ${moment(entry.timeFrom, 'HH:mm:ss')
                      .add(entry.hours, 'hours')
                      .format('HH:mm')}`}
                  </td>
                  <td>{entry.hours}</td>
                  <td>
                    {entry.type}{' '}
                    {entry.type === 'work' && entry.homeOffice && <FaHome />}
                  </td>
                  {showWorkCategory && (
                    <td className={s.hideOnSmall}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          style={{
                            backgroundColor: entry.workCategory
                              ? entry.workCategory.color
                              : 'transparent',
                            borderRadius: 10,
                            content: '" "',
                            display: 'block',
                            marginRight: '8px',
                            height: 12,
                            width: 12,
                          }}
                        />
                        {entry.workCategory && entry.workCategory.name}
                      </div>
                    </td>
                  )}
                  <td>{entry.type === 'work' ? entry.project.name : ''}</td>
                  <td className={s.hideOnSmall}>
                    {entry.category && entry.category.name}
                  </td>
                  <td>
                    {entry.issue
                      ? `#${entry.issue.ticketId} ${entry.issue.title}`
                      : ''}
                  </td>
                  <td>
                    <div
                      title={entry.description}
                      className={`${s.description} ${s.hideOnSmall}`}
                    >
                      {entry.description}
                    </div>
                  </td>
                  {(editable || entryWasCreatedToday) && (
                    <td>
                      <button
                        className="btn btn-link"
                        onClick={() =>
                          this.setHoursEntryToEditAndOpenModal({
                            hoursEntry: entry,
                            disabled: false,
                          })
                        }
                      >
                        <FaEdit />
                      </button>
                      <button
                        className="btn btn-link"
                        onClick={() =>
                          this.setEntryAndOpenAlert({
                            hoursEntry: entry,
                            disabled: false,
                          })
                        }
                      >
                        <FaTrashAlt />
                      </button>
                    </td>
                  )}
                  {!editable &&
                    !entryWasCreatedToday && (
                      <td>
                        <button
                          className="btn btn-link"
                          onClick={() =>
                            this.setHoursEntryToEditAndOpenModal({
                              hoursEntry: entry,
                              disabled: true,
                            })
                          }
                        >
                          <FaEye />
                        </button>
                      </td>
                    )}
                </tr>,
              );
              return row;
            })}
            {user.hoursEntries.length > 0 && (
              <tr
                key={`${
                  user.hoursEntries[user.hoursEntries.length - 1].id
                }__total`}
              >
                <td colSpan="2" style={{ textAlign: 'right' }}>
                  <strong>
                    <FormattedMessage {...messages.dayTotal} />
                  </strong>
                </td>
                <td>
                  <strong>{dayTotal.toString()}</strong>
                </td>
                <td colSpan={editable ? 7 : 6} />
              </tr>
            )}
          </tbody>
        </table>
        <Modal
          show={this.state.modals.editHoursEntry.show}
          onHide={() => this.closeModal('editHoursEntry')}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.modals.editHoursEntry.disabled && (
                <FormattedMessage {...messages.viewHoursEntryModalTitle} />
              )}
              {!this.state.modals.editHoursEntry.disabled && (
                <FormattedMessage {...messages.editHoursEntryModalTitle} />
              )}
            </Modal.Title>
            <Modal.Body>
              <AddOrUpdateHoursEntryForm
                disabled={this.state.modals.editHoursEntry.disabled}
                form="updateHoursEntryForm"
                onSubmit={this.handleSubmit}
                currentOAuthProvider={currentOAuthProvider}
                projects={projects}
                handleProjectSelect={({ value, isCategory }) => {
                  handleProjectSelect(this, { value, isCategory }, true);
                }}
                issues={this.state.issues || []}
                onIssueInputChange={value => {
                  handleIssueInputChangeAsync(this, { value });
                }}
                maxBulkAdditionHours={
                  this.props.maxBulkAdditionHoursQuery.maxBulkAdditionHours
                }
                descriptionRequirement={
                  this.props.descriptionRequirementQuery.descriptionRequirement
                }
                initialValues={{
                  ...hoursEntry,
                  type: hoursEntry
                    ? { value: hoursEntry.type, label: hoursEntry.type }
                    : null,
                  date: hoursEntry ? dateOnlyToString(hoursEntry.date) : null,
                  timeFrom: hoursEntry
                    ? {
                        value: moment(hoursEntry.timeFrom, 'HH:mm').format(
                          'HH:mm',
                        ),
                      }
                    : null,
                  timeTo: hoursEntry
                    ? {
                        value: moment(hoursEntry.timeFrom, 'HH:mm')
                          .add(hoursEntry.hours, 'hours')
                          .format('HH:mm'),
                      }
                    : null,
                  project: getProjectOption(projects, hoursEntry),
                  issue: getIssueOption(issues, hoursEntry),
                  workCategory:
                    showWorkCategory && hoursEntry && hoursEntry.workCategory
                      ? {
                          value: hoursEntry.workCategory.name,
                          label: hoursEntry.workCategory.name,
                          color: hoursEntry.workCategory.color,
                          id: hoursEntry.workCategory.id,
                        }
                      : null,
                }}
                serverValidation={this.state.serverValidation}
                showHomeOffice={this.props.showHomeOfficeQuery.showHomeOffice}
                showWorkCategory={
                  this.props.showWorkCategoryQuery.showWorkCategory
                }
                errors={this.state.formErrors}
              />
            </Modal.Body>
          </Modal.Header>
        </Modal>
        {this.state.alertOpen ? (
          <SweetAlert
            warning
            showCancel
            confirmBtnText={this.props.intl.formatMessage(
              messages.deleteConfirm,
            )}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={this.props.intl.formatMessage(messages.deleteTitle)}
            onConfirm={this.deleteHoursEntry}
            onCancel={this.toggleAlert}
          >
            <FormattedMessage {...messages.deleteQuestion} />
          </SweetAlert>
        ) : null}
      </div>
    );
  }
}

const oAuthProviderQuery = gql`
  query oAuthProviderQuery {
    currentOAuthProvider
  }
`;

const maxBulkAdditionHoursQuery = gql`
  query maxBulkAdditionHoursQuery {
    maxBulkAdditionHours
  }
`;

const descriptionRequirementQuery = gql`
  query descriptionRequirementQuery {
    descriptionRequirement
  }
`;

const showHomeOfficeQuery = gql`
  query showHomeOfficeQuery {
    showHomeOffice
  }
`;

const showWorkCategoryQuery = gql`
  query showWorkCategoryQuery {
    showWorkCategory
  }
`;

const updateHoursEntryMutation = gql`
  mutation updateHoursEntry(
    $id: String!
    $patch: HoursEntryInput!
    $filter: HoursEntryFilter
  ) {
    updateHoursEntry(id: $id, patch: $patch) {
      id
      hoursEntries(filter: $filter) {
        id
        date
        timeFrom
        hours
        type
        homeOffice
        workCategory {
          id
          name
          isActive
          isDefault
          description
          color
        }
        project {
          id
          name
        }
        issue {
          id
          ticketId
          title
        }
        category {
          id
          name
        }
        description
        createdAt
      }
      dailyBalance {
        total
        done
        balance
      }
      weeklyBalance(filter: $filter) {
        total
        done
        balance
      }
      monthlyBalance(filter: $filter) {
        total
        done
        balance
      }
      totalBalance(filter: $filter) {
        total
        done
        balance
      }
      vacationBalance(filter: $filter) {
        total
        done
        balance
      }
    }
  }
`;

const deleteHoursEntryMutation = gql`
  mutation deleteHoursEntry($id: String!, $filter: HoursEntryFilter) {
    deleteHoursEntry(id: $id) {
      id
      hoursEntries(filter: $filter) {
        id
        date
        timeFrom
        hours
        type
        homeOffice
        workCategory {
          id
          name
          isActive
          isDefault
          description
          color
        }
        project {
          id
          name
        }
        category {
          id
          name
        }
        description
        createdAt
      }
      dailyBalance {
        total
        done
        balance
      }
      weeklyBalance(filter: $filter) {
        total
        done
        balance
      }
      monthlyBalance(filter: $filter) {
        total
        done
        balance
      }
      totalBalance(filter: $filter) {
        total
        done
        balance
      }
      vacationBalance(filter: $filter) {
        total
        done
        balance
      }
    }
  }
`;

export default compose(
  connect(),
  graphql(oAuthProviderQuery, {
    name: 'oAuthProviderQuery',
  }),
  graphql(maxBulkAdditionHoursQuery, {
    name: 'maxBulkAdditionHoursQuery',
  }),
  graphql(descriptionRequirementQuery, {
    name: 'descriptionRequirementQuery',
  }),
  graphql(showHomeOfficeQuery, {
    name: 'showHomeOfficeQuery',
  }),
  graphql(showWorkCategoryQuery, {
    name: 'showWorkCategoryQuery',
  }),
  graphql(updateHoursEntryMutation, {
    props: ({ mutate }) => ({
      updateHoursEntry: (id, patch, filter) =>
        mutate({
          variables: { id, patch, filter },
        }),
    }),
  }),
  graphql(deleteHoursEntryMutation, {
    props: ({ mutate }) => ({
      deleteHoursEntry: (id, filter) =>
        mutate({
          variables: { id, filter },
        }),
    }),
  }),
)(injectIntl(withStyles(s)(List)));
