import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';
import { injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './Login.scss';
import messages from '../messages';
import Loading from '../../Loading';
import LoginForm from './LoginForm';
import LoginAtlassian from './LoginAtlassian';
import LoginJiraApp from './LoginJiraApp';
import { withLogin } from '../withLogin';
import TopNavigationLogin from '../../AdminLayout/TopNavigationLogin';
import LoginGitlab from './LoginGitlab';

class Login extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    oAuthProviderQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      currentOAuthProvider: PropTypes.string.isRequired,
    }).isRequired,
    login: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
    successCallback: PropTypes.func,
    inModal: PropTypes.bool,
  };

  static defaultProps = {
    successCallback: history => {
      history.push({ pathname: '/admin/dashboard' });
    },
    inModal: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
    };

    this.setErrors = this.setErrors.bind(this);
    this.submitLoginForm = this.submitLoginForm.bind(this);
  }

  setErrors(errors) {
    this.setState({
      errors: errors.map(err => err.message),
    });
  }

  async submitLoginForm({ username, password }) {
    const { login, history, intl, successCallback } = this.props;
    const result = await login(username, password);

    if (!result) {
      this.setErrors([
        { message: intl.formatMessage(messages.invalidCredentials) },
      ]);
    } else {
      successCallback(history);
    }
  }

  render() {
    const { loading, currentOAuthProvider } = this.props.oAuthProviderQuery;
    if (loading) return <Loading />;
    let login = null;

    switch (currentOAuthProvider) {
      case 'atlassian':
        login = <LoginAtlassian />;
        break;
      case 'jiraApp':
        login = <LoginJiraApp inModal={this.props.inModal} />;
        break;
      case 'gitlab':
        login = <LoginGitlab inModal={this.props.inModal} />;
        break;
      default:
        login = (
          <LoginForm
            onSubmit={this.submitLoginForm}
            errors={this.state.errors}
            provider={currentOAuthProvider}
          />
        );
        break;
    }

    return (
      <div className={s.loginContainer}>
        <TopNavigationLogin />
        {login}
      </div>
    );
  }
}

const oAuthProviderQuery = gql`
  query oAuthProviderQuery {
    currentOAuthProvider
  }
`;

export default compose(
  graphql(oAuthProviderQuery, {
    name: 'oAuthProviderQuery',
  }),
  withRouter,
)(withLogin(injectIntl(withStyles(s)(Login))));
