import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Alert, { alertOpts } from 'components/Alert';

import TopNavigationLogin from '../AdminLayout/TopNavigationLogin';
import { messagesSet } from './messages';
import SetPasswordForm from './SetPasswordForm';
import s from './SetPassword.scss';

class SetPassword extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    setPassword: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        token: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      redirect: null,
      alert: {
        ...alertOpts.success,
      },
    };

    this.closeAlert = this.closeAlert.bind(this);
    this.submitSetPassword = this.submitSetPassword.bind(this);
  }

  async submitSetPassword(formValues) {
    // eslint-disable-next-line prettier/prettier
    const { setPassword, match: { params: { token }} } = this.props;
    try {
      await setPassword({
        password: formValues.password,
        token,
      });

      this.setState({
        errors: [],
        redirect: '/login',
        alert: {
          ...alertOpts.success,
          show: true,
          title: <FormattedMessage {...messagesSet.alertSuccessTitle} />,
          msg: <FormattedMessage {...messagesSet.alertSuccessMessage} />,
        },
      });
    } catch (e) {
      if (__DEV__) {
        console.error(e.message);
      }
      this.setState({
        errors: [],
        redirect: null,
        alert: {
          ...alertOpts.error,
          show: true,
          title: <FormattedMessage {...messagesSet.alertErrorTitle} />,
          msg: <FormattedMessage {...messagesSet.alertErrorMessage} />,
        },
      });
    }
  }

  closeAlert() {
    this.setState({ alert: { show: false } });
  }

  render() {
    const { alert, redirect } = this.state;
    if (!alert.show && redirect) {
      const { history } = this.props;
      history.push({ pathname: redirect });
    }
    return (
      <div className={`container ${s.setPasswordContainer}`}>
        <TopNavigationLogin />
        <SetPasswordForm
          onSubmit={this.submitSetPassword}
          errors={this.state.errors}
          initialValues={{}}
        />
        <Alert {...alert} onConfirm={this.closeAlert} />
      </div>
    );
  }
}

const setPasswordMutation = gql`
  mutation setPassword($password: String!, $token: String!) {
    setPassword(password: $password, token: $token) {
      id
      username
    }
  }
`;

export default compose(
  withStyles(s),
  withRouter,
  graphql(setPasswordMutation, {
    props: ({ mutate }) => ({
      setPassword: data =>
        mutate({
          variables: { ...data },
        }),
    }),
  }),
)(SetPassword);
