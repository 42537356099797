import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import render from '../ReduxForm/renderField';
import validations from '../ReduxForm/validations';
import validationMessages from '../ReduxForm/messages';

import buttonStyle from '../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import s from './SetPasswordForm.scss';

import { messagesSet } from './messages';

const validate = values => {
  const errors = {};
  if (!validations.required(values.password)) {
    errors.password = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.required(values.passwordControll)) {
    errors.passwordControll = (
      <FormattedMessage {...validationMessages.required} />
    );
  }
  if (!(values.passwordControll === values.password)) {
    errors.passwordControll = (
      <FormattedMessage {...validationMessages.passwordConfirmation} />
    );
  }

  return errors;
};

class ResetPasswordFrom extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  render() {
    const { intl, errors, handleSubmit } = this.props;
    return (
      <div className={s.resetPasswordForm}>
        <h1>
          <FormattedMessage {...messagesSet.heading} />
        </h1>
        <span className={s.subline}>
          <FormattedMessage {...messagesSet.info} />
        </span>
        <form className="default-form" onSubmit={handleSubmit}>
          <fieldset>
            <Field
              id="password"
              name="password"
              type="password"
              label={intl.formatMessage(messagesSet.password)}
              component={render.renderInput}
            />
            <Field
              id="passwordControll"
              name="passwordControll"
              type="password"
              label={intl.formatMessage(messagesSet.passwordRepeat)}
              component={render.renderInput}
            />
            {errors.length === 0 ? null : (
              <ul style={{ padding: '0', listStyleType: 'none' }}>
                {errors.map(err => (
                  <li key={err} className="validationError">
                    {err}
                  </li>
                ))}
              </ul>
            )}
            <button
              type="submit"
              className={`${buttonStyle['btn-primary']} ${s.button}`}
            >
              <FormattedMessage {...messagesSet.submit} />
            </button>
            <Link
              to="/login"
              className={`${buttonStyle['btn-white-rnd']} ${s.button}`}
            >
              <FormattedMessage {...messagesSet.cancel} />
            </Link>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default withStyles(s)(
  reduxForm({
    form: 'set-password',
    validate,
    pure: false, // this is necessary to trigger form re-renders if the locale changes
  })(injectIntl(ResetPasswordFrom)),
);
