import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, ButtonToolbar } from 'react-bootstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { FaTrash } from 'react-icons/fa';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import _ from 'lodash';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import formMessages from 'components/ReduxForm/messages';
import render from 'components/ReduxForm/renderField';
import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from './EditForm.scss';
import { VISIBILITY_PUBLIC } from '../../constants';
import validations from '../ReduxForm/validations';

const projectTypes = ['standard', 'gitlab', 'jira'];

const validate = values => {
  const errors = {};
  if (!validations.required(values.name)) {
    errors.name = <FormattedMessage {...formMessages.required} />;
  }
  return errors;
};

const messages = defineMessages({
  project: {
    id: 'editForm.project',
    defaultMessage: 'Project',
    description: 'Label for project field in edit form',
  },
  type: {
    id: 'editForm.type',
    defaultMessage: 'Project type',
    description: 'Label for project type field in edit form',
  },
  repositoryUrl: {
    id: 'editForm.repositoryUrl',
    defaultMessage: 'Repository Url',
    description: 'Label for Repository Url field in edit form',
  },
  mandatoryCategory: {
    id: 'editForm.mandatoryCategory',
    defaultMessage: 'mandatory Category',
    description: 'Label for mandatoryCategory field in edit form',
  },
  mandatoryIssue: {
    id: 'editForm.mandatoryIssue',
    defaultMessage: 'mandatory Issue',
    description: 'Label for mandatoryIssue field in edit form',
  },
  submit: {
    id: 'editForm.submit',
    defaultMessage: 'Apply',
    description: 'Submit button text in editForm form',
  },
  categoryName: {
    id: 'editForm.categoryName',
    defaultMessage: 'Category Name',
    description: 'Label for CategoryName in editForm form',
  },
  categoryUrl: {
    id: 'editForm.categoryUrl',
    defaultMessage: 'Category Url',
    description: 'Label for categoryUrl in editForm form',
  },
  categoryDescription: {
    id: 'editForm.categoryDescription',
    defaultMessage: 'Category Description',
    description: 'Label for categoryDescription in editForm form',
  },
  active: {
    id: 'user.active',
    defaultMessage: 'active',
    description: 'Label for active flag',
  },
  actions: {
    id: 'editForm.actions',
    defaultMessage: 'Actions',
    description: 'Label for actions in editForm form',
  },
  billable: {
    id: 'editForm.billable',
    defaultMessage: 'billable',
    description: 'Lavel for billable checkbox in editForm',
  },
});

class EditForm extends React.Component {
  static propTypes = {
    project: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      mandatoryIssue: PropTypes.bool.isRequired,
      mandatoryCategory: PropTypes.bool.isRequired,
      billable: PropTypes.bool.isRequired,
      repositoryUrl: PropTypes.string,
      visibility: PropTypes.string.isRequired,
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          repositoryUrl: PropTypes.string.isRequired,
          isLinked: PropTypes.bool.isRequired,
          active: PropTypes.bool.isRequired,
        }),
      ),
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    errors: [],
  };

  static renderCategories({ fields, meta: { error }, disabled }) {
    return (
      <table className={`table table-striped ${s.categoryTable}`}>
        <thead>
          <tr>
            <th>
              <FormattedMessage {...messages.categoryName} />
            </th>
            <th>
              <FormattedMessage {...messages.categoryDescription} />
            </th>
            <th>
              <FormattedMessage {...messages.active} />
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {fields.map((category, index) => (
            <tr key={category} className={s.category}>
              <td className={s.categoryField}>
                <Field
                  name={`${category}.name`}
                  component={render.renderInput}
                  disabled={disabled}
                />
              </td>
              <td className={s.categoryField}>
                <Field
                  name={`${category}.description`}
                  component={render.renderInput}
                  disabled={disabled}
                />
              </td>
              <td className={s.categoryField}>
                <Field
                  name={`${category}.active`}
                  label={<FormattedMessage {...messages.active} />}
                  component={render.renderCheckbox}
                />
              </td>
              {!fields.get(index).isLinked && (
                <td>
                  <button
                    type="button"
                    onClick={() => fields.remove(index)}
                    className={`btn btn-danger ${s.trash}`}
                    disabled={disabled}
                  >
                    <FaTrash />
                  </button>
                </td>
              )}
            </tr>
          ))}
          {error && <tr className="error">{error}</tr>}
          <tr>
            <td colSpan="4">
              <hr />
              <button
                type="button"
                className="btn btn-primary"
                onClick={() =>
                  fields.push({ name: 'new category', active: true })
                }
                disabled={disabled}
              >
                <i className="fa fa-plus fa--prepended" />
                <FormattedMessage {...formMessages.add} />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  constructor(props) {
    super(props);
    this.typeSelectRef = React.createRef();
  }

  render() {
    const { errors, project: { visibility } } = this.props;
    const isNew = _.isEmpty(this.props.project);
    const notEmptyPrivate = !isNew && visibility !== VISIBILITY_PUBLIC;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <fieldset>
          <Row className={layoutStyle.noMargin}>
            <Col xs={12} md={3} style={{ paddingLeft: '0px' }}>
              <Field
                id="name"
                name="name"
                label={<FormattedMessage {...messages.project} />}
                component={render.renderInput}
                disabled={notEmptyPrivate}
              />
            </Col>
            <Col xs={12} md={3} style={{ paddingLeft: '0px' }}>
              <Field
                id="type"
                name="type"
                label={<FormattedMessage {...messages.type} />}
                component={render.renderSelectAlt}
                disabled
                options={projectTypes.map(type => ({
                  value: type,
                  label: type,
                }))}
                selectRef={this.typeSelectRef}
              />
            </Col>
            <Col xs={12} md={3} style={{ paddingLeft: '0px' }}>
              <Field
                id="repositoryUrl"
                name="repositoryUrl"
                label={<FormattedMessage {...messages.repositoryUrl} />}
                component={render.renderInput}
                disabled
              />
            </Col>
            <Col xs={12} md={3} style={{ paddingLeft: '0px' }}>
              <Field
                id="mandatoryCategory"
                name="mandatoryCategory"
                label={<FormattedMessage {...messages.mandatoryCategory} />}
                component={render.renderCheckbox}
              />
            </Col>
            <Col xs={12} md={3} style={{ paddingLeft: '0px' }}>
              <Field
                id="mandatoryIssue"
                name="mandatoryIssue"
                label={<FormattedMessage {...messages.mandatoryIssue} />}
                component={render.renderCheckbox}
              />
            </Col>
            <Col xs={12} md={3} style={{ paddingLeft: '0px' }}>
              <Field
                id="billable"
                name="billable"
                label={<FormattedMessage {...messages.billable} />}
                component={render.renderCheckbox}
              />
            </Col>
          </Row>
          <hr />
          <FieldArray name="categories" component={EditForm.renderCategories} />
          {errors.length === 0 ? null : (
            <ul style={{ padding: '0', listStyleType: 'none' }}>
              {errors.map(err => (
                <li key={err} className="bg-danger">
                  {err}
                </li>
              ))}
            </ul>
          )}
          <hr />
          <ButtonToolbar>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={this.props.submitting}
            >
              <FormattedMessage {...messages.submit} />
            </button>
          </ButtonToolbar>
        </fieldset>
      </form>
    );
  }
}

export default reduxForm({
  form: 'editForm',
  validate,
  enableReinitialize: true,
})(injectIntl(withStyles(s, layoutStyle)(EditForm)));
