import { defineMessages } from 'react-intl';

const messages = defineMessages({
  heading: {
    id: 'license.expired.heading',
    defaultMessage: 'License expired',
    description: 'Heading for license expired component',
  },
  info: {
    id: 'license.expired.info',
    defaultMessage:
      'Your license has expired. You need to renew your license in order to continue to use this application.',
    description: 'Info text for license expired component',
  },
});

export default messages;
