import { defineMessages } from 'react-intl';

// NOTE: These messages have to be unique among the error message files

const messages = defineMessages({
  managedProjectCouldntBeCreated: {
    id: 'errors.managedProject.managedProjectCouldntBeCreated',
    defaultMessage: 'Could not create ManagedProject',
    description: 'Error message for no category with mandatory category',
  },
  immutableFields: {
    id: 'errors.managedProject.managedProjectCouldntImmutableFields',
    defaultMessage: 'Cannot change immutable fields.',
    description: 'Error message for immutable fields',
  },
});

export default messages;
