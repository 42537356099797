import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import Loading from 'components/Loading';

import messages from './messages';
import EditForm from './EditForm';
import { shortWorkListQuery } from './Table';
import { getErrorsFromApollo } from '../../core/errors/util';
import errorMessages from '../../core/errors/messages';
import { stringToDateOnly, DATEONLY_STRING_FMT } from '../../core/dateonly';
import { usersQuery } from '../User/Table';

const emptyShortWork = {};

class ShortWorkNew extends React.Component {
  static propTypes = {
    shortWorkQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      shortWork: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
        users: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            username: PropTypes.string.isRequired,
            ShortWorkUser: PropTypes.shape({
              id: PropTypes.string.isRequired,
              minWeeklyHours: PropTypes.number.isRequired,
              maxWeeklyHours: PropTypes.number.isRequired,
            }).isRequired,
          }),
        ),
      }),
    }).isRequired,
    usersQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          username: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    createShortWork: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(formValues) {
    this.setState({ errors: [] });
    const data = {
      id: formValues.id,
      name: formValues.name,
      active: formValues.active,
      start: stringToDateOnly(formValues.start),
      end: stringToDateOnly(formValues.end),
      users: [].concat(formValues.users).map(u => ({
        userId: u.userId,
        minWeeklyHours: u.minWeeklyHours,
        maxWeeklyHours: u.maxWeeklyHours,
      })),
    };
    try {
      const result = await this.props.createShortWork(data);
      const id = _.get(result, 'data.createShortWork.id');
      this.props.history.push({ pathname: `${id}/edit` });
    } catch (e) {
      const errors = getErrorsFromApollo(e);
      this.props.dispatch(reset('shortWork-edit'));
      this.setState({
        errors: this.state.errors.concat(
          errors.map(err =>
            this.props.intl.formatMessage(
              errorMessages[err.message],
              err.messageValues,
            ),
          ),
        ),
      });
    }
  }

  render() {
    const { usersQuery: { loading, users } } = this.props;
    if (loading) return <Loading />;
    const initialValues = {
      active: true,
      start: moment()
        .startOf('month')
        .format(DATEONLY_STRING_FMT),
      end: moment()
        .add(2, 'months')
        .endOf('month')
        .format(DATEONLY_STRING_FMT),
    };
    return (
      <div>
        <h2>
          <FormattedMessage {...messages.addHeading} />
        </h2>
        <EditForm
          shortWork={emptyShortWork}
          onSubmit={this.handleSubmit}
          errors={this.state.errors}
          initialValues={initialValues}
          users={users}
        />
      </div>
    );
  }
}

const createShortWorkMutation = gql`
  mutation createShortWorkMutation($shortWork: ShortWorkInput!) {
    createShortWork(shortWork: $shortWork) {
      id
      name
      active
      start
      end
      users {
        id
        username
        ShortWorkUser {
          id
          minWeeklyHours
          maxWeeklyHours
        }
      }
    }
  }
`;

export default compose(
  injectIntl,
  graphql(usersQuery, {
    name: 'usersQuery',
  }),
  graphql(createShortWorkMutation, {
    props: ({ mutate }) => ({
      createShortWork: shortWork =>
        mutate({
          variables: { shortWork },
          update: (proxy, { data: { createShortWork: newShortWork } }) => {
            if (newShortWork) {
              const data = proxy.readQuery({ query: shortWorkListQuery });
              data.shortWorkList.push({
                id: newShortWork.id,
                name: newShortWork.name,
                active: newShortWork.active,
                start: newShortWork.start,
                end: newShortWork.end,
                users: newShortWork.users,
                __typename: 'ShortWork',
              });
              data.shortWorkList = data.shortWorkList.sort(
                (a, b) => (a.name < b.name ? -1 : 1),
              );
              proxy.writeQuery({ query: shortWorkListQuery, data });
            }
          },
        }),
    }),
  }),
  connect(),
)(ShortWorkNew);
