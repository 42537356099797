import CustomError from './CustomError';

class ValidationError extends CustomError {
  constructor(message, messageValues) {
    super(message, messageValues);
    this.name = 'ValidationError';
  }
}

export default ValidationError;
