import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment';
import qs from 'qs';

import s from './Filter.scss';
import messages from './messages';
import FilterForm from './FilterForm';
import { dateOnlyToString, stringToDateOnly } from '../../../core/dateonly';

const handleExport = (formValues, format) => {
  const filterString = qs.stringify({
    filter: formValues,
  });
  const exportTab = window.open('', '_blank');
  const shortWorkId = formValues.shortWork;
  const exportUrl = `/export/shortWork/${shortWorkId}?${filterString}&format=${format}`;
  exportTab.location.href = exportUrl;
  exportTab.focus();
};

class Filter extends React.Component {
  static propTypes = {
    onFilterApply: PropTypes.func.isRequired,
    appliedValues: PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
    }),
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
    onExport: PropTypes.func,
    onApplyCompensation: PropTypes.func.isRequired,
    shortWorkList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
      }),
    ),
  };
  static defaultProps = {
    appliedValues: {},
    projects: [],
    onExport: null,
    shortWorkList: [],
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleSubmit(formValues) {
    const filter = { ...formValues };
    // shortWork
    if (formValues.shortWork) {
      filter.shortWork = formValues.shortWork.value;
    }
    // from, to
    if (filter.from) filter.from = stringToDateOnly(filter.from);
    if (filter.to) filter.to = stringToDateOnly(filter.to);

    this.props.onFilterApply({ filter });
  }

  handleReset() {
    this.props.onFilterApply({
      filter: {
        from: moment()
          .startOf('month')
          .format('DD.MM.YYYY'),
        to: moment()
          .endOf('month')
          .format('DD.MM.YYYY'),
      },
    });
  }

  render() {
    const { appliedValues, shortWorkList, onApplyCompensation } = this.props;
    const filterShortWork = shortWorkList.find(
      entry => entry.id === appliedValues.shortWork,
    );
    return (
      <div className={s.filter}>
        <h2>
          <FormattedMessage {...messages.heading} />
        </h2>
        <FilterForm
          initialValues={{
            ...appliedValues,
            from: dateOnlyToString(appliedValues.from),
            to: dateOnlyToString(appliedValues.to),
            shortWork: filterShortWork
              ? {
                  value: filterShortWork.id,
                  label: `${filterShortWork.name}: ${dateOnlyToString(
                    filterShortWork.start,
                  )} - ${dateOnlyToString(filterShortWork.end)}`,
                }
              : null,
          }}
          onSubmit={this.handleSubmit}
          onReset={this.handleReset}
          shortWorkList={shortWorkList}
          onExport={format => handleExport(appliedValues, format)}
          onApplyCompensation={onApplyCompensation}
        />
      </div>
    );
  }
}

export default withStyles(s)(Filter);
