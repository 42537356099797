/* eslint-disable import/prefer-default-export */

import { SET_SESSION_EXPIRED, RESET_SESSION_EXPIRED } from '../constants';

export function setSessionExpired() {
  return {
    type: SET_SESSION_EXPIRED,
  };
}

export function resetSessionExpired() {
  return {
    type: RESET_SESSION_EXPIRED,
  };
}
