import errorMessages from '../messages';
import * as errorTypes from '../../errors';

// format message id to message key
export const formatApolloError = errorMessageId => {
  const messageSplit = errorMessageId.split(':');
  const messageId = messageSplit[messageSplit.length - 1].trim();
  const messageKey = Object.keys(errorMessages).find(
    key => errorMessages[key].id === messageId,
  );
  return messageKey;
};

// create new Error object from error message(in JSON format)
export const parseError = errorJson => {
  try {
    const data = JSON.parse(errorJson);
    const Cls = errorTypes[data.class];
    const error = new Cls();
    data.message = formatApolloError(data.message);
    error.update(data);
    return error;
  } catch (e) {
    return null;
  }
};

// combine network and graphql errors to one list
export const getErrorsFromApollo = e => {
  const errors = e.graphQLErrors;
  if (e.networkError)
    errors.push(new Error(formatApolloError(e.networkError.message)));
  return errors;
};
