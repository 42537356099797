import { SET_LICENSE_STATE } from '../constants';

const initialState = {
  valid: true,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case SET_LICENSE_STATE: {
      return {
        ...state,
        valid: action.payload.value,
      };
    }
    default:
      return state;
  }
}
