import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  filteredHours: {
    id: 'filter.filteredHours',
    defaultMessage: 'Filtered Hours',
    description: 'Title for filtered hours in user edit',
  },
});

class TotalFilteredHours extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    hoursEntries: PropTypes.array.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    filter: PropTypes.object.isRequired,
  };

  render() {
    const { hoursEntries, filter } = this.props;
    if (Object.keys(filter).length > 0) {
      const filteredHours = hoursEntries.reduce(
        (total, entry) => total + parseFloat(entry.hours, 10),
        0,
      );
      return (
        <div
          style={{
            borderBottom: '1px solid rgba(155, 155, 155, 0.2)',
            paddingBottom: '15px',
          }}
        >
          <span
            style={{
              fontWeight: 'bold',
              paddingLeft: '8px',
            }}
          >
            <FormattedMessage {...messages.filteredHours} />:
          </span>
          <span> {`${filteredHours}`}</span>
        </div>
      );
    }

    return <div />;
  }
}

export default TotalFilteredHours;
