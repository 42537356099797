exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._16u9R{width:100%}._16u9R ._3llns{height:44px;width:100%;margin-bottom:10px;padding:0;border:none;border-bottom:3px solid #00dcb2;border-right:3px solid #00dcb2;background-color:rgba(0,220,178,.5);outline:none!important}._16u9R ._3llns .j_gtp{flex-direction:row;justify-content:flex-start;width:calc(100% - 50px);padding:0 0 0 15px;text-align:left}._16u9R ._3llns ._2U-BO,._16u9R ._3llns .j_gtp{display:inline-flex;align-items:center;height:100%;vertical-align:top}._16u9R ._3llns ._2U-BO{justify-content:center;width:50px;font-size:16px}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"collapsible": "_16u9R",
	"collapsibleHeader": "_3llns",
	"headerContent": "j_gtp",
	"arrowIconContainer": "_2U-BO"
};