import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reset } from 'redux-form';

import messages from './messages';
import EditForm from './EditForm';
import { projectsQuery } from './Table';
import { getErrorsFromApollo } from '../../core/errors/util';
import errorMessages from '../../core/errors/messages';

const emptyProject = {};

class ProjectNew extends React.Component {
  static propTypes = {
    createProject: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(formValues) {
    await this.setState({ errors: [] });
    const payloadWithTypenames = { ...formValues };
    let categories = [];
    if (payloadWithTypenames.categories) {
      categories = payloadWithTypenames.categories.map(category => {
        const categoryWithoutTypeName = _.omit(category, [
          '__typename',
          'isLinked',
        ]);
        return { ...categoryWithoutTypeName };
      });
    }
    const payload = _.omit(payloadWithTypenames, [
      '__typename',
      'categories',
      'users',
    ]);

    const payloadWithCategories = { ...payload, categories };
    try {
      const result = await this.props.createProject(payloadWithCategories);
      const id = _.get(result, 'data.createProject.id');
      this.props.history.push({ pathname: `${id}/edit` });
    } catch (e) {
      const errors = getErrorsFromApollo(e);
      this.props.dispatch(reset('editForm'));
      this.setState({
        errors: this.state.errors.concat(
          errors.map(err =>
            this.props.intl.formatMessage(
              errorMessages[err.message],
              err.messageValues,
            ),
          ),
        ),
      });
    }
  }

  render() {
    return (
      <div>
        <h2>
          <FormattedMessage {...messages.addHeading} />
        </h2>
        <EditForm
          project={emptyProject}
          onSubmit={this.handleSubmit}
          errors={this.state.errors}
        />
      </div>
    );
  }
}

const createProjectMutation = gql`
  mutation createProjectMutation($project: ProjectInput!) {
    createProject(project: $project) {
      id
      name
      mandatoryIssue
      mandatoryCategory
      type
      repositoryUrl
      visibility
      isLinked
      categories {
        id
        name
        description
        repositoryUrl
        active
      }
    }
  }
`;

export default compose(
  injectIntl,
  graphql(createProjectMutation, {
    props: ({ mutate }) => ({
      createProject: project =>
        mutate({
          variables: { project },
          update: (proxy, { data: { createProject: newProject } }) => {
            if (newProject) {
              const data = proxy.readQuery({ query: projectsQuery });
              data.projects.push({
                id: newProject.id,
                name: newProject.name,
                visibility: newProject.visibility,
                isLinked: newProject.isLinked,
                __typename: 'Project',
              });
              data.projects = data.projects.sort(
                (a, b) => (a.name < b.name ? -1 : 1),
              );
              proxy.writeQuery({ query: projectsQuery, data });
            }
          },
        }),
    }),
  }),
  connect(),
)(ProjectNew);
