import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import buttonStyle from '../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import s from './CreateUserForm.scss';
import render from '../ReduxForm/renderField';
import validations from '../ReduxForm/validations';
import validationMessages from '../ReduxForm/messages';
import messages, { createUserFormMessages } from './messages';

const validate = (values, props) => {
  const errors = {};
  if (!validations.required(values.username)) {
    errors.username = <FormattedMessage {...validationMessages.required} />;
  }
  if (props.users.find(u => u.username === values.username)) {
    errors.username = (
      <FormattedMessage {...createUserFormMessages.userAlreadyExists} />
    );
  }
  if (!validations.required(values.email)) {
    errors.email = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.email(values.email)) {
    errors.email = <FormattedMessage {...validationMessages.email} />;
  }
  return errors;
};

class CreateUserFrom extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
    // eslint-disable-next-line react/require-default-props
    users: PropTypes.arrayOf(
      PropTypes.shape({
        username: PropTypes.string.isRequired,
      }),
    ),
  };

  static defaultProps = {
    errors: [],
    users: [],
  };

  render() {
    const { intl, handleSubmit, errors } = this.props;
    return (
      <div className={s.createUserForm}>
        <h1>
          <FormattedMessage {...createUserFormMessages.heading} />
        </h1>
        <form className="default-form" onSubmit={handleSubmit}>
          <fieldset>
            <Field
              id="username"
              name="username"
              type="text"
              label={intl.formatMessage(messages.username)}
              component={render.renderInput}
            />
            <Field
              id="email"
              name="email"
              type="text"
              label={intl.formatMessage(createUserFormMessages.email)}
              component={render.renderInput}
            />
            {errors.length === 0 ? null : (
              <ul style={{ padding: '0', listStyleType: 'none' }}>
                {errors.map(err => (
                  <li key={err} className="bg-danger">
                    {err}
                  </li>
                ))}
              </ul>
            )}
            <button
              type="submit"
              className={`${buttonStyle['btn-primary']} ${s.button}`}
            >
              <FormattedMessage {...createUserFormMessages.submit} />
            </button>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default withStyles(s)(
  reduxForm({
    form: 'create-user',
    validate,
    pure: false, // this is necessary to trigger form re-renders if the locale changes
  })(injectIntl(CreateUserFrom)),
);
