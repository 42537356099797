/* 
 * Hack: Remove every usage of this function once react-select supports focus and scroll when menu is opened again
 * Issue: https://github.com/JedWatson/react-select/issues/3648
 */

let lockField = false; // hacky solution to allow key inputs

// eslint-disable-next-line import/prefer-default-export
export const createHackySelectFieldHandlers = (selectRef, options) => ({
  ref: selectRef,
  onFocus: () => {
    selectRef.current.focus(); // mega hack: delay focus for selection hack to work
  },
  onInputChange: () => {
    lockField = true;
  },
  onMenuClose: () => {
    lockField = false;
  },
  onMenuOpen: () => {
    if (lockField) {
      lockField = false;
      return;
    }

    // hack: delay state update to override previous state update done by react-select when menu was openend
    setTimeout(() => {
      // eslint-disable-next-line no-param-reassign
      selectRef.current.select.scrollToFocusedOptionOnUpdate = true;
      selectRef.current.select.setState({
        oldInput: selectRef.current.select.inputRef.value,
        focusedOption: options.find(option => {
          let selectedOption = {};
          if (
            selectRef.current &&
            selectRef.current.select &&
            selectRef.current.select.state &&
            selectRef.current.select.state.selectValue &&
            selectRef.current.select.state.selectValue.length > 0
          ) {
            selectedOption = selectRef.current.select.state.selectValue[0];
          }
          return option.value === selectedOption.value;
        }),
      });
    }, 0);
  },
});
