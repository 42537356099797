import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import render from 'components/ReduxForm/renderField';
import validations from 'components/ReduxForm/validations';
import formMessages from 'components/ReduxForm/messages';
import monthMessages from 'components/Month/messages';

const validate = values => {
  const errors = {};
  if (!validations.required(values.weeklyHours)) {
    errors.weeklyHours = <FormattedMessage {...formMessages.required} />;
  }

  return errors;
};

class EditUserMonthsForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
  };

  static renderMonth(name, index, fields) {
    const month = fields.get(index);
    return (
      <div>
        <strong>{month.name}</strong>
        <Field
          name={`${name}.weeklyHours`}
          label={<FormattedMessage {...monthMessages.weeklyHours} />}
          type="number"
          component={render.renderInput}
        />
      </div>
    );
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <fieldset>
          <FieldArray
            name="months"
            component={render.renderGenericFieldArray}
            renderField={EditUserMonthsForm.renderMonth}
            withAdd={false}
            withRemove={false}
            singleRows
          />
          <button
            type="submit"
            className="btn btn-primary"
            disabled={this.props.submitting}
          >
            <FormattedMessage {...formMessages.submit} />
          </button>
        </fieldset>
      </form>
    );
  }
}

export default reduxForm({
  form: 'editUserMonthsForm',
  validate,
})(injectIntl(EditUserMonthsForm));
