import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';

import { injectIntl, intlShape } from 'react-intl';

import messages from './messages';
import variables from '../../../styles/base/variables.scss';
import s from './Controlling.scss';
import Collapsible from '../../Collapsible';
import { DATEONLY_STRING_FMT_DB } from '../../../core/dateonly';
import { getPattern } from '../../../util';

const options = {
  tooltips: {
    enabled: false,
  },
  plugins: {
    datalabels: {
      anchor: 'start',
      display: true,
      color: 'black',
      textAlign: 'left',
      align: 'right',
      font: {
        size: 15,
        weight: 'bold',
      },
      formatter: (value, context) => `${context.dataset.label}\n${value}%`,
    },
  },
  legend: {
    display: false,
  },
  maintainAspectRatio: false,

  scales: {
    xAxes: [
      {
        stacked: true,
        angleLines: {
          display: false,
        },
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        angleLines: {
          display: false,
        },
        gridLines: {
          display: false,
        },
        barPercentage: 1.0,
        categoryPercentage: 1.0,
      },
    ],
  },
};

const workCategoryOptions = {
  ...options,
  plugins: {
    datalabels: { ...options.plugins.datalabels, display: false },
  },
  scales: {
    ...options.scales,
    xAxes: [
      {
        stacked: true,
        angleLines: { display: false },
        gridLines: { display: false },
        ticks: { display: false, max: 100 },
      },
    ],
  },
  tooltips: {
    enabled: true,
    yAlign: 'center',
    xAlign: 'center',
    callbacks: {
      label: (tooltipitem, data) => {
        const current = data.datasets[tooltipitem.datasetIndex];
        return `${current.label}: ${tooltipitem.xLabel}% (${current.hours}h)`;
      },
    },
  },
};

class RatioPanel extends React.Component {
  static generateTotalChartData({
    showWorkCategory,
    billableHours,
    nonBillableHours,
    intl,
  }) {
    const percentBillableHours = Math.round(
      100 * billableHours / (billableHours + nonBillableHours),
    );
    const percentNonBillableHours = Math.round(
      100 * nonBillableHours / (billableHours + nonBillableHours),
    );

    const backgroundColor = (color, billable) =>
      showWorkCategory
        ? {
            backgroundColor: billable
              ? getPattern('line', color, 1, true)
              : getPattern('diagonal-right-left', color, 1),
            hoverBackgroundColor: billable
              ? getPattern('line', color, 0.5, true)
              : getPattern('diagonal-right-left', color, 0.5),
          }
        : { backgroundColor: color };
    return {
      datasets: [
        {
          stack: '1',
          label: 'billable',
          data: [percentBillableHours],
          hours: billableHours,
          ...backgroundColor(variables.teal, true),
          datalabels: {
            formatter(value) {
              // eslint-disable-next-line css-modules/no-undef-class
              if (!value) return '';
              if (value > 25)
                return `${intl.formatMessage(
                  messages.billable,
                )}\n${value}% (${billableHours.toFixed(2)}h)`;
              return `${intl.formatMessage(
                messages.abrBillable,
              )}\n${value}%\n(${billableHours.toFixed(2)}h)`;
            },
          },
        },
        {
          stack: '1',
          label: 'nonbillable',
          data: [percentNonBillableHours],
          hours: nonBillableHours,
          ...backgroundColor(variables.grey),
          datalabels: {
            formatter(value) {
              // eslint-disable-next-line css-modules/no-undef-class
              if (!value) return '';
              if (value > 25)
                return `${intl.formatMessage(
                  messages.nonBillable,
                )}\n${value}% (${nonBillableHours.toFixed(2)}h)`;
              return `${intl.formatMessage(
                messages.abrNonBillable,
              )}\n${value}%\n(${nonBillableHours.toFixed(2)}h)`;
              // eslint-disable-next-line prettier/prettier
            },
          },
        },
      ],
    };
  }
  static generateWorkCategoryChartData(projects) {
    const sumHours = projects.reduce((all, current) => all + current.hours, 0);
    const projectsToChart = [...projects];
    projectsToChart.sort((p1, p2) => p2.hours - p1.hours);
    // clone options to only overwrite the display option

    const data = {
      datasets: projectsToChart.map((project, index) => {
        const percent = (100 * project.hours / sumHours).toFixed(1);
        const backgroundColor = alpha =>
          project.workCategory
            ? project.billable
              ? getPattern('line', project.workCategory.color, alpha, true)
              : getPattern(
                  'diagonal-right-left',
                  project.workCategory.color,
                  alpha,
                )
            : project.billable
              ? getPattern('line', variables.teal, alpha, true)
              : getPattern('diagonal-right-left', variables.grey, alpha);
        return {
          hours: project.hours,
          label: project.workCategory
            ? `${project.workCategory.name}`
            : project.name,
          stack: project.name,
          index,
          data: [percent],
          backgroundColor: backgroundColor(1),
          hoverBackgroundColor: backgroundColor(0.5),
        };
      }),
    };

    const groupedProjects = data.datasets.reduce((all, current) => {
      if (!all[current.stack]) all[current.stack] = [current];
      else all[current.stack].push(current);
      return all;
    }, {});

    return Object.keys(groupedProjects).map(project => {
      const projectHours = groupedProjects[project].reduce(
        (all, current) => all + current.hours,
        0,
      );
      const percentage = Math.round(100 * projectHours / sumHours);
      return (
        <>
          <h4>{`${project} ${percentage}% (${projectHours}h)`}</h4>
          <div className={s.workCategoryCharts}>
            <HorizontalBar
              data={{ datasets: [...groupedProjects[project]] }}
              options={workCategoryOptions}
              plugins={[ChartDataLabels]}
            />
          </div>
        </>
      );
    });
  }

  static generateProjectChart(projects) {
    const sumHours = projects.reduce((all, current) => all + current.hours, 0);
    const projectsToChart = [...projects];
    projectsToChart.sort((p1, p2) => p2.hours - p1.hours);
    const data = {
      datasets: projectsToChart.map((project, index) => {
        const percent = Math.round(100 * project.hours / sumHours);
        return {
          stack: index,
          label: project.name,
          data: [percent],
          // eslint-disable-next-line css-modules/no-undef-class
          backgroundColor: project.billable ? variables.teal : variables.grey,
          datalabels: {
            formatter(value) {
              // eslint-disable-next-line prettier/prettier
              return `${project.name}\n${value}% (${project.hours.toFixed(
                2,
              )}h)`;
            },
          },
        };
      }),
    };
    return (
      <div style={{ height: projectsToChart.length * 60, width: '400px' }}>
        <HorizontalBar
          data={data}
          options={options}
          plugins={[ChartDataLabels]}
        />
      </div>
    );
  }

  static propTypes = {
    totalData: PropTypes.shape({
      billableHours: PropTypes.number.isRequired,
      nonBillableHours: PropTypes.number.isRequired,
    }).isRequired,
    projectData: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        hours: PropTypes.number,
        billable: PropTypes.bool,
      }),
    ).isRequired,
    title: PropTypes.string,
    initialShow: PropTypes.bool,
    shortWorkSettings: PropTypes.shape({
      maxWeeklyHours: PropTypes.number.isRequired,
      minWeeklyHours: PropTypes.number.isRequired,
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired,
    }),
    showWorkCategory: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    initialShow: false,
    title: '',
    shortWorkSettings: null,
  };

  render() {
    const {
      totalData,
      projectData,
      title,
      initialShow,
      shortWorkSettings,
      intl,
      showWorkCategory,
    } = this.props;
    const totalHours = (
      totalData.billableHours + totalData.nonBillableHours
    ).toFixed(2);
    let headerClassName = s.ratioHeader;
    if (shortWorkSettings) {
      const weeks = moment(shortWorkSettings.end, DATEONLY_STRING_FMT_DB).diff(
        moment(shortWorkSettings.start, DATEONLY_STRING_FMT_DB),
        'weeks',
        true,
      );
      const hoursPerWeek = totalHours / weeks;
      if (hoursPerWeek < shortWorkSettings.minWeeklyHours) {
        headerClassName = `${headerClassName} ${s.underMinimum}`;
      } else if (hoursPerWeek > shortWorkSettings.maxWeeklyHours) {
        headerClassName = `${headerClassName} ${s.overMaximum}`;
      }
    }

    return (
      <div className={s.totalChart}>
        <Collapsible
          initialOpen={initialShow}
          contentClassName={s.ratioContent}
          headerClassName={headerClassName}
          headerContent={
            <h3>
              {title} (
              {totalHours}h)
            </h3>
          }
        >
          {showWorkCategory && <h4>Total</h4>}
          <div
            className={showWorkCategory ? s.workCategoryCharts : s.chartWrapper}
          >
            <HorizontalBar
              data={RatioPanel.generateTotalChartData({
                showWorkCategory,
                ...totalData,
                intl,
              })}
              options={showWorkCategory ? workCategoryOptions : options}
              plugins={[ChartDataLabels]}
            />
          </div>
          {projectData &&
            (showWorkCategory
              ? RatioPanel.generateWorkCategoryChartData(projectData)
              : RatioPanel.generateProjectChart(projectData))}
        </Collapsible>
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(RatioPanel));
