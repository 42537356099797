import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FaPlus, FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import { Row, Col, ToggleButton } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Loading from 'components/Loading';
import tableMessages from 'components/Table/messages';
import Table from 'components/Table';
import { VISIBILITY_PUBLIC } from '../../constants';
import messages from './messages';
import layoutStyle from '../../styles/base/layout.scss';

class ProjectTable extends React.Component {
  static propTypes = {
    projectsQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      projects: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          visibility: PropTypes.string.isRequired,
          isLinked: PropTypes.bool.isRequired,
        }),
      ),
    }).isRequired,
    updateActiveProject: PropTypes.func.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    deleteProject: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.createColumns = this.createColumns.bind(this);
    this.handleDeleteProject = this.handleDeleteProject.bind(this);
    this.createToggleButton = this.createToggleButton.bind(this);
  }

  handleDeleteProject(id) {
    this.props.deleteProject(id);
  }

  createColumns() {
    const { match: { path } } = this.props;

    const columns = [
      {
        Header: <FormattedMessage {...messages.name} />,
        accessor: 'name',
        width: 350,
        Cell: ({ original }) => <span>{original.name}</span>,
        filterMethod: (filter, row) => {
          const filterString = filter.value.toLowerCase();
          const name = row._original.name.toLowerCase(); // eslint-disable-line no-underscore-dangle

          return name.indexOf(filterString) !== -1;
        },
      },
      {
        Header: <FormattedMessage {...messages.categories} />,
        accessor: 'categories',
        filterable: true,
        width: 350,
        Cell: ({ original }) => <span>{original.categories}</span>,
        filterMethod: (filter, row) => {
          const filterString = filter.value.toLowerCase();
          const categories = row._original.categories.toLowerCase(); // eslint-disable-line no-underscore-dangle

          return categories.indexOf(filterString) !== -1;
        },
      },
      {
        Header: <FormattedMessage {...messages.visibility} />,
        accessor: 'visibility',
        filterable: true,
        width: 200,
        Cell: ({ original }) => <span>{original.visibility}</span>,
        filterMethod: (filter, row) => {
          const filterString = filter.value.toLowerCase();
          const visibility = row._original.visibility.toLowerCase(); // eslint-disable-line no-underscore-dangle

          return visibility.indexOf(filterString) !== -1;
        },
      },
      {
        Header: <FormattedMessage {...tableMessages.actions} />,
        width: 510,
        Cell: ({ original }) => (
          <Row>
            <Col xs={1}>
              <Link to={`${path}${original.id}/view`}>
                <FaEye />
              </Link>
            </Col>
            <Col xs={1}>
              <Link to={`${path}${original.id}/edit`}>
                <FaEdit />
              </Link>
            </Col>
            {!original.isLinked &&
              original.visibility === VISIBILITY_PUBLIC && (
                <Col xs={1}>
                  <button
                    type="button"
                    onClick={() => this.handleDeleteProject(original.id)}
                    className="btn-small"
                  >
                    <FaTrash />
                  </button>
                </Col>
              )}
          </Row>
        ),
        Filter: () => null,
      },
      {
        Header: <FormattedMessage {...messages.active} />,
        width: 510,
        Cell: ({ original }) => this.createToggleButton(original),
      },
    ];

    return columns;
  }

  async invertActive(args) {
    const userActiveInput = {
      id: args.id,
      active: !args.active,
    };
    await this.props.updateActiveProject(userActiveInput);
  }

  createToggleButton(args) {
    const message = args.active ? tableMessages.active : tableMessages.inactive;
    return (
      <ToggleButton
        className="tgl-btn"
        checked={args.active}
        type="checkbox"
        onChange={() => this.invertActive(args)}
        style={{ width: '125px' }}
      >
        {<FormattedMessage {...message} />}
      </ToggleButton>
    );
  }

  render() {
    const {
      projectsQuery: { loading, projects },
      match: { path },
    } = this.props;
    if (loading) return <Loading />;

    // replace cateogries construct with comma seperated category string
    const projectData = projects.map(project => ({
      ...project,
      categories: project.categories.map(category => category.name).join(', '),
    }));

    const columns = this.createColumns();
    return (
      <div>
        <Row className={layoutStyle.noMargin} style={{ marginBottom: '15px' }}>
          <Col xs={12} className={layoutStyle.noPadding}>
            <Link to={`${path}new`} className="btn btn-primary">
              <FaPlus className="icon--prepended icon-button" />
              <FormattedMessage {...messages.addProject} />
            </Link>
          </Col>
        </Row>
        <Row className={layoutStyle.noMargin}>
          <Col xs={12} className={layoutStyle.noPadding}>
            <Table
              keyField="name"
              data={projectData}
              columns={columns}
              filterable
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export const projectsQuery = gql`
  query projects {
    projects(showInactive: true) {
      id
      name
      visibility
      active
      isLinked
      categories {
        id
        name
      }
    }
  }
`;

const deleteProjectMutation = gql`
  mutation deleteProject($id: String!) {
    deleteProject(id: $id)
  }
`;

const updateActiveMutation = gql`
  mutation updateProject($project: ProjectActiveInput!) {
    updateActiveProject(project: $project) {
      id
      active
    }
  }
`;

export default compose(
  graphql(projectsQuery, {
    name: 'projectsQuery',
  }),
  graphql(deleteProjectMutation, {
    props: ({ mutate }) => ({
      deleteProject: projectId =>
        mutate({
          variables: { id: projectId },
          refetchQueries: ['projectsQuery'],
          update: (proxy, { data: { deleteProject: deletedId } }) => {
            if (deletedId) {
              const data = proxy.readQuery({ query: projectsQuery });
              const projects = data.projects.filter(
                ({ id }) => id !== deletedId,
              );
              proxy.writeQuery({ query: projectsQuery, data: { projects } });
            }
          },
        }),
    }),
  }),
  graphql(updateActiveMutation, {
    props: ({ mutate }) => ({
      updateActiveProject: project =>
        mutate({
          variables: { project },
        }),
    }),
  }),
)(withStyles(layoutStyle)(ProjectTable));
