import { defineMessages } from 'react-intl';

const messages = defineMessages({
  name: {
    id: 'shortWork.name',
    defaultMessage: 'Name',
    description: 'Label for name in shortWork',
  },
  editHeading: {
    id: 'shortWork.edit.heading',
    defaultMessage: 'Edit',
    description: 'Heading for Edit',
  },
  addHeading: {
    id: 'shortWork.add.heading',
    defaultMessage: 'Add ShortWork',
    description: 'Heading for Add',
  },
  addShortWork: {
    id: 'shortWork.addShortWork',
    defaultMessage: 'ShortWork',
    description: 'Label for Add ShortWork button',
  },
  active: {
    id: 'shortWork.active',
    defaultMessage: 'active',
    description: 'Label for active flag',
  },
  start: {
    id: 'shortWork.start',
    defaultMessage: 'Start',
    description: 'Label for start column',
  },
  end: {
    id: 'shortWork.end',
    defaultMessage: 'End',
    description: 'Label for end column',
  },
  userCount: {
    id: 'shortWork.userCount',
    defaultMessage: 'Users',
    description: 'Label for users column',
  },
  duplicateUserId: {
    id: 'shortWork.duplicateUserId',
    defaultMessage: 'Duplicate user selection',
    description: 'Error message for duplicate user selection',
  },
  maxWeeklyHoursError: {
    id: 'shortWork.maxWeeklyHoursError',
    defaultMessage:
      'maxWeeklyHours has to be greater or equal to minWeeklyHours',
    description: 'Error message for invalid maxWeeklyHours entry',
  },
});

export default messages;
