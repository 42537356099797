/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { Row, Col, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { withRouter } from 'react-router';
import moment from 'moment';

import Loading from 'components/Loading';
import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import meQuery from 'components/Auth/queries/meQuery';

import s from './Dashboard.scss';
import layoutStyle from '../../styles/base/layout.scss';
import employeeBalanceMessages from '../EmployeeBalance/messages';
import variables from '../../styles/base/variables.scss';
import RatioPanel from '../Controlling/Ratios/RatioPanel';
import { DATEONLY_STRING_FMT_DB } from '../../core/dateonly';

const doughnutOptions = {
  plugins: {
    datalabels: {
      display: false,
    },
  },
  tooltips: {
    enabled: true,
    callbacks: {
      label: (tooltipitem, data) => {
        console.log(data);
        return `${data.labels[tooltipitem.index]}: ${
          data.datasets[tooltipitem.datasetIndex].data[tooltipitem.index]
        }h`;
      },
    },
  },
};

class Dashboard extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      me: PropTypes.shape({
        username: PropTypes.string.isRequired,
        hoursStatistics: PropTypes.shape({
          billableHours: PropTypes.number.isRequired,
          nonBillableHours: PropTypes.number.isRequired,
          projectHours: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              hours: PropTypes.number.isRequired,
              billable: PropTypes.bool.isRequired,
            }).isRequired,
          ),
        }),
        weeklyBalance: PropTypes.shape({
          done: PropTypes.number.isRequired,
          total: PropTypes.number.isRequired,
        }).isRequired,
        monthlyBalance: PropTypes.shape({
          done: PropTypes.number.isRequired,
          total: PropTypes.number.isRequired,
        }).isRequired,
      }),
    }).isRequired,
    dataWeekly: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      me: PropTypes.shape({
        hoursStatistics: PropTypes.shape({
          projectHours: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              hours: PropTypes.number.isRequired,
              billable: PropTypes.bool.isRequired,
            }).isRequired,
          ),
        }),
      }),
    }).isRequired,
    dataMonthly: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      me: PropTypes.shape({
        hoursStatistics: PropTypes.shape({
          projectHours: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              hours: PropTypes.number.isRequired,
              billable: PropTypes.bool.isRequired,
            }).isRequired,
          ),
        }),
      }),
    }).isRequired,
    intl: intlShape.isRequired,
    showWorkCategory: PropTypes.bool.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.createWorkCategoryData = this.createWorkCategoryData.bind(this);
    this.createShortWorkData = this.createShortWorkData.bind(this);
  }

  componentDidMount() {
    const redirect = window.localStorage.getItem('redirect');
    if (redirect) {
      window.localStorage.removeItem('redirect');
      this.props.history.push(redirect);
    }
  }

  async onChange(interval) {
    let filter = {};
    if (interval === 'monthly') {
      filter = {
        from: moment()
          .startOf('month')
          .format(DATEONLY_STRING_FMT_DB),
        to: moment()
          .endOf('month')
          .format(DATEONLY_STRING_FMT_DB),
        active: true,
      };
    } else {
      filter = {
        from: moment()
          .startOf('week')
          .format(DATEONLY_STRING_FMT_DB),
        to: moment()
          .endOf('week')
          .format(DATEONLY_STRING_FMT_DB),
        active: true,
      };
    }
    this.props.data.refetch({
      filter,
    });
  }
  createShortWorkData() {
    const { data: { me }, intl } = this.props;
    const balance = me.activeShortWorkBalance;
    if (!balance) return null;
    let labels;
    let balanceData;
    let shortWorkData = {};
    const labelDone = intl.formatMessage(employeeBalanceMessages.done);
    const labelRemaining = intl.formatMessage(
      employeeBalanceMessages.remaining,
    );
    const labelMin = intl.formatMessage(employeeBalanceMessages.underMinimum);
    const labelMax = intl.formatMessage(employeeBalanceMessages.overMaximum);
    if (balance.done < balance.minHours) {
      labels = [labelDone, labelMin, labelRemaining];
      balanceData = [
        balance.done.toFixed(2),
        (balance.minHours - balance.done).toFixed(2),
        (balance.maxHours - balance.minHours).toFixed(2),
      ];
      shortWorkData = {
        labels,
        datasets: [
          {
            data: balanceData,
            // eslint-disable-next-line css-modules/no-undef-class
            backgroundColor: [variables.teal, variables.orange, variables.grey],
          },
        ],
      };
    } else if (
      balance.done >= balance.minHours &&
      balance.done < balance.maxHours
    ) {
      labels = [labelDone, labelRemaining];
      balanceData = [
        balance.done.toFixed(2),
        (balance.maxHours - balance.done).toFixed(2),
      ];
      shortWorkData = {
        labels,
        datasets: [
          {
            data: balanceData,
            // eslint-disable-next-line css-modules/no-undef-class
            backgroundColor: [variables.teal, variables.grey],
          },
        ],
      };
    } else {
      labels = [labelDone, labelMax];
      balanceData = [
        balance.maxHours.toFixed(2),
        (balance.done - balance.maxHours).toFixed(2),
      ];
      shortWorkData = {
        labels,
        datasets: [
          {
            data: balanceData,
            // eslint-disable-next-line css-modules/no-undef-class
            backgroundColor: [variables.teal, variables.orange],
          },
        ],
      };
    }
    return shortWorkData;
  }

  createWorkCategoryData(data, weekly) {
    const {
      intl,
      data: {
        me: {
          weeklyBalance,
          monthlyBalance,
          weeklyNonWorkEntries,
          monthlyNonWorkEntries,
        },
      },
    } = this.props;

    const groupedWorkCategory = data.reduce((all, current) => {
      if (current.workCategory && all[current.workCategory.name]) {
        all[current.workCategory.name].hours =
          all[current.workCategory.name].hours + current.hours;
      } else if (current.workCategory) {
        all[current.workCategory.name] = {
          hours: current.hours,
          name: current.workCategory.name,
          color: current.workCategory.color,
        };
      } else if (all.rest) {
        all.rest.hours = all.rest.hours + current.hours;
      } else
        all.rest = {
          hours: current.hours,
          name: intl.formatMessage(employeeBalanceMessages.done),
          color: variables.teal,
        };
      return all;
    }, {});
    const keys = Object.keys(groupedWorkCategory);
    const nonWorkLabels = weekly
      ? weeklyNonWorkEntries.map(
          entry =>
            employeeBalanceMessages[entry.type]
              ? intl.formatMessage(employeeBalanceMessages[entry.type])
              : entry.type,
        )
      : monthlyNonWorkEntries.map(
          entry =>
            employeeBalanceMessages[entry.type]
              ? intl.formatMessage(employeeBalanceMessages[entry.type])
              : entry.type,
        );
    const nonWorkData = weekly
      ? weeklyNonWorkEntries.map(entry => entry.hours)
      : monthlyNonWorkEntries.map(entry => entry.hours);
    const nonWorkColor = weekly
      ? weeklyNonWorkEntries.map(
          entry => (variables[entry.type] ? variables[entry.type] : '#000000'),
        )
      : monthlyNonWorkEntries.map(
          entry => (variables[entry.type] ? variables[entry.type] : '#000000'),
        );

    const dataSet = {
      labels: [
        ...keys.map(key => groupedWorkCategory[key].name),
        ...nonWorkLabels,
        intl.formatMessage(employeeBalanceMessages.remaining),
      ],
      datasets: [
        {
          data: [
            ...keys.map(key => groupedWorkCategory[key].hours.toFixed(2)),
            ...nonWorkData,
            weekly
              ? Math.max(
                  0,
                  (weeklyBalance.total - weeklyBalance.done).toFixed(2),
                )
              : Math.max(
                  0,
                  (monthlyBalance.total - monthlyBalance.done).toFixed(2),
                ),
          ],
          backgroundColor: [
            ...keys.map(key => groupedWorkCategory[key].color),
            ...nonWorkColor,
            variables.grey,
          ],
        },
      ],
    };
    return dataSet;
  }

  render() {
    const {
      data: { loading, me },
      intl,
      showWorkCategory,
      dataWeekly,
      dataMonthly,
    } = this.props;
    if (
      (loading ||
        (showWorkCategory && (dataWeekly.loading || dataMonthly.loading))) &&
      (!me ||
        !me.hoursStatistics ||
        (showWorkCategory &&
          (!dataWeekly.me ||
            !dataWeekly.me.hoursStatistics ||
            !dataMonthly.me ||
            !dataMonthly.me.hoursStatistics)))
    )
      return <Loading />;
    const shortWorkData = this.createShortWorkData();
    const { hoursStatistics } = me;
    const totalHours = {
      billableHours: hoursStatistics.billableHours,
      nonBillableHours: hoursStatistics.nonBillableHours,
    };
    const weeklyBalanceData = {
      labels: [
        intl.formatMessage(employeeBalanceMessages.done),
        intl.formatMessage(employeeBalanceMessages.remaining),
      ],
      datasets: [
        {
          data: [
            me.weeklyBalance.done.toFixed(2),
            Math.max(
              0,
              (me.weeklyBalance.total - me.weeklyBalance.done).toFixed(2),
            ),
          ],
          // eslint-disable-next-line css-modules/no-undef-class
          backgroundColor: [variables.teal, variables.grey],
        },
      ],
    };

    const monthlyBalanceData = {
      labels: [
        intl.formatMessage(employeeBalanceMessages.done),
        intl.formatMessage(employeeBalanceMessages.remaining),
      ],
      datasets: [
        {
          data: [
            me.monthlyBalance.done.toFixed(2),
            Math.max(
              0,
              (me.monthlyBalance.total - me.monthlyBalance.done).toFixed(2),
            ),
          ],
          // eslint-disable-next-line css-modules/no-undef-class
          backgroundColor: [variables.teal, variables.grey],
        },
      ],
    };
    let colSpan = 6;
    if (shortWorkData) colSpan = 4;
    return [
      <Row key="chartRow" className={layoutStyle.noMargin}>
        <Col md={colSpan} xs={12} className={layoutStyle.noPadding}>
          <label htmlFor="weeklyChart">
            <FormattedMessage {...employeeBalanceMessages.weeklyBalance} />
          </label>
          <Doughnut
            name="weeklyChart"
            data={
              showWorkCategory
                ? this.createWorkCategoryData(
                    dataWeekly.me.hoursStatistics.projectHours,
                    true,
                  )
                : weeklyBalanceData
            }
            options={doughnutOptions}
          />
        </Col>
        <Col md={colSpan} xs={12} className={layoutStyle.noPadding}>
          <label htmlFor="monthlyChart">
            <FormattedMessage {...employeeBalanceMessages.monthlyBalance} />
          </label>
          <Doughnut
            name="monthlyChart"
            data={
              showWorkCategory
                ? this.createWorkCategoryData(
                    dataMonthly.me.hoursStatistics.projectHours,
                  )
                : monthlyBalanceData
            }
            options={doughnutOptions}
          />
        </Col>
        {shortWorkData && (
          <Col md={colSpan} xs={12} className={layoutStyle.noPadding}>
            <label htmlFor="shortWorkChart">
              <FormattedMessage {...employeeBalanceMessages.shortWorkBalance} />
            </label>
            <Doughnut
              name="shortWorkChart"
              data={shortWorkData}
              options={doughnutOptions}
            />
          </Col>
        )}
      </Row>,
      <Row key="statisticsRow" className={layoutStyle.noMargin}>
        <Col md={6} xs={12} className={layoutStyle.noPadding}>
          <label htmlFor="projectStatistics" style={{ display: 'block' }}>
            <FormattedMessage {...employeeBalanceMessages.projectStatistics} />
          </label>
          <ToggleButtonGroup
            name="interval"
            type="radio"
            onChange={this.onChange}
            defaultValue="monthly"
          >
            <ToggleButton value="monthly">
              <FormattedMessage {...employeeBalanceMessages.monthly} />
            </ToggleButton>
            <ToggleButton value="weekly">
              <FormattedMessage {...employeeBalanceMessages.weekly} />
            </ToggleButton>
          </ToggleButtonGroup>
          <RatioPanel
            title={me.username}
            totalData={totalHours}
            projectData={hoursStatistics.projectHours}
            initialShow
            showWorkCategory={showWorkCategory}
          />
        </Col>
      </Row>,
    ];
  }
}

export default AuthenticationWrapper(
  compose(
    graphql(meQuery, {
      name: 'data',
      options: () => ({
        variables: {
          filter: {
            from: moment()
              .startOf('month')
              .format(DATEONLY_STRING_FMT_DB),
            to: moment()
              .endOf('month')
              .format(DATEONLY_STRING_FMT_DB),
            active: true,
          },
        },
      }),
    }),
    graphql(meQuery, {
      name: 'dataWeekly',
      skip: props => !props.showWorkCategory,
      options: () => ({
        variables: {
          filter: {
            from: moment()
              .startOf('week')
              .format(DATEONLY_STRING_FMT_DB),
            to: moment()
              .endOf('week')
              .format(DATEONLY_STRING_FMT_DB),
            active: true,
          },
        },
      }),
    }),
    graphql(meQuery, {
      name: 'dataMonthly',
      skip: props => !props.showWorkCategory,
      options: () => ({
        variables: {
          filter: {
            from: moment()
              .startOf('month')
              .format(DATEONLY_STRING_FMT_DB),
            to: moment()
              .endOf('month')
              .format(DATEONLY_STRING_FMT_DB),
            active: true,
          },
        },
      }),
    }),
  )(withRouter(injectIntl(withStyles(layoutStyle, s)(Dashboard)))),
);
