import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Table from 'components/Table';
import { FaUser } from 'react-icons/fa';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment';

import Loading from 'components/Loading';
import layoutStyle from '../../styles/base/layout.scss';
import messages from './messages';
import { DATEONLY_STRING_FMT_DB } from '../../core/dateonly';

class Account extends React.Component {
  static propTypes = {
    meQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
      }),
    }).isRequired,
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.createColumns = this.createColumns.bind(this);

    this.state = {
      tabs: {
        activeKey: 'months',
      },
    };
  }

  handleTabSelect(key) {
    this.setState({ tabs: { activeKey: key } });
  }

  // eslint-disable-next-line class-methods-use-this
  createColumns() {
    const columns = [
      {
        Header: <FormattedMessage {...messages.month} />,
        width: 350,
        accessor: 'start',
        Cell: ({ original }) => <span>{original.name}</span>,
        filterMethod: (filter, row) => {
          const filterString = filter.value.toLowerCase();
          const name = row._original.name.toLowerCase(); // eslint-disable-line no-underscore-dangle

          return name.indexOf(filterString) !== -1;
        },
        sortMethod: (month1Start, month2Start) => {
          const month1StartDate = moment(month1Start, DATEONLY_STRING_FMT_DB);
          const month2StartDate = moment(month2Start, DATEONLY_STRING_FMT_DB);

          if (!month1StartDate.isValid()) return -1;
          if (!month2StartDate.isValid()) return 1;

          return Math.sign(month1StartDate.diff(month2StartDate, 'days'));
        },
      },
      {
        Header: <FormattedMessage {...messages.hours} />,
        accessor: 'weeklyHours',
        filterable: true,
        width: 200,
      },
    ];

    return columns;
  }

  render() {
    const { meQuery: { loading, me }, intl } = this.props;
    if (loading) return <Loading />;
    const columns = this.createColumns();
    return (
      <div>
        <div className="h3">
          <FaUser size={20} style={{ marginBottom: '3px' }} />
          {me.username}
        </div>
        <Tabs
          id="user-edit-tabs"
          activeKey={this.state.tabs.activeKey}
          onSelect={this.handleTabSelect}
          animation={false}
        >
          <Tab
            eventKey="months"
            title={intl.formatMessage(messages.monthsTabTitle)}
          >
            <Row className={layoutStyle.noMargin} style={{ marginTop: '15px' }}>
              <Col xs={12} className={layoutStyle.noPadding}>
                <Table
                  keyField="name"
                  data={me.months}
                  columns={columns}
                  filterable
                  defaultSorted={[
                    {
                      id: 'start',
                      desc: true,
                    },
                  ]}
                />
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const userQuery = gql`
  query meQuery {
    me {
      id
      username
      active
      months {
        id
        name
        weeklyHours
        start
      }
    }
  }
`;

export default compose(
  graphql(userQuery, {
    name: 'meQuery',
  }),
)(injectIntl(withStyles(layoutStyle)(Account)));
