import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './NotFound.scss';

class NotFound extends React.Component {
  render() {
    return (
      <div className={s.notFoundWrapper}>
        <img className={s.timeIcon} src="/icon.png" alt="" />
        <p className={s.timeNotFound}>Error 404</p>
      </div>
    );
  }
}

export default withStyles(s)(NotFound);
