import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Alert, { alertOpts } from 'components/Alert';
import TopNavigationLogin from '../AdminLayout/TopNavigationLogin';

import { messagesReset } from './messages';
import s from './ResetPassword.scss';
import ResetPasswordForm from './ResetPasswordForm';

class ResetPassword extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    resetPassword: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      alert: {
        ...alertOpts.success,
      },
    };

    this.closeAlert = this.closeAlert.bind(this);
    this.submitResetPassword = this.submitResetPassword.bind(this);
  }

  closeAlert() {
    this.setState({ alert: { show: false } });
  }

  async submitResetPassword(formValues) {
    const { resetPassword } = this.props;
    try {
      await resetPassword(formValues.username);
      this.setState({
        alert: {
          show: true,
          redirect: '/login',
          title: <FormattedMessage {...messagesReset.alertSuccessTitle} />,
          msg: <FormattedMessage {...messagesReset.alertSuccessMessage} />,
        },
      });
    } catch (e) {
      // do not show an error on failure
      if (__DEV__) {
        console.error(e.message);
      }
    }
  }

  render() {
    const { alert, redirect } = this.state;
    if (!alert.show && redirect) {
      const { history } = this.props;
      history.push({ pathname: '/login' });
    }
    return (
      <div className={`container ${s.resetPasswordContainer}`}>
        <TopNavigationLogin />
        <ResetPasswordForm
          onSubmit={this.submitResetPassword}
          errors={this.state.errors}
          initialValues={{}}
        />
        <Alert {...alert} onConfirm={this.closeAlert} />
      </div>
    );
  }
}

const resetPasswordMutation = gql`
  mutation resetPassword($username: String) {
    resetPassword(username: $username) {
      id
      username
    }
  }
`;

export default compose(
  withStyles(s),
  withRouter,
  graphql(resetPasswordMutation, {
    props: ({ mutate }) => ({
      resetPassword: username =>
        mutate({
          variables: { username },
        }),
    }),
  }),
)(injectIntl(ResetPassword));
