import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Alert, { alertOpts } from 'components/Alert';
import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';

import TopNavigationLogin from '../AdminLayout/TopNavigationLogin';
import ChangePasswordForm from './ChangePasswordForm';
import s from './ChangePassword.scss';

class ChangePassword extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    changePassword: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    me: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        token: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      redirect: null,
      alert: {
        ...alertOpts.success,
      },
    };

    this.closeAlert = this.closeAlert.bind(this);
    this.submitChangePassword = this.submitChangePassword.bind(this);
  }

  async submitChangePassword(formValues) {
    // eslint-disable-next-line prettier/prettier
    const { changePassword, me } = this.props;
    try {
      await changePassword({
        user: {
          id: me.id,
          currentPassword: formValues.currentPassword,
          newPassword: formValues.newPassword,
        },
      });

      this.setState({
        errors: [],
        redirect: '/admin/dashboard',
        alert: {
          ...alertOpts.success,
          show: true,
        },
      });
    } catch (e) {
      if (__DEV__) {
        console.error(e.message);
      }
      this.setState({
        errors: [],
        redirect: null,
        alert: {
          ...alertOpts.error,
          show: true,
        },
      });
    }
  }

  closeAlert() {
    this.setState({ alert: { show: false } });
  }

  render() {
    const { alert, redirect } = this.state;
    if (!alert.show && redirect) {
      const { history } = this.props;
      history.push({ pathname: redirect });
    }
    return (
      <div className={`container ${s.changePasswordContainer}`}>
        <TopNavigationLogin />
        <ChangePasswordForm
          onSubmit={this.submitChangePassword}
          errors={this.state.errors}
          initialValues={{}}
        />
        <Alert {...alert} onConfirm={this.closeAlert} />
      </div>
    );
  }
}

const changePasswordMutation = gql`
  mutation changePassword($user: ChangePasswordInput!) {
    changePassword(user: $user) {
      id
    }
  }
`;

export default AuthenticationWrapper(
  compose(
    withStyles(s),
    withRouter,
    graphql(changePasswordMutation, {
      props: ({ mutate }) => ({
        changePassword: data =>
          mutate({
            variables: { ...data },
          }),
      }),
    }),
  )(ChangePassword),
);
