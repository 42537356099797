import { defineMessages } from 'react-intl';

// NOTE: These messages have to be unique among the error message files

const messages = defineMessages({
  userNotFound: {
    id: 'errors.user.userNotFound',
    defaultMessage: 'User not found',
    description: 'Error message for user not found',
  },
  invalidLocalUserProvider: {
    id: 'errors.user.invalidLocalUserProvider',
    defaultMessage: 'Local user managment not supported',
    description: 'Error message if oauth provider is not local',
  },
  invalidSetPasswordToken: {
    id: 'errors.user.invalidSetPasswordToken',
    defaultMessage: 'Invalid password reset token',
    description: 'Error message if an invalid password reset token is given',
  },
});

export default messages;
