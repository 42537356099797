import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import gate from '../Auth/gate';
import NotFound from '../../components/NotFound';
import ScrollToTop from '../ScrollToTop';

class RouteBundler extends React.Component {
  static propTypes = {
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        component: PropTypes.any.isRequired,
      }).isRequired,
    ).isRequired,
    prefix: PropTypes.string,
    me: PropTypes.shape({
      id: PropTypes.string.isRequired,
      userRoles: PropTypes.array,
    }),
  };

  static defaultProps = {
    prefix: '',
    me: null,
  };

  /* eslint-disable no-nested-ternary */
  render() {
    const { routes, prefix, me } = this.props;

    return (
      <ScrollToTop>
        <Switch>
          {routes.map(
            route =>
              route.requiredRoles ? (
                me &&
                gate.isAllowed({
                  user: me,
                  allowedRoles: route.requiredRoles,
                }) ? (
                  <Route
                    key={route.path}
                    path={`${prefix}${route.path}`}
                    component={route.component}
                    exact={route.exact}
                  />
                ) : null
              ) : (
                <Route
                  key={route.path}
                  path={`${prefix}${route.path}`}
                  component={route.component}
                  exact={route.exact}
                />
              ),
          )}
          <NotFound />
        </Switch>
      </ScrollToTop>
    );
  }
  /* eslint-enable no-nested-ternary */
}

export default withRouter(RouteBundler);
