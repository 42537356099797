import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Spinner.scss';

class Spinner extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
  };
  render() {
    return this.props.show ? <div className={s.loader}>Loading ...</div> : null;
  }
}

export default withStyles(s)(Spinner);
