import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';
import { Row, Col, ToggleButton } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Loading from 'components/Loading';
import tableMessages from 'components/Table/messages';
import Table from 'components/Table';
import messages from './messages';
import layoutStyle from '../../styles/base/layout.scss';
import { dateOnlyToString } from '../../core/dateonly';
import { getErrorsFromApollo } from '../../core/errors/util';
import errorMessages from '../../core/errors/messages';

class ShortWorkTable extends React.Component {
  static propTypes = {
    shortWorkListQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      shortWorkList: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          active: PropTypes.bool.isRequired,
          start: PropTypes.string.isRequired,
          end: PropTypes.string.isRequired,
          users: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
            }),
          ),
        }),
      ),
    }).isRequired,
    updateShortWork: PropTypes.func.isRequired,
    deleteShortWork: PropTypes.func.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
    };
    this.createColumns = this.createColumns.bind(this);
    this.handleDeleteShortWork = this.handleDeleteShortWork.bind(this);
    this.createToggleButton = this.createToggleButton.bind(this);
  }

  async handleDeleteShortWork(id) {
    try {
      await this.props.deleteShortWork(id);
    } catch (e) {
      const errors = getErrorsFromApollo(e);
      this.setState({
        errors: this.state.errors.concat(
          errors.map(err =>
            this.props.intl.formatMessage(
              errorMessages[err.message],
              err.messageValues,
            ),
          ),
        ),
      });
    }
  }

  createColumns() {
    const { match: { path } } = this.props;

    const columns = [
      {
        Header: <FormattedMessage {...messages.name} />,
        accessor: 'name',
        Cell: ({ original }) => <span>{original.name}</span>,
        filterMethod: (filter, row) => {
          const filterString = filter.value.toLowerCase();
          const name = row._original.name.toLowerCase(); // eslint-disable-line no-underscore-dangle

          return name.indexOf(filterString) !== -1;
        },
      },
      {
        Header: <FormattedMessage {...messages.start} />,
        accessor: 'start',
        filterable: true,
        width: 100,
        Cell: ({ original }) => <span>{dateOnlyToString(original.start)}</span>,
        filterMethod: (filter, row) => {
          const filterString = filter.value.toLowerCase();
          const start = dateOnlyToString(row._original.start).toLowerCase(); // eslint-disable-line no-underscore-dangle

          return start.indexOf(filterString) !== -1;
        },
      },
      {
        Header: <FormattedMessage {...messages.end} />,
        accessor: 'end',
        filterable: true,
        width: 100,
        Cell: ({ original }) => <span>{dateOnlyToString(original.end)}</span>,
        filterMethod: (filter, row) => {
          const filterString = filter.value.toLowerCase();
          const end = dateOnlyToString(row._original.end).toLowerCase(); // eslint-disable-line no-underscore-dangle

          return end.indexOf(filterString) !== -1;
        },
      },
      {
        Header: <FormattedMessage {...tableMessages.actions} />,
        width: 150,
        Cell: ({ original }) => (
          <Row>
            <Col xs={1}>
              <Link to={`${path}${original.id}/edit`}>
                <FaEdit />
              </Link>
            </Col>
            {!original.active && (
              <Col xs={1}>
                <button
                  type="button"
                  onClick={() => this.handleDeleteShortWork(original.id)}
                  className="btn-small"
                >
                  <FaTrash />
                </button>
              </Col>
            )}
          </Row>
        ),
        Filter: () => null,
      },
      {
        Header: <FormattedMessage {...messages.active} />,
        width: 150,
        Cell: ({ original }) => this.createToggleButton(original),
      },
    ];

    return columns;
  }

  async invertActive(args) {
    const patch = {
      id: args.id,
      active: !args.active,
    };
    await this.props.updateShortWork(patch);
  }

  createToggleButton(args) {
    const message = args.active ? tableMessages.active : tableMessages.inactive;
    return (
      <ToggleButton
        className="tgl-btn"
        checked={args.active}
        type="checkbox"
        onChange={() => this.invertActive(args)}
        style={{ width: '125px' }}
      >
        {<FormattedMessage {...message} />}
      </ToggleButton>
    );
  }

  render() {
    const {
      shortWorkListQuery: { loading, shortWorkList },
      match: { path },
    } = this.props;
    const { errors } = this.state;
    if (loading) return <Loading />;

    // replace cateogries construct with comma seperated category string
    const shortWorkListData = shortWorkList.map(shortWork => ({
      ...shortWork,
      userCount: shortWork.users.length,
    }));

    const columns = this.createColumns();
    return (
      <div>
        <Row className={layoutStyle.noMargin} style={{ marginBottom: '15px' }}>
          <Col xs={12} className={layoutStyle.noPadding}>
            <Link to={`${path}new`} className="btn btn-primary">
              <FaPlus className="icon--prepended icon-button" />
              <FormattedMessage {...messages.addShortWork} />
            </Link>
          </Col>
        </Row>
        <Row className={layoutStyle.noMargin}>
          <Col xs={12} className={layoutStyle.noPadding}>
            <Table
              keyField="name"
              data={shortWorkListData}
              columns={columns}
              filterable
            />
          </Col>
        </Row>
        <Row
          className={layoutStyle.noMargin}
          style={{
            marginTop: '10px',
          }}
        >
          {errors.length === 0 ? null : (
            <ul
              style={{
                padding: '0',
                listStyleType: 'none',
              }}
            >
              {errors.map(err => (
                <li key={err} className="bg-danger">
                  {err}
                </li>
              ))}
            </ul>
          )}
        </Row>
      </div>
    );
  }
}

export const shortWorkListQuery = gql`
  query shortWorkList {
    shortWorkList(showInactive: true) {
      id
      name
      active
      start
      end
      users {
        id
        username
        ShortWorkUser {
          id
          minWeeklyHours
          maxWeeklyHours
        }
      }
    }
  }
`;

const deleteShortWorkMutation = gql`
  mutation deleteShortWork($id: String!) {
    deleteShortWork(id: $id)
  }
`;

const updateShortWorkMutation = gql`
  mutation updateShortWork($patch: ShortWorkInput!) {
    updateShortWork(patch: $patch) {
      id
      active
    }
  }
`;

export default compose(
  injectIntl,
  graphql(shortWorkListQuery, {
    name: 'shortWorkListQuery',
    fetchPolicy: 'network-only',
  }),
  graphql(deleteShortWorkMutation, {
    props: ({ mutate }) => ({
      deleteShortWork: shortWorkListId =>
        mutate({
          variables: { id: shortWorkListId },
          refetchQueries: ['shortWorkListQuery'],
          update: (proxy, { data: { deleteShortWork: deletedId } }) => {
            if (deletedId) {
              const data = proxy.readQuery({ query: shortWorkListQuery });
              const shortWorkList = data.shortWorkList.filter(
                ({ id }) => id !== deletedId,
              );
              proxy.writeQuery({
                query: shortWorkListQuery,
                data: { shortWorkList },
              });
            }
          },
        }),
    }),
  }),
  graphql(updateShortWorkMutation, {
    props: ({ mutate }) => ({
      updateShortWork: patch =>
        mutate({
          variables: { patch },
        }),
    }),
  }),
)(withStyles(layoutStyle)(ShortWorkTable));
