import { defineMessages } from 'react-intl';

const messages = defineMessages({
  heading: {
    id: 'login.heading',
    defaultMessage: 'Log in',
    description: 'Heading for login form',
  },
  infoLocal: {
    id: 'login.infoLocal',
    defaultMessage: `
      Log in using your credentials
    `,
    description: 'Info text for login form',
  },
  infoGitlab: {
    id: 'login.infoGitlab',
    defaultMessage: `
      Log in using your Gitlab credentials
    `,
    description: 'Info text for login form',
  },
  infoAtlassian: {
    id: 'login.infoAtlassian',
    defaultMessage: `
      Log in using your Atlassian credentials
    `,
    description: 'Info text for login form',
  },
  infoJira: {
    id: 'login.infoJira',
    defaultMessage: `
      Log in using your Jira credentials
    `,
    description: 'Info text for login form',
  },
  submitLocal: {
    id: 'login.submitLocal',
    defaultMessage: 'Log in',
    description: 'Submit button text in login form',
  },
  submitProvider: {
    id: 'login.submitGitlab',
    defaultMessage: 'Log in with {provider}',
    description: 'Submit button text in login form',
  },
  passwordReset: {
    id: 'login.passwordReset',
    defaultMessage: 'Forgot your password?',
    description: 'Password reset button text in login form',
  },
});

export default messages;
