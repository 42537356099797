import { defineMessages } from 'react-intl';

const messages = defineMessages({
  hoursListTabTitle: {
    id: 'userEdit.tabs.hoursListTabTitle',
    defaultMessage: 'Hours List',
    description: 'Title for hours list tab in user edit',
  },
  monthsTabTitle: {
    id: 'userEdit.tabs.monthsTabTitle',
    defaultMessage: 'Months',
    description: 'Title for months list tab in user edit',
  },
  userDataTabTitle: {
    id: 'userEdit.tabs.userDataTabTitle',
    defaultMessage: 'Settings',
    description: 'Title for user data tab in user edit',
  },
});

export default messages;
