exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".noMargin,body,html{margin:0}.noPadding{padding:0}", ""]);

// exports
exports.locals = {
	"noMargin": "noMargin",
	"noPadding": "noPadding"
};