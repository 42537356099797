exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._254cx>tbody>tr>td{padding-top:10px}._1J5-q{background-color:#efefef}._1J5-q,._18csa{padding-right:10px}._18csa{padding-left:10px;border-bottom:1px #fff}._18csa>div{margin:0}._18csa input{border:1px solid #b4b4b4}.LhrY6{margin-right:10px;padding:6.5px 12px}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"categoryTable": "_254cx",
	"category": "_1J5-q",
	"categoryField": "_18csa",
	"trash": "LhrY6"
};