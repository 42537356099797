exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2kYFO{width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}._2kYFO h2{display:inline-block;font-size:1.2em;margin:0}._2kYFO .T-aGF{display:inline;margin-left:10px}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"filterHeader": "_2kYFO",
	"filteredParameters": "T-aGF"
};