import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Collapse } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import s from './Collapsible.scss';

class Collapsible extends React.Component {
  static propTypes = {
    initialOpen: PropTypes.bool,
    openStateKey: PropTypes.string,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    headerContent: PropTypes.node,
    children: PropTypes.node.isRequired,
    onToggle: PropTypes.func,
  };

  static defaultProps = {
    initialOpen: false,
    headerContent: '',
    className: '',
    contentClassName: '',
    headerClassName: '',
    openStateKey: null,
    onToggle: () => {},
  };

  constructor(props) {
    super(props);

    let open = props.initialOpen;

    if (props.openStateKey !== null) {
      if (window.localStorage.getItem(props.openStateKey) === 'true') open = true; // eslint-disable-line prettier/prettier
      if (window.localStorage.getItem(props.openStateKey) === 'false') open = false; // eslint-disable-line prettier/prettier
      // do not set if null, since it would override the initialOpen prop
    }

    this.state = {
      open,
    };

    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    const open = !this.state.open;
    window.localStorage.setItem(this.props.openStateKey, open);
    this.setState({ open });
    this.props.onToggle(open);
  }

  render() {
    const {
      className,
      contentClassName,
      headerClassName,
      headerContent,
      children,
    } = this.props;
    const { open } = this.state;

    return (
      <div className={`${s.collapsible} ${className}`}>
        <button
          className={`${s.collapsibleHeader} ${headerClassName}`}
          onClick={this.handleToggle}
        >
          <div className={s.headerContent}>{headerContent}</div>
          <div className={s.arrowIconContainer}>
            {open ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        </button>
        <Collapse in={open}>
          <div className={contentClassName}>{children}</div>
        </Collapse>
      </div>
    );
  }
}

export default withStyles(s)(Collapsible);
