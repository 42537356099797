import { defineMessages } from 'react-intl';

// NOTE: These messages have to be unique among the error message files

const messages = defineMessages({
  greaterZero: {
    id: 'errors.hoursEntry.greaterZero',
    defaultMessage: 'Field Hours must be greater than zero',
    description: 'Validation error message for greater zero fields',
  },
  overlappingTimes: {
    id: 'errors.hoursEntry.overlappingTimes',
    defaultMessage: 'The entry must not overlap with another time entry',
    description: 'Validation error message for overlapping time entries',
  },
  exceedingMaxHours: {
    id: 'errors.hoursEntry.exceedingMaxHours',
    defaultMessage: `
      There cannot be any time span of more than {maxTimespanHours} hours without any break of at least {minBreakMinutes} minutes in it.
    `,
    description: 'Validation error message for overlapping time entries',
  },
  exceedingMaxBulkHours: {
    id: 'errors.hoursEntry.exceedingMaxBulkHours',
    defaultMessage: `
      Bulk additions cannot be greather than {hours} hours.
    `,
    description: 'Validation error message for overlapping time entries',
  },
  exceedingMaxNonBulkHours: {
    id: 'errors.hoursEntry.exceedingMaxNonBulkHours',
    defaultMessage: `
      Non bulk additions cannot be greather than 24 hours.
    `,
    description: 'Validation error message for overlapping time entries',
  },
  descriptionRequired: {
    id: 'errors.hoursEntry.descriptionRequired',
    defaultMessage: `
      A description is required for time entries of this project.
    `,
    description: 'Validation error message for overlapping time entries',
  },
  projectCategoryRequired: {
    id: 'errors.hoursEntry.projectCategoryRequired',
    defaultMessage: 'This project only allows entries for categories',
    description: 'Validation error message for a missing project category',
  },
  issueRequired: {
    id: 'errors.hoursEntry.issueRequired',
    defaultMessage: 'This project requires selection of an issue.',
    description: 'Validation error message for a missing issue selection',
  },
  issueProjectMismatch: {
    id: 'errors.hoursEntry.issueProjectMismatch',
    defaultMessage: 'This issue does not belong to this project!',
    description: 'Validation error message for project and issue mismatch',
  },
  noWorkCategoryFound: {
    id: 'errors.hoursEntry.noWorkCategoryFound',
    defaultMessage: 'No Work category found!',
    description: 'Validation error message for no Work category found',
  },
});

export default messages;
