import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Panel, PanelGroup } from 'react-bootstrap';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { FaChevronDown, FaChevronLeft, FaDotCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import Loading from 'components/Loading';
import navTree from './navTree';
import showNav from './navPermissions';
import s from './Sidebar.scss';

class Sidebar extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      id: PropTypes.string.isRequired,
      userRoles: PropTypes.array,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    oAuthProviderQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      currentOAuthProvider: PropTypes.string,
    }).isRequired,
    showWorkCategory: PropTypes.bool.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      activeKey: null,
    };

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(activeKey) {
    this.setState({ activeKey });
  }

  render() {
    const {
      oAuthProviderQuery: { loading, currentOAuthProvider },
      me,
      showWorkCategory,
    } = this.props;

    if (loading) return <Loading />;

    return (
      <PanelGroup
        className={s.sideNav}
        accordion
        id="sidebar-nav"
        activeKey={this.state.activeKey}
        onSelect={this.handleSelect}
      >
        {navTree(showWorkCategory).map((nav, index) => {
          if (typeof nav === 'function') {
            // eslint-disable-next-line no-param-reassign
            nav = nav(me);
          }

          const { mainNav, subNavs } = nav;

          const showMainNav = showNav(mainNav, currentOAuthProvider, me);

          if (
            !showMainNav ||
            (mainNav.oAuthProvider &&
              mainNav.oAuthProvider !== currentOAuthProvider)
          )
            return [];

          const key = `nav_${index}`;
          const Icon =
            this.state.activeKey === index ? nav.openIcon : nav.closedIcon;
          const IconChevron =
            this.state.activeKey === index ? FaChevronDown : FaChevronLeft;
          const navTitle =
            this.state.activeKey === index ||
            this.props.location.pathname === mainNav.path
              ? s.navTitleSelected
              : s.navTitle;
          return (
            <Panel className={s.navPanel} key={key} eventKey={index}>
              <Panel.Heading className={s.navHeading}>
                <Panel.Title
                  componentClass={() => (
                    <div className={navTitle}>
                      <Link to={showMainNav ? mainNav.path : '#'}>
                        <Icon className="fa--prepended" />{' '}
                        <FormattedMessage {...nav.name} />
                      </Link>
                      <Panel.Toggle>
                        {nav.subNavs.length > 1 && (
                          <IconChevron className={s.iconRight} />
                        )}
                      </Panel.Toggle>
                    </div>
                  )}
                />
              </Panel.Heading>
              {nav.subNavs.length > 1 && ( // only render sub panel if it contains more than 1 entry
                <Panel.Body className={s.navBody} collapsible>
                  {subNavs
                    .filter(subNav => showNav(subNav, currentOAuthProvider, me))
                    .map((subNav, subIndex) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div className={s.subNav} key={`subNav_${subIndex}`}>
                        <Link to={subNav.path}>
                          <FaDotCircle className="fa--prepended" />{' '}
                          <FormattedMessage {...subNav.name} />
                        </Link>
                      </div>
                    ))}
                </Panel.Body>
              )}
            </Panel>
          );
        })}
      </PanelGroup>
    );
  }
}

const oAuthProviderQuery = gql`
  query oAuthProviderQuery {
    currentOAuthProvider
  }
`;

export default compose(
  graphql(oAuthProviderQuery, {
    name: 'oAuthProviderQuery',
  }),
  AuthenticationWrapper,
  withStyles(s),
)(Sidebar);
