import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import Loading from 'components/Loading';
import NotFound from 'components/NotFound';

import IssueTable from './IssueTable';

class IssueManagementWrapper extends React.Component {
  static propTypes = {
    gitlabUsersQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      gitlabUsers: PropTypes.shape({
        id: PropTypes.number,
      }),
    }).isRequired,
  };

  render() {
    const { gitlabUsersQuery: { gitlabUsers, loading } } = this.props;

    if (loading) return <Loading />;
    if (!gitlabUsers) return <NotFound />;

    return <>{gitlabUsers.map(entry => <IssueTable gitlabUser={entry} />)}</>;
  }
}

const gitlabUsersQuery = gql`
  query GitlabUsers {
    gitlabUsers {
      id
      name
      avatar_url
    }
  }
`;

export default compose(
  graphql(gitlabUsersQuery, {
    name: 'gitlabUsersQuery',
    options: () => ({
      variables: {
        filter: {
          pageSize: 100,
          page: 1,
        },
      },
    }),
  }),
)(IssueManagementWrapper);
