import { defineMessages } from 'react-intl';

const messages = defineMessages({
  shortWorkBalance: {
    id: 'employeeBalance.shortWorkBalance',
    defaultMessage: 'Active Short Time Work Balance',
    description: 'Label Active Short Time Work Balance',
  },
  employeeBalance: {
    id: 'employeeBalance.employeeBalance',
    defaultMessage: 'Balance',
    description: 'Label employee balance',
  },
  dailyBalance: {
    id: 'employeeBalance.dailyBalance',
    defaultMessage: 'Daily Balance',
    description: 'Label for daily balance in employee balance',
  },
  weeklyBalance: {
    id: 'employeeBalance.weeklyBalance',
    defaultMessage: 'Weekly Balance',
    description: 'Label for weekly balance in employee balance',
  },
  monthlyBalance: {
    id: 'employeeBalance.monthlyBalance',
    defaultMessage: 'Monthly Balance',
    description: 'Label for monthly balance in employee balance',
  },
  weekly: {
    id: 'employeeBalance.weekly',
    defaultMessage: 'Weekly',
    description: 'Label for weekly in employee dashboard',
  },
  monthly: {
    id: 'employeeBalance.monthly',
    defaultMessage: 'Monthly',
    description: 'Label for monthly in employee dashboard',
  },
  totalBalance: {
    id: 'employeeBalance.totalBalance',
    defaultMessage: 'Total Balance Until Now',
    description: 'Label for total balance since start in employee balance',
  },
  vacationBalance: {
    id: 'employeeBalance.vacationBalance',
    defaultMessage: 'Vacation Balance',
    description: 'Label for vacation balance in eployee balance',
  },
  balance: {
    id: 'employeeBalance.balance',
    defaultMessage: 'Balance',
    description: 'Label for balance in employee balance',
  },
  total: {
    id: 'employeeBalance.total',
    defaultMessage: 'Total',
    description: 'Label for total in employee balance',
  },
  done: {
    id: 'employeeBalance.done',
    defaultMessage: 'Done',
    description: 'Label for done in employee balance',
  },
  underMinimum: {
    id: 'employeeBalance.underMinimum',
    defaultMessage: 'under Minimum',
    description: 'Label for under Minimum in employee balance',
  },
  overMaximum: {
    id: 'employeeBalance.overMaximum',
    defaultMessage: 'over Maximum',
    description: 'Label for over Maximum in employee balance',
  },
  remaining: {
    id: 'employeeBalance.remaining',
    defaultMessage: 'Remaining',
    description: 'Label for remaining in employee balance',
  },
  projectStatistics: {
    id: 'employeeBalance.projectStatistics',
    defaultMessage: 'Statistics',
    description: 'Label for ProjectStatistics',
  },
  sick: {
    id: 'employeeBalance.sick',
    defaultMessage: 'Sick',
    description: 'Label for sick',
  },
  vacation: {
    id: 'employeeBalance.vacation',
    defaultMessage: 'Vacation',
    description: 'Label for vacation',
  },
  holiday: {
    id: 'employeeBalance.holiday',
    defaultMessage: 'Holiday',
    description: 'Label for holiday',
  },
});

export default messages;
