import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';

import { resetSessionExpired } from '../../actions/user';
import Login from '../../components/Auth/login';
import loginMessages from '../../components/Auth/login/messages';

class UnauthorizedError extends React.Component {
  static propTypes = {
    requestExpired: PropTypes.bool,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    requestExpired: false,
  };

  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
    this.onSuccess = this.closeModal.bind(this);
  }

  closeModal({ redirect } = {}) {
    const { history } = this.props;
    this.props.dispatch(resetSessionExpired());
    if (redirect) history.push(redirect);
  }

  render() {
    const { requestExpired } = this.props;
    return (
      <Modal
        show={requestExpired}
        onHide={() => this.closeModal({ redirect: '/login' })}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage {...loginMessages.heading} />
          </Modal.Title>
          <Modal.Body>
            <Login successCallback={() => this.closeModal()} inModal />
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({ ...state.user });
export default injectIntl(
  withRouter(connect(mapStateToProps)(UnauthorizedError)),
);
