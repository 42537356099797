import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

import render from 'components/ReduxForm/renderField';
import validations from 'components/ReduxForm/validations';
import formMessages from 'components/ReduxForm/messages';

import userMessages from '../messages';

const validate = values => {
  const errors = {};
  if (!validations.required(values.weeklyHours)) {
    errors.weeklyHours = <FormattedMessage {...formMessages.required} />;
  }

  return errors;
};

class UserEditForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    oAuthProviderQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      currentOAuthProvider: PropTypes.string,
    }).isRequired,
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <fieldset>
          <Field
            id="username"
            name="username"
            type="text"
            label={<FormattedMessage {...userMessages.username} />}
            component={render.renderInput}
            disabled={
              this.props.oAuthProviderQuery.currentOAuthProvider !== 'local'
            }
          />
          <Field
            id="startDate"
            name="startDate"
            label={<FormattedMessage {...userMessages.startDate} />}
            component={render.renderDate}
          />
          <Field
            id="weeklyHours"
            name="weeklyHours"
            type="number"
            label={<FormattedMessage {...userMessages.weeklyHours} />}
            component={render.renderInput}
          />
          <button
            type="submit"
            className="btn btn-primary"
            disabled={this.props.submitting}
          >
            <FormattedMessage {...formMessages.submit} />
          </button>
        </fieldset>
      </form>
    );
  }
}

const oAuthProviderQuery = gql`
  query oAuthProviderQuery {
    currentOAuthProvider
  }
`;

export default compose(
  graphql(oAuthProviderQuery, {
    name: 'oAuthProviderQuery',
  }),
  reduxForm({
    form: 'userEditForm',
    validate,
  }),
)(injectIntl(UserEditForm));
