import { SET_SESSION_EXPIRED, RESET_SESSION_EXPIRED } from '../constants';

export default function user(state = {}, action) {
  switch (action.type) {
    case SET_SESSION_EXPIRED:
      return { ...state, requestExpired: true };
    case RESET_SESSION_EXPIRED:
      return { ...state, requestExpired: false };
    default:
      return state;
  }
}
