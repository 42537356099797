import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import { createHackySelectFieldHandlers } from '../ReduxForm/hackyMcHackHacks';

const selectStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  menu: styles => ({
    ...styles,
    zIndex: 10000,
  }),
  input: styles => ({ ...styles, color: 'transparent' }),
  option: (styles, { data }) => ({
    ...styles,
    paddingLeft: data.isCategory ? '25px' : null,
    fontWeight: data.isCategory ? 'normal' : 'bold',
  }),
};

/* eslint-disable react/prop-types */
const CustomSingleValue = props => (
  <components.SingleValue {...props}>
    {props.data.isCategory ? (
      <span>
        {props.data.projectName} | {props.data.label}
      </span>
    ) : (
      props.data.label
    )}
  </components.SingleValue>
);
/* eslint-enable react/prop-types */

class ProjectDropdown extends React.Component {
  static propTypes = {
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        categories: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            active: PropTypes.bool.isRequired,
          }),
        ),
      }),
    ).isRequired,
    onBlur: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    handleProjectSelect: PropTypes.func,
    input: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    disabled: false,
    handleProjectSelect: () => {},
  };

  constructor(props) {
    super(props);

    this.customFilterOption = this.customFilterOption.bind(this);

    this.selectRef = React.createRef();
  }

  customFilterOption({ label, data: { projectName } }, input) {
    const filteredLabel = label;
    const words = input.split(' ').filter(str => str.length > 0);
    return words.reduce((acc, cur) => {
      let found = false;
      const { projects } = this.props;
      if (
        filteredLabel.toLowerCase().includes(cur.toLowerCase()) ||
        (projectName && projectName.toLowerCase().includes(cur.toLowerCase()))
      ) {
        found = true;
      }
      if (!projectName) {
        const foundProjects = projects.filter(proj => {
          const cats = proj.categories.find(
            category =>
              category.name.toLowerCase().includes(cur.toLowerCase()) &&
              proj.name === label,
          );
          return cats;
        });
        found = foundProjects.length > 0 || found;
      }
      return acc && found;
    }, true);
  }

  render() {
    const { projects, input, onBlur, disabled } = this.props;
    const options = projects.reduce((all, project) => {
      all.push({
        value: project.id,
        label: project.name,
        projectProps: { ...project },
      });
      if (project.categories) {
        project.categories.forEach(category => {
          all.push({
            value: `${project.id}_${category.id}`,
            label: category.name,
            isCategory: true,
            projectName: project.name,
            projectProps: { ...project },
          });
        });
      }
      return all;
    }, []);

    return (
      <Select
        {...input}
        options={options}
        styles={selectStyles}
        handleUpdate={this.props.handleProjectSelect}
        filterOption={this.customFilterOption}
        onBlur={onBlur}
        isDisabled={disabled}
        components={
          input.components
            ? { ...input.components, SingleValue: CustomSingleValue }
            : { SingleValue: CustomSingleValue }
        }
        {...createHackySelectFieldHandlers(this.selectRef, options)}
      />
    );
  }
}

export default ProjectDropdown;
