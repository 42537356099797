exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2XUxO h1{font-size:2rem}._2XUxO fieldset{margin-top:-10px}._2XUxO ._1jIMm,._2XUxO ._1jIMm:active,._2XUxO ._1jIMm:focus{float:left;font-style:normal;padding:6px 20px;font-size:20px;font-weight:800;text-transform:uppercase;outline:none;text-decoration:none;margin:10px 0 10px 10px}@media(max-width:768px){._2XUxO ._1jIMm,._2XUxO ._1jIMm:active,._2XUxO ._1jIMm:focus{padding:10px;width:100%;left:0}}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"createUserForm": "_2XUxO",
	"button": "_1jIMm"
};