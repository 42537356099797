import gql from 'graphql-tag';
import { change } from 'redux-form';
import { getErrorsFromApollo } from '../../core/errors/util';
import { AuthenticationError } from '../../core/errors';

export const getIssuesForProject = async (
  scope,
  { projectId, search, searchIn },
) => {
  let issues = {};
  try {
    if (search == null) {
      const query = gql`
        query apiIssuesQuery($projectId: String!) {
          apiIssues(projectId: $projectId) {
            iid
            project_id
            title
          }
        }
      `;
      issues = await scope.context.client.query({
        query,
        variables: { projectId },
      });
    } else {
      const query = gql`
        query searchApiIssuesQuery(
          $projectId: String!
          $search: String
          $searchIn: String
        ) {
          searchApiIssues(
            projectId: $projectId
            search: $search
            searchIn: $searchIn
          ) {
            iid
            project_id
            title
          }
        }
      `;
      issues = await scope.context.client.query({
        query,
        variables: { projectId, search, searchIn },
      });
    }
  } catch (e) {
    const errors = getErrorsFromApollo(e);
    if (errors[0] instanceof AuthenticationError) {
      // authorization error is already handled
    } else {
      throw e;
    }
  }

  if (issues.data) {
    if (search == null) {
      issues = issues.data.apiIssues;
    } else {
      issues = issues.data.searchApiIssues;
    }
    return issues;
  }

  return [];
};

export const handleIssueInputChangeAsync = async (scope, { value }) => {
  const { projectId } = scope.state;
  if (projectId == null) return;

  const search = value;
  let issues = await getIssuesForProject(scope, {
    projectId,
    search,
    searchIn: 'title',
  });

  if (!issues || issues.length === 0) {
    issues = await getIssuesForProject(scope, { projectId });
  }

  scope.setState({ issues });
};

export const handleProjectSelect = async (
  scope,
  { value, isCategory },
  isModal = false,
) => {
  const { dispatch } = scope.props;

  // Check so issue is no removed when editmodal is called
  if (!isModal) dispatch(change('updateHoursEntryForm', 'issue', ''));

  let projectId = value;
  if (isCategory) projectId = projectId.split('_')[0];
  const issues = await getIssuesForProject(scope, { projectId });

  scope.setState({
    projectId,
    issues,
  });
};
