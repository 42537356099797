import gql from 'graphql-tag';

const meQuery = gql`
  query data($filter: HoursEntryFilter) {
    me {
      id
      username
      weeklyHours
      startDate
      roles {
        id
        name
      }
      projects {
        id
        name
        categories {
          id
          name
        }
        mandatoryCategory
        billable
      }
      hoursStatistics(filter: $filter) {
        billableHours
        nonBillableHours
        projectHours {
          name
          hours
          billable
          workCategory {
            color
            name
          }
        }
      }
      hoursEntries(filter: $filter) {
        id
        date
        timeFrom
        hours
        type
        project {
          id
          name
          mandatoryCategory
        }
        issue {
          id
          ticketId
          title
        }
        category {
          id
          name
        }
        workCategory {
          id
          name
          isActive
          isDefault
          description
          color
        }
        homeOffice
        description
        createdAt
      }
      shortWorkEntries {
        id
        name
        start
        end
        active
      }
      dailyBalance {
        total
        done
        balance
      }
      weeklyNonWorkEntries(filter: $filter) {
        hours
        type
      }
      monthlyNonWorkEntries(filter: $filter) {
        hours
        type
      }
      weeklyBalance(filter: $filter) {
        total
        done
        balance
      }
      monthlyBalance(filter: $filter) {
        total
        done
        balance
      }
      totalBalance(filter: $filter) {
        total
        done
        balance
      }
      vacationBalance(filter: $filter) {
        total
        done
        balance
      }
      activeShortWorkBalance {
        minHours
        maxHours
        done
      }
    }
  }
`;

export default meQuery;
