exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1eAX_{padding-top:15px;padding-right:15px;border-top:1px solid #efefef;min-height:35px}._1eAX_>span{padding:10px 25px;float:right;text-align:left;text-transform:uppercase}._1eAX_>span a{color:#00dcb2}._1eAX_>span:first-child{padding-right:5px}._1eAX_>span:last-child{padding:10px}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"languageSwitcher": "_1eAX_"
};