import { defineMessages } from 'react-intl';

// NOTE: These messages have to be unique among the error message files

const messages = defineMessages({
  mandatoryCategoryWithoutCategory: {
    id: 'errors.project.mandatoryCategoryWithoutCategory',
    defaultMessage:
      'Cannot make Categories mandatory without adding Categories',
    description: 'Error message for no category with mandatory category',
  },
  immutableFields: {
    id: 'errors.project.immutableFields',
    defaultMessage: 'Cannot change immutable fields.',
    description: 'Error message for immutable fields',
  },
  projectNotFound: {
    id: 'errors.project.projectNotFound',
    defaultMessage: 'Project not found.',
    description: 'Error message for Project not found',
  },
  projectIsReferenced: {
    id: 'errors.project.projectIsReferenced',
    defaultMessage: 'Project is referenced',
    description: 'Error message for Project is referenced',
  },
  projectCategoryIsReferenced: {
    id: 'errors.project.projectCategoryIsReferenced',
    defaultMessage: 'Project Category is referenced',
    description: 'Error message for Project Category is referenced',
  },
});

export default messages;
