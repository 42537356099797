import gate from 'components/Auth/gate';

const showNav = (nav, currentOAuthProvider, me) =>
  nav &&
  (!(nav.oAuthProvider && nav.oAuthProvider !== currentOAuthProvider) &&
    (!nav.roles ||
      nav.roles.length === 0 ||
      gate.isAllowed({ user: me, allowedRoles: nav.roles })) &&
    (!nav.filter || nav.filter(this.props.me)));

export default showNav;
