import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { stringToDateOnly } from '../../../core/dateonly';

import messages from './messages';
import EditForm from './EditForm';
import { managedProjectsQuery } from './Table';
import { getErrorsFromApollo } from '../../../core/errors/util';
import errorMessages from '../../../core/errors/messages';
import Loading from '../../Loading';

const emptyManagedProject = {};

class ManagedProjectNew extends React.Component {
  static propTypes = {
    createManagedProject: PropTypes.func.isRequired,
    projectsQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      projects: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          visibility: PropTypes.string.isRequired,
          isLinked: PropTypes.bool.isRequired,
        }),
      ),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(formValues) {
    const { managedProjectPositions, ...managedProject } = formValues;
    const filteredPositions = managedProjectPositions
      ? managedProjectPositions.filter(value => Object.keys(value).length !== 0)
      : [];

    if (managedProject.deadline)
      managedProject.deadline = stringToDateOnly(managedProject.deadline);

    const managedProjectPositionsPayload = filteredPositions.map(mpp => ({
      projectId: mpp.managedProjectPosition.value,
      name: mpp.managedProjectPosition.label,
      includeAllCategories: mpp.includeAllCategories,
      includeHoursBookedOnProject: mpp.includeHoursBookedOnProject,
      managedProjectPositionCategories:
        mpp.managedProjectPosition &&
        mpp.managedProjectPosition.managedProjectPositionCategories
          ? mpp.managedProjectPosition.managedProjectPositionCategories.map(
              mppc => ({
                id: mppc.value,
                name: mppc.label,
              }),
            )
          : null,
    }));

    await this.setState({ errors: [] });

    const payloadWithCategoriesAndProjects = {
      ...managedProject,
      managedProjectPositions: managedProjectPositionsPayload,
    };

    try {
      const result = await this.props.createManagedProject(
        payloadWithCategoriesAndProjects,
      );

      const id = _.get(result, 'data.createManagedProject.id');
      this.props.history.push({ pathname: `${id}/edit` });
    } catch (e) {
      const errors = getErrorsFromApollo(e);
      this.props.dispatch(reset('editForm'));
      this.setState({
        errors: this.state.errors.concat(
          errors.map(err =>
            this.props.intl.formatMessage(
              errorMessages[err.message],
              err.messageValues,
            ),
          ),
        ),
      });
    }
  }

  render() {
    if (this.props.projectsQuery.loading) return <Loading />;

    return (
      <div>
        <h2>
          <FormattedMessage {...messages.createManagedProject} />
        </h2>
        <EditForm
          managedProject={emptyManagedProject}
          projects={this.props.projectsQuery.projects}
          onSubmit={this.handleSubmit}
          errors={this.state.errors}
        />
      </div>
    );
  }
}

const createManagedProject = gql`
  mutation createManagedProject($managedProject: CreateManagedProjectInput!) {
    createManagedProject(managedProject: $managedProject) {
      id
      name
      deadline
      totalHoursOffered
      hourlyRate
      offeredPrice
      managedProjectPositions {
        id
        includeAllCategories
        includeHoursBookedOnProject
        name
        projectId
        managedProjectPositionCategories {
          id
          name
        }
      }
    }
  }
`;

export const projectsQuery = gql`
  query projects {
    projects(showInactive: true) {
      id
      name
      visibility
      active
      isLinked
      categories {
        id
        name
      }
    }
  }
`;

export default compose(
  injectIntl,
  graphql(createManagedProject, {
    props: ({ mutate }) => ({
      createManagedProject: managedProject =>
        mutate({
          variables: { managedProject },
          update: (proxy, data) => {
            const newManagedProject = data.data
              ? data.data.createManagedProject
              : null;
            if (newManagedProject) {
              const managedProjectQueryData = proxy.readQuery({
                query: managedProjectsQuery,
              });
              managedProjectQueryData.managedProjects.push({
                ...newManagedProject,
              });
              managedProjectQueryData.managedProjects = managedProjectQueryData.managedProjects.sort(
                (a, b) => (a.name < b.name ? -1 : 1),
              );
              proxy.writeQuery({
                query: managedProjectsQuery,
                data: managedProjectQueryData,
              });
            }
          },
        }),
    }),
  }),
  graphql(projectsQuery, {
    name: 'projectsQuery',
  }),
  connect(),
)(ManagedProjectNew);
