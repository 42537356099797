import { defineMessages } from 'react-intl';

const messages = defineMessages({
  monthsTabTitle: {
    id: 'account.tabs.monthsTabTitle',
    defaultMessage: 'Months',
    description: 'Title for months list tab in user edit',
  },
  month: {
    id: 'account.tabs.monthsTabTitle.month',
    defaultMessage: 'Month',
    description: 'Label for month in account months table',
  },
  hours: {
    id: 'account.tabs.monthsTabTitle.hours',
    defaultMessage: 'Hours',
    description: 'Label for hours in account months table',
  },
});

export default messages;
