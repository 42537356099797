import { defineMessages } from 'react-intl';

// NOTE: These messages have to be unique among the error message files

const messages = defineMessages({
  defaultGraphQLError: {
    id: 'errors.generic.defaultGraphQLError',
    defaultMessage: 'An internal Error occured, please try again',
    description: 'Generic graphql error message',
  },
  defaultNetworkError: {
    id: 'errors.generic.defaultNetworkError',
    defaultMessage: 'An internal Error occured, please try again',
    description: 'Generic network error message',
  },
  notAuthenticated: {
    id: 'errors.generic.notAuthenticated',
    defaultMessage: 'User is not authenticated',
    description: 'Generic not authenticated error message',
  },
});

export default messages;
