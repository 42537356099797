exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3Zoz1{position:fixed;bottom:0;left:0;width:100vw;height:25px;background-color:#fff;z-index:2}._14kUy{position:fixed;bottom:2px;right:10px;color:#00dcb2}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"footer": "_3Zoz1",
	"version": "_14kUy"
};