import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  ButtonToolbar,
  Dropdown,
  Glyphicon,
  MenuItem,
} from 'react-bootstrap';
import { Field, reduxForm, change } from 'redux-form';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import render from 'components/ReduxForm/renderField';
import layoutStyle from '../../../styles/base/layout.scss';
import { messages as filterFormMessages } from '../../Filter/FilterForm';
import { dateOnlyToString } from '../../../core/dateonly';

const messages = defineMessages({
  from: {
    id: 'controlling.from',
    defaultMessage: 'From',
    description: 'Label for From field in filter form',
  },
  to: {
    id: 'controlling.to',
    defaultMessage: 'To',
    description: 'Label for To field in filter form',
  },
  submit: {
    id: 'controlling.submit',
    defaultMessage: 'submit',
    description: 'Label for submit button in filter form',
  },
  reset: {
    id: 'controlling.reset',
    defaultMessage: 'reset',
    description: 'Label for reset button in filter form',
  },
  shortWork: {
    id: 'controlling.shortWork',
    defaultMessage: 'Short Time Work',
    description: 'Label for shortWork in filter form',
  },
});

const DropdownMenu = Dropdown.Menu;
const DropdownToggle = Dropdown.Toggle;

class FilterForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    onReset: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    shortWorkList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
      }),
    ).isRequired,
    dispatch: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    onApplyCompensation: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleDatepickerChange = this.handleDatepickerChange.bind(this);
    this.handleShortWorkChange = this.handleShortWorkChange.bind(this);
    this.shortWorkSelectRef = React.createRef();
  }

  handleShortWorkChange(e, value) {
    if (value) {
      const { shortWorkList } = this.props;
      const shortWorkEntry = shortWorkList.find(
        entry => entry.id === value.value,
      );
      if (shortWorkEntry) {
        const from = dateOnlyToString(shortWorkEntry.start);
        const to = dateOnlyToString(shortWorkEntry.end);
        this.props.dispatch(change('filterForm', 'from', from));
        this.props.dispatch(change('filterForm', 'to', to));
      } else {
        this.props.dispatch(change('filterForm', 'from', null));
        this.props.dispatch(change('filterForm', 'to', null));
      }

      this.props.dispatch(change('filterForm', 'year', null));
    }
  }

  handleDatepickerChange(e, value) {
    if (value) {
      this.props.dispatch(change('filterForm', 'year', null));
    }
  }
  render() {
    const { shortWorkList } = this.props;
    const shortWorkSelected =
      this.props.onExport &&
      this.shortWorkSelectRef.current &&
      this.shortWorkSelectRef.current.select &&
      this.shortWorkSelectRef.current.select.state &&
      this.shortWorkSelectRef.current.select.state.selectValue &&
      this.shortWorkSelectRef.current.select.state.selectValue.length > 0;
    const exportFormats = [
      {
        label: 'PDF',
        value: 'pdf',
      },
      {
        label: 'XLSX',
        value: 'xlsx',
      },
    ];
    const formats = exportFormats.map(fmt => (
      <MenuItem key={fmt.value} eventKey={fmt.value}>
        {fmt.label}
      </MenuItem>
    ));
    return (
      <form onSubmit={this.props.handleSubmit}>
        <fieldset>
          <Row className={layoutStyle.noMargin}>
            <Col xs={12} md={2} className={layoutStyle.noPadding}>
              <Field
                id="from"
                name="from"
                label={<FormattedMessage {...messages.from} />}
                component={render.renderDate}
                onChange={(e, value) => this.handleDatepickerChange(e, value)}
              />
            </Col>
            <Col xs={12} md={2} className={layoutStyle.noPadding}>
              <Field
                id="to"
                name="to"
                label={<FormattedMessage {...messages.to} />}
                component={render.renderDate}
                onChange={(e, value) => this.handleDatepickerChange(e, value)}
              />
            </Col>
            {shortWorkList.length > 0 && (
              <Col xs={12} md={5} className={layoutStyle.noPadding}>
                <Field
                  id="shortWork"
                  name="shortWork"
                  onChange={(e, value) => this.handleShortWorkChange(e, value)}
                  label={<FormattedMessage {...messages.shortWork} />}
                  component={render.renderSelectAlt}
                  options={shortWorkList.map(entry => ({
                    value: entry.id,
                    label: `${entry.name}: ${dateOnlyToString(
                      entry.start,
                    )} - ${dateOnlyToString(entry.end)}`,
                  }))}
                  selectRef={this.shortWorkSelectRef}
                />
              </Col>
            )}
          </Row>
          <ButtonToolbar>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={this.props.submitting}
            >
              <FormattedMessage {...messages.submit} />
            </button>
            <button
              type="button"
              className="btn"
              disabled={this.props.submitting}
              onClick={this.props.onReset}
            >
              <FormattedMessage {...messages.reset} />
            </button>
            {this.props.onExport &&
              shortWorkSelected && (
                <Dropdown
                  id="exportDropdown"
                  onSelect={(e, value) => this.props.onExport(e, value)}
                >
                  <DropdownToggle className="btn dropdown-toggle">
                    <Glyphicon glyph="export" />
                    <FormattedMessage {...filterFormMessages.exportFormat} />
                  </DropdownToggle>
                  <DropdownMenu>{formats}</DropdownMenu>
                </Dropdown>
              )}
            {shortWorkSelected && (
              <button
                type="button"
                className="btn"
                disabled={this.props.submitting}
                onClick={this.props.onApplyCompensation}
              >
                <FormattedMessage {...filterFormMessages.applyCompensation} />
              </button>
            )}
          </ButtonToolbar>
        </fieldset>
        <hr />
      </form>
    );
  }
}

export default reduxForm({
  form: 'filterForm',
})(connect()(injectIntl(withStyles(layoutStyle)(FilterForm))));
