import { defineMessages } from 'react-intl';

const messages = defineMessages({
  hourListHeading: {
    id: 'filter.hourListHeading',
    defaultMessage: 'Hours',
    description: 'Heading for Hourlist',
  },
  editHoursEntryModalTitle: {
    id: 'hoursList.editHoursEntryModalTitle',
    defaultMessage: 'Edit Hours Entry',
    description: 'Title for edit hours entry modal',
  },
  viewHoursEntryModalTitle: {
    id: 'hoursList.viewHoursEntryModalTitle',
    defaultMessage: 'View Hours Entry',
    description: 'Title for view hours entry modal',
  },
  project: {
    id: 'addOrUpdateHoursEntryForm.project',
    defaultMessage: 'Project',
    description: 'Label for project field in add hours entry form',
  },
  issue: {
    id: 'addOrUpdateHoursEntryForm.issue',
    defaultMessage: 'Issue',
    description: 'Label for issue field in add hours entry form',
  },
  category: {
    id: 'addOrUpdateHoursEntryForm.category',
    defaultMessage: 'Category',
    description: 'Label for category field in add hours entry form',
  },
  date: {
    id: 'addOrUpdateHoursEntryForm.date',
    defaultMessage: 'Date',
    description: 'Label for date field in add hours entry form',
  },
  dateFrom: {
    id: 'addOrUpdateHoursEntryForm.dateFrom',
    defaultMessage: 'Date From',
    description: 'Label for date from field in add hours entry form',
  },
  dateTo: {
    id: 'addOrUpdateHoursEntryForm.dateTo',
    defaultMessage: 'Date To',
    description: 'Label for date to field in add hours entry form',
  },
  time: {
    id: 'addOrUpdateHoursEntryForm.time',
    defaultMessage: 'Time',
    description: 'Label for time header in hours list table',
  },
  timeFrom: {
    id: 'addOrUpdateHoursEntryForm.timeFrom',
    defaultMessage: 'Time From',
    description: 'Label for start time field in add hours entry form',
  },
  timeTo: {
    id: 'addOrUpdateHoursEntryForm.timeTo',
    defaultMessage: 'Time To',
    description: 'Label for end time field in add hours entry form',
  },
  hours: {
    id: 'addOrUpdateHoursEntryForm.hours',
    defaultMessage: 'Hours',
    description: 'Label for hours field in add hours entry form',
  },
  dailyHours: {
    id: 'addOrUpdateHoursEntryForm.dailyHours',
    defaultMessage: 'Daily Hours',
    description: 'Label for daily hours field in add hours entry form',
  },
  type: {
    id: 'addOrUpdateHoursEntryForm.type',
    defaultMessage: 'Type',
    description: 'Label for type field in add hours entry form',
  },
  workCategory: {
    id: 'addOrUpdateHoursEntryForm.workCategory',
    defaultMessage: 'Work category',
    description: 'Label for work category field in add hours entry form',
  },
  username: {
    id: 'addOrUpdateHoursEntryForm.username',
    defaultMessage: 'Username',
    description: 'Label for username field in add hours entry form',
  },
  description: {
    id: 'addOrUpdateHoursEntryForm.description',
    defaultMessage: 'Description',
    description: 'Label for description field in add hours entry form',
  },
  isHomeOffice: {
    id: 'addOrUpdateHoursEntryForm.isHomeOffice',
    defaultMessage: 'Home Office / Remote',
    description: 'Label for isHomeOffice checkbox in add hours entry form',
  },
  dayTotal: {
    id: 'hoursList.dayTotal',
    defaultMessage: 'Total',
    description: 'Label for day total field in hours list',
  },
  delete: {
    id: 'list.delete',
    defaultMessage: 'Delete',
    description: 'label for delete button',
  },
  deleteQuestion: {
    id: 'listAlert.deleteQuestion',
    defaultMessage: 'Do you really want to delete this entry?',
    description: 'question for delete alert',
  },
  deleteConfirm: {
    id: 'listAlert.deleteConfirm',
    defaultMessage: 'Yes, delete it!',
    description: 'confirm for delete alert',
  },
  deleteTitle: {
    id: 'listAlert.deleteTitle',
    defaultMessage: 'Are you sure?',
    description: 'title for delete alert',
  },
});

export default messages;
