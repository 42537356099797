import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import baseStyles from '../../styles/app.scss';
import s from './AdminLayout.scss';
import TopNavigation from './TopNavigation';
import Sidebar from './Sidebar';
import Footer from './Footer';

class AdminLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <div className="container-fluid" style={{ padding: '0' }}>
        <TopNavigation />
        <Footer />
        <div style={{ float: 'left' }}>
          <Sidebar />
        </div>
        <div className={s.layoutContainer}>
          <div className={`content ${s.layoutContent}`}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(baseStyles, s)(AdminLayout);
