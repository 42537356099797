import { FaFolder, FaFolderOpen } from 'react-icons/fa';

import allRoles from 'core/roles';
import messages from './messages';

// only one isMain Nav is allowed and needed
const navTree = showWorkCategory => [
  {
    name: messages.dashboard,
    mainNav: {
      name: messages.dashboard,
      path: '/admin/dashboard',
      roles: [],
    },
    subNavs: [],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.hours,
    mainNav: {
      name: messages.hours,
      path: '/admin/hours',
      roles: [],
    },
    subNavs: [],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.account,
    mainNav: {
      name: messages.account,
      path: '/admin/account',
      roles: [],
    },
    subNavs: [],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.users,
    mainNav: {
      name: messages.users,
      path: '/admin/users',
      roles: [allRoles.ADMIN],
    },
    subNavs: [],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.shortWork,
    mainNav: {
      name: messages.shortWork,
      path: '/admin/short-work',
      roles: [allRoles.ADMIN],
    },
    subNavs: [],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.projects,
    mainNav: {
      name: messages.projects,
      path: '/admin/projects',
      roles: [allRoles.ADMIN, allRoles.PROJECT_MANAGER],
    },
    subNavs: [],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.controlling,
    subNavs: [
      {
        name: messages.projectManagement,
        path: '/admin/controlling/projectManagement',
        roles: [allRoles.ADMIN, allRoles.PROJECT_MANAGER],
      },
      {
        name: messages.issueManagement,
        path: '/admin/controlling/issueManagement',
        oAuthProvider: 'gitlab',
        roles: [allRoles.ADMIN, allRoles.PROJECT_MANAGER],
      },
      showWorkCategory && {
        name: messages.workCategoryManagement,
        path: '/admin/controlling/workCategoryManagement',
        roles: [allRoles.ADMIN, allRoles.PROJECT_MANAGER],
      },
    ],
    mainNav: {
      name: messages.controlling,
      path: '/admin/controlling/ratios',
      roles: [allRoles.ADMIN, allRoles.PROJECT_MANAGER],
    },
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
];

export default navTree;
