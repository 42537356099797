import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';
import { FaUser } from 'react-icons/fa';
import qs from 'qs';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Loading from 'components/Loading';
import Filter from 'components/Filter';
import List from 'components/HoursList/List';
import Alert, { alertOpts } from 'components/Alert';

import messages from './messages';
import EditUserMonthsForm from './EditUserMonthsForm';
import UserEditForm from './EditForm';
import TotalFilteredHours from '../../Filter/TotalFilteredHours';
import layoutStyle from '../../../styles/base/layout.scss';
import {
  DATEONLY_STRING_FMT_DB,
  dateOnlyToString,
  stringToDateOnly,
} from '../../../core/dateonly';

class UserEdit extends React.Component {
  static propTypes = {
    userQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
        hoursEntries: PropTypes.array.isRequired,
      }),
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        username: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    updateUser: PropTypes.func.isRequired,
    updateUserMonths: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMonthsSubmit = this.handleMonthsSubmit.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.handleExport = this.handleExport.bind(this);

    this.state = {
      filter: {
        from: moment()
          .startOf('month')
          .format(DATEONLY_STRING_FMT_DB),
        to: moment()
          .endOf('month')
          .format(DATEONLY_STRING_FMT_DB),
      },
      tabs: {
        activeKey: 'hoursList',
      },
      alert: {
        ...alertOpts.success,
      },
    };
  }

  handleTabSelect(key) {
    this.setState({ tabs: { activeKey: key } });
  }

  async handleSubmit(formValues) {
    try {
      const values = {
        ..._.omit(formValues, ['__typename']),
      };
      if (formValues.startDate) {
        values.startDate = stringToDateOnly(formValues.startDate);
      }
      await this.props.updateUser(values);
      this.setState({ alert: { show: true } });
    } catch (e) {
      this.setState({
        alert: {
          ...alertOpts.error,
          show: true,
          msg: e.message,
        },
      });
    }
  }

  async handleMonthsSubmit(formValues) {
    try {
      const payload = {
        id: formValues.id,
        months: formValues.months.map(month => _.omit(month, ['__typename'])),
      };
      await this.props.updateUserMonths(payload);
      this.setState({ alert: { show: true } });
    } catch (e) {
      this.setState({
        alert: {
          ...alertOpts.error,
          show: true,
          msg: e.message,
        },
      });
    }
  }

  closeAlert() {
    this.setState({ alert: { show: false } });
  }

  async applyFilters({ filter }) {
    this.setState({ filter });
    this.props.userQuery.refetch({
      filter,
    });
  }

  handleExport({ filter, format }) {
    const filterString = qs.stringify({ filter });
    const exportTab = window.open('', '_blank');
    const userId = this.props.userQuery.user.id;
    const exportUrl = `/export/user/${userId}?${filterString}&format=${format}`;
    exportTab.location.href = exportUrl;
    exportTab.focus();
  }

  render() {
    const { userQuery: { loading, user }, intl } = this.props;
    const { filter } = this.state;
    const { alert } = this.state;

    if (loading || !user) return <Loading />;

    return (
      <div>
        <div className="h3">
          <FaUser size={20} style={{ marginRight: '5px' }} />
          {user.username}
        </div>
        <Tabs
          id="user-edit-tabs"
          activeKey={this.state.tabs.activeKey}
          onSelect={this.handleTabSelect}
          animation={false}
        >
          <Tab
            eventKey="hoursList"
            title={intl.formatMessage(messages.hoursListTabTitle)}
          >
            <Row className={layoutStyle.noMargin} style={{ marginTop: '15px' }}>
              <Col xs={12} className={layoutStyle.noPadding}>
                <Filter
                  onFilterApply={this.applyFilters}
                  appliedValues={filter}
                  projects={user.projects}
                  onExport={this.handleExport}
                />
              </Col>
              <Col xs={12} className={layoutStyle.noPadding}>
                <TotalFilteredHours
                  filter={filter}
                  hoursEntries={this.props.userQuery.user.hoursEntries}
                />
              </Col>
              <Col xs={12} className={layoutStyle.noPadding}>
                <List user={user} filter={filter} editable />
              </Col>
            </Row>
          </Tab>
          <Tab
            eventKey="months"
            title={intl.formatMessage(messages.monthsTabTitle)}
          >
            <Row className={layoutStyle.noMargin} style={{ marginTop: '15px' }}>
              <Col xs={12} className={layoutStyle.noPadding}>
                <EditUserMonthsForm
                  initialValues={{
                    id: user.id,
                    months: [...user.months].sort(
                      (a, b) =>
                        new Date(a.start).getTime() >
                        new Date(b.start).getTime()
                          ? -1
                          : 1,
                    ),
                  }}
                  onSubmit={this.handleMonthsSubmit}
                />
              </Col>
            </Row>
          </Tab>
          <Tab
            eventKey="userData"
            title={intl.formatMessage(messages.userDataTabTitle)}
          >
            <Row className={layoutStyle.noMargin} style={{ marginTop: '15px' }}>
              <Col xs={12} className={layoutStyle.noPadding}>
                <UserEditForm
                  initialValues={{
                    id: user.id,
                    username: user.username,
                    weeklyHours: user.weeklyHours,
                    startDate: dateOnlyToString(user.startDate),
                  }}
                  onSubmit={this.handleSubmit}
                />
              </Col>
            </Row>
          </Tab>
        </Tabs>
        <Alert {...alert} onConfirm={this.closeAlert} />
      </div>
    );
  }
}

const userQuery = gql`
  query userQuery($username: String!, $filter: HoursEntryFilter) {
    user(username: $username) {
      id
      username
      weeklyHours
      startDate
      projects {
        id
        name
        categories {
          id
          name
        }
      }
      months {
        id
        name
        weeklyHours
        start
      }
      hoursEntries(filter: $filter) {
        id
        date
        timeFrom
        hours
        type
        workCategory {
          id
          name
          color
        }
        project {
          id
          name
        }
        issue {
          id
          ticketId
          title
        }
        category {
          id
          name
        }
        description
      }
      dailyBalance {
        total
        done
        balance
      }
      weeklyBalance(filter: $filter) {
        total
        done
        balance
      }
      monthlyBalance(filter: $filter) {
        total
        done
        balance
      }
      totalBalance(filter: $filter) {
        total
        done
        balance
      }
      vacationBalance(filter: $filter) {
        total
        done
        balance
      }
    }
  }
`;

const updateUserMutation = gql`
  mutation updateUser($user: UserInput!) {
    updateUser(user: $user) {
      id
      username
      active
      weeklyHours
    }
  }
`;

const updateUserMonthsMutation = gql`
  mutation updateUserMonths($user: UserInput!) {
    updateUserMonths(user: $user) {
      id
      months {
        id
        name
        weeklyHours
        start
      }
    }
  }
`;

export default compose(
  graphql(userQuery, {
    name: 'userQuery',
    options: ({ match }) => ({
      variables: {
        username: match.params.username,
        filter: {
          from: moment()
            .startOf('month')
            .format(DATEONLY_STRING_FMT_DB),
          to: moment()
            .endOf('month')
            .format(DATEONLY_STRING_FMT_DB),
        },
      },
    }),
  }),
  graphql(updateUserMutation, {
    props: ({ mutate }) => ({
      updateUser: user =>
        mutate({
          variables: { user },
        }),
    }),
  }),
  graphql(updateUserMonthsMutation, {
    props: ({ mutate }) => ({
      updateUserMonths: user =>
        mutate({
          variables: { user },
        }),
    }),
  }),
)(injectIntl(withStyles(layoutStyle)(UserEdit)));
