import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './EmployeeBalance.scss';
import messages from './messages';
import EmployeeBalanceTable from './EmployeeBalanceTable';
import Collapsible from '../Collapsible';

const WA_TIME_VIEW_BALANCE = 'WA_TIME_VIEW_BALANCE';

class EmployeeBalance extends React.Component {
  static propTypes = {
    user: PropTypes.shape({
      username: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const { user } = this.props;

    return (
      <Collapsible
        openStateKey={WA_TIME_VIEW_BALANCE}
        headerContent={
          <span className={s.balanceHeader}>
            <h2>
              <FormattedMessage {...messages.employeeBalance} />
            </h2>
          </span>
        }
      >
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>
                  <FormattedMessage {...messages.dailyBalance} />
                </th>
                <th>
                  <FormattedMessage {...messages.weeklyBalance} />
                </th>
                <th>
                  <FormattedMessage {...messages.monthlyBalance} />
                </th>
                <th>
                  <FormattedMessage {...messages.totalBalance} />
                </th>
                <th>
                  <FormattedMessage {...messages.vacationBalance} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <EmployeeBalanceTable
                    total={user.dailyBalance.total}
                    done={user.dailyBalance.done}
                    balance={user.dailyBalance.balance}
                  />
                </td>
                <td>
                  <EmployeeBalanceTable
                    total={user.weeklyBalance.total}
                    done={user.weeklyBalance.done}
                    balance={user.weeklyBalance.balance}
                  />
                </td>
                <td>
                  <EmployeeBalanceTable
                    total={user.monthlyBalance.total}
                    done={user.monthlyBalance.done}
                    balance={user.monthlyBalance.balance}
                  />
                </td>
                <td>
                  <EmployeeBalanceTable
                    total={user.totalBalance.total}
                    done={user.totalBalance.done}
                    balance={user.totalBalance.balance}
                  />
                </td>
                <td>
                  <EmployeeBalanceTable
                    total={user.vacationBalance.total}
                    done={user.vacationBalance.done}
                    balance={user.vacationBalance.balance}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Collapsible>
    );
  }
}

export default withStyles(s)(EmployeeBalance);
