import React from 'react';
import PropTypes from 'prop-types';

import RouteBundler from 'components/RouteBundler';
import AuthorizationWrapper from 'components/Auth/AuthorizationWrapper';
import roles from 'core/roles';

import Ratios from './Ratios';

/* eslint-disable prettier/prettier */
const routes = [{ path: '', component: Ratios, exact: true }];
/* eslint-enable prettier/prettier */

class RatioManagement extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  };
  render() {
    return (
      <RouteBundler
        prefix={`${this.props.match.path}/`}
        routes={routes}
        me={this.props.me}
      />
    );
  }
}

export default AuthorizationWrapper([roles.PROJECT_MANAGER, roles.ADMIN])(
  RatioManagement,
);
