import { defineMessages } from 'react-intl';

export const messagesSet = defineMessages({
  heading: {
    id: 'set-password.heading',
    defaultMessage: 'Set New Password',
    description: 'Heading for set password form',
  },
  info: {
    id: 'set-password.info',
    defaultMessage: `
      Please enter your new password twice in order to mitigate typing errors.
    `,
    description: 'description',
  },
  password: {
    id: 'set-password.password',
    defaultMessage: 'Password',
    description: 'Password field placeholder text in set password form',
  },
  passwordRepeat: {
    id: 'set-password.passwordRepeat',
    defaultMessage: 'Repeat password',
    description: 'Password repeat field placeholder text in set password form',
  },
  submit: {
    id: 'set-password.submit',
    defaultMessage: 'Set password',
    description: 'Submit button text in set password form',
  },
  cancel: {
    id: 'set-password.cancel',
    defaultMessage: 'Cancel',
    description: 'Cancel button text in set password form',
  },
  alertSuccessTitle: {
    id: 'set-password.alertSuccessTitle',
    defaultMessage: 'Success',
    description: 'Header for success alert dialog',
  },
  alertSuccessMessage: {
    id: 'set-password.alertSuccessMessage',
    defaultMessage: 'Password was reset!',
    description: 'Message for success alert dialog',
  },
  alertErrorTitle: {
    id: 'set-password.alertErrorTitle',
    defaultMessage: 'Error',
    description: 'Header for error alert dialog',
  },
  alertErrorMessage: {
    id: 'set-password.alertErrorMessage',
    defaultMessage: 'Invalid Token!',
    description: 'Message for error alert dialog',
  },
});

export const messagesReset = defineMessages({
  heading: {
    id: 'reset-password.heading',
    defaultMessage: 'Reset Password',
    description: 'Heading for login form',
  },
  info: {
    id: 'reset-password.info',
    defaultMessage: `
      Please enter your username so that we can send you a link you can use to reset your password.
    `,
    description: 'description',
  },
  username: {
    id: 'reset-password.username',
    defaultMessage: 'Username',
    description: 'E-Mail field placeholder text in reset password form',
  },
  submit: {
    id: 'reset-password.submit',
    defaultMessage: 'request reset',
    description: 'Submit button text in reset password form',
  },
  cancel: {
    id: 'reset-password.cancel',
    defaultMessage: 'Cancel',
    description: 'Cancel button text in reset password form',
  },
  alertSuccessTitle: {
    id: 'reset-password.alertSuccessTitle',
    defaultMessage: 'Success',
    description: 'Header for success alert dialog',
  },
  alertSuccessMessage: {
    id: 'reset-password.alertSuccessMessage',
    defaultMessage: 'E-Mail was sent!',
    description: 'Message for success alert dialog',
  },
});

export const messagesChange = defineMessages({
  heading: {
    id: 'change-password.heading',
    defaultMessage: 'Change Password',
    description: 'Heading for change password form',
  },
  info: {
    id: 'change-password.info',
    defaultMessage: `
      Please enter your current password and your new password twice in order to mitigate typing errors.
    `,
    description: 'description',
  },
  currentPassword: {
    id: 'change-password.currentPassword',
    defaultMessage: 'Current Password',
    description:
      'Current password field placeholder text in change password form',
  },
  newPassword: {
    id: 'change-password.newPassword',
    defaultMessage: 'New Password',
    description: 'New password field placeholder text in change password form',
  },
  passwordRepeat: {
    id: 'change-password.passwordRepeat',
    defaultMessage: 'Repeat New Password',
    description:
      'Password repeat field placeholder text in change password form',
  },
  submit: {
    id: 'change-password.submit',
    defaultMessage: 'Set password',
    description: 'Submit button text in change password form',
  },
  cancel: {
    id: 'change-password.cancel',
    defaultMessage: 'Cancel',
    description: 'Cancel button text in change password form',
  },
});

export default { messagesSet, messagesReset, messagesChange };
