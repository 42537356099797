import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Dropdown, Glyphicon, MenuItem } from 'react-bootstrap';
import qs from 'qs';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Loading from 'components/Loading';
import Filter from 'components/Filter';
import List from 'components/HoursList/List';
import TotalFilteredHours from 'components/Filter/TotalFilteredHours';
import layoutStyle from '../../styles/base/layout.scss';
import { DATEONLY_STRING_FMT_DB } from '../../core/dateonly';
import NotFound from '../NotFound';

const DropdownMenu = Dropdown.Menu;
const DropdownToggle = Dropdown.Toggle;

class ProjectView extends React.Component {
  static propTypes = {
    projectQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      project: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.applyFilters = this.applyFilters.bind(this);
    this.handleExport = this.handleExport.bind(this);

    this.state = {
      filter: {
        from: moment()
          .startOf('month')
          .format(DATEONLY_STRING_FMT_DB),
        to: moment()
          .endOf('month')
          .format(DATEONLY_STRING_FMT_DB),
        project: props.match.params.id,
      },
    };
  }

  async applyFilters({ filter }) {
    // force filter to current project
    const filterParams = {
      ...filter,
      project: this.props.match.params.id,
      type: 'work',
    };
    this.setState({ filter: filterParams });
    this.props.projectQuery.refetch({
      filter: filterParams,
    });
  }

  handleExport(format) {
    const { match: { params: { id } } } = this.props;
    const filter = qs.stringify({ filter: this.state.filter });
    const exportTab = window.open('', '_blank');
    if (exportTab) {
      const exportUrl = `/export/project/${id}?${filter}&format=${format}`;
      exportTab.location.href = exportUrl;
      exportTab.focus();
    }
  }

  render() {
    const { projectQuery: { loading, project } } = this.props;
    const { filter } = this.state;
    if (loading) return <Loading />;
    const hoursEntries = [];
    const users = project && project.users ? project.users : [];
    users.forEach(user =>
      user.hoursEntries.forEach(hoursEntry => {
        hoursEntries.push(hoursEntry);
      }),
    );
    const exportFormats = [
      {
        label: 'PDF',
        value: 'pdf',
      },
      {
        label: 'XLSX',
        value: 'xlsx',
      },
    ];
    const formats = exportFormats.map(fmt => (
      <MenuItem key={fmt.value} eventKey={fmt.value}>
        {fmt.label}
      </MenuItem>
    ));
    if (!project) {
      return <NotFound />;
    }
    return (
      <div>
        <div className="h3">{project.name}</div>
        <Row className={layoutStyle.noMargin} style={{ marginTop: '15px' }}>
          <Col xs={12} className={layoutStyle.noPadding}>
            <Filter
              onFilterApply={this.applyFilters}
              appliedValues={filter}
              projects={[project]}
            />
          </Col>
          <Col xs={12} className={layoutStyle.noPadding}>
            <TotalFilteredHours filter={filter} hoursEntries={hoursEntries} />
            {Object.keys(filter).length > 0 ? (
              <div style={{ paddingBottom: '15px' }} />
            ) : (
              <div />
            )}
          </Col>
          <Col xs={12} className={layoutStyle.noPadding}>
            <Dropdown
              id="exportDropdown"
              onSelect={value => this.handleExport(value)}
            >
              <DropdownToggle className="btn dropdown-toggle">
                <Glyphicon glyph="export" />
                Export
              </DropdownToggle>
              <DropdownMenu>{formats}</DropdownMenu>
            </Dropdown>
          </Col>
          <Col xs={12} className={layoutStyle.noPadding}>
            {users.map(
              user =>
                user.hoursEntries && user.hoursEntries.length > 0 ? (
                  <div key={user.id}>
                    <h3>{user.username}</h3>
                    <List user={user} filter={filter} withBalance={false} />
                  </div>
                ) : null,
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const projectQuery = gql`
  query projectQuery($id: String!, $filter: HoursEntryFilter) {
    project(id: $id) {
      id
      name
      categories {
        id
        name
      }
      users {
        id
        username
        weeklyHours
        startDate
        projects {
          id
          name
        }
        months {
          id
          name
          weeklyHours
          start
        }
        hoursEntries(filter: $filter) {
          id
          date
          timeFrom
          hours
          type
          workCategory {
            id
            name
            color
          }
          project {
            id
            name
          }
          issue {
            id
            ticketId
            title
          }
          category {
            id
            name
          }
          description
        }
      }
    }
  }
`;

export default compose(
  graphql(projectQuery, {
    name: 'projectQuery',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
        filter: {
          from: moment()
            .startOf('month')
            .format(DATEONLY_STRING_FMT_DB),
          to: moment()
            .endOf('month')
            .format(DATEONLY_STRING_FMT_DB),
          project: match.params.id,
        },
      },
    }),
  }),
)(withStyles(layoutStyle)(ProjectView));
