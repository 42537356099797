import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import reactTableStyle from 'react-table/react-table.css';
import ReactTable from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';

import s from './Table.scss'; // eslint-disable-line css-modules/no-unused-class

const CheckboxTable = checkboxHOC(ReactTable);

class Table extends React.Component {
  static propTypes = {
    isCheckable: PropTypes.bool,
    keyField: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    isCheckable: false,
    keyField: 'id',
  };

  constructor() {
    super();

    this.state = {
      selection: {},
      selectAll: false,
    };

    this.toggleSelection = this.toggleSelection.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.isSelected = this.isSelected.bind(this);
  }

  /**
   * toggles the selection of the row with this key
   * @param {*} key
   */
  toggleSelection(key) {
    const newSelection = { ...this.state.selection };

    if (newSelection[key]) {
      newSelection[key] = false;
    } else {
      newSelection[key] = true;
    }

    this.setState({ selection: newSelection });
  }

  /**
   * selects or unselects all rows
   */
  toggleAll() {
    const newSelectAll = !this.state.selectAll;
    const newSelection = {};

    if (newSelectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach(item => {
        newSelection[item._original.id] = true; // eslint-disable-line no-underscore-dangle
      });
    }

    this.setState({ selectAll: newSelectAll, selection: newSelection });
  }

  isSelected = key => this.state.selection[key] === true; // ask for true as undefined should correspond to false

  render() {
    const { data, columns, ...rest } = this.props;
    const { toggleSelection, toggleAll, isSelected } = this;
    const { selectAll } = this.state;
    const { isCheckable, keyField } = this.props;

    if (isCheckable) {
      const checkboxProps = {
        selectAll,
        isSelected,
        toggleSelection,
        toggleAll,
        selectType: 'checkbox',
        getTrProps: (st, r) => {
          const selected = r && this.isSelected(r.original.id);
          if (selected) {
            return {
              style: {
                backgroundColor: 'lightgreen',
              },
            };
          }
          return {
            style: {},
          };
        },
      };

      return (
        <div>
          <CheckboxTable
            ref={r => (this.checkboxTable = r)} // eslint-disable-line no-return-assign
            data={data}
            defaultPageSize={20}
            minRows={0}
            columns={columns}
            className="-striped"
            keyField={keyField}
            {...checkboxProps}
          />
        </div>
      );
    }

    return (
      <div>
        <ReactTable
          className="-striped"
          data={data}
          defaultPageSize={20}
          minRows={0}
          columns={columns}
          {...rest}
        />
      </div>
    );
  }
}

export default withStyles(s, reactTableStyle)(Table);
