import { defineMessages } from 'react-intl';

const messages = defineMessages({
  nameHeading: {
    id: 'workCategory.heading.name',
    defaultMessage: 'Name',
    description: 'Heading for Name column in table',
  },
  colorHeading: {
    id: 'workCategory.heading.color',
    defaultMessage: 'Color',
    description: 'Heading for Color column in table',
  },
  descriptionHeading: {
    id: 'workCategory.heading.description',
    defaultMessage: 'Description',
    description: 'Heading for Description column in table',
  },
  activeHeading: {
    id: 'workCategory.heading.isActive',
    defaultMessage: 'Active',
    description: 'Heading for Active column in table',
  },
  defaultHeading: {
    id: 'workCategory.heading.defaultHeading',
    defaultMessage: 'Default',
    description: 'Heading for Default column in table',
  },
  editWorkCategory: {
    id: 'workCategory.editWorkCategory',
    defaultMessage: 'Edit work category',
    description: 'Label for Edit WorkCategory button',
  },
  createWorkCategory: {
    id: 'workCategory.createWorkCategory',
    defaultMessage: 'Create work category',
    description: 'Label for Create WorkCategory button',
  },
  submit: {
    id: 'editForm.submit',
    defaultMessage: 'Apply',
    description: 'Submit button text in editForm form',
  },
  noCategory: {
    id: 'editForm.noCategory',
    defaultMessage: 'No Categories available',
    description: 'Submit button text in editForm form',
  },
  filterHeading: {
    id: 'filter.heading',
    defaultMessage: 'Filter',
    description: 'Heading for filter',
  },
});

export default messages;
