exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".aoSWK{color:#00dcb2;border:none;background-color:transparent}.aoSWK:hover{color:#66ead0}._2cyqF{display:flex;justify-content:center;align-items:center}._2cyqF,._321OX{width:100%;height:100%}._321OX{position:relative}._321OX ._1ufTX{display:flex;flex-direction:column;justify-content:center;align-items:center;position:absolute;top:50%;left:50%}._321OX ._1ufTX ._2zP98{padding:0;list-style-type:none;text-wrap:wrap}._321OX ._1ufTX ._2zP98>li{font-weight:700;padding:2px;border-radius:2px}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"trashcan": "aoSWK",
	"colorBox": "_2cyqF",
	"tableWrapper": "_321OX",
	"errorWrapper": "_1ufTX",
	"errorBox": "_2zP98"
};