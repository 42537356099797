import moment from 'moment';

export const DATEONLY_STRING_FMT = 'DD.MM.YYYY';
export const DATEONLY_STRING_FMT_DB = 'YYYY-MM-DD';

export const stringToDateOnly = (value, fmt = DATEONLY_STRING_FMT) =>
  moment(value, fmt).format(DATEONLY_STRING_FMT_DB);

export const stringToDateOnlyDB = (value, fmt = DATEONLY_STRING_FMT_DB) =>
  moment(value, fmt).format(DATEONLY_STRING_FMT_DB);

export const dateOnlyToString = (value, fmt = DATEONLY_STRING_FMT) =>
  moment(value, DATEONLY_STRING_FMT_DB).format(fmt);

export const stringToDateOnlyStart = (value, fmt = DATEONLY_STRING_FMT_DB) =>
  moment
    .utc(value, fmt)
    .startOf('day')
    .format('YYYY-MM-DD HH:mm:ss');
export const stringToDateOnlyEnd = (value, fmt = DATEONLY_STRING_FMT_DB) =>
  moment
    .utc(value, fmt)
    .endOf('day')
    .format('YYYY-MM-DD HH:mm:ss');
