import { defineMessages } from 'react-intl';

// NOTE: These messages have to be unique among the error message files

const messages = defineMessages({
  workCategoryCouldntBeCreated: {
    id: 'errors.workCategories.workCategoryCouldntBeCreated',
    defaultMessage: 'Could not create Work category',
    description: 'Error message for no category with mandatory category',
  },
  noWorkCategoryFound: {
    id: 'errors.workCategories.noWorkCategoryFound',
    defaultMessage: 'No Work category found!',
    description: 'Validation error message for no Work category found',
  },
  duplicateNameEntry: {
    id: 'errors.workCategories.duplicateEntry',
    defaultMessage: 'Work category with this name already exists',
    description: 'Validation error message for duplicate name entry',
  },
  duplicateColorEntry: {
    id: 'errors.workCategories.duplicateColorEntry',
    defaultMessage: 'Work category with this color already exists',
    description: 'Validation error message for duplicate color entry',
  },
  workCategoriesHasIssues: {
    id: 'errors.workCategories.workCategoriesHasIssues',
    defaultMessage:
      'Work category has linked hoursEntries and cannot be deleted',
    description: 'Validation error message for linked work categories',
  },
  defaultAlreadyExists: {
    id: 'errors.workCategories.defaultAlreadyExists',
    defaultMessage: 'Default work category already exists',
    description: 'Validation error message for existing default work category',
  },
  changeDefaultBeforeDeleting: {
    id: 'errors.workCategories.changeDefaultBeforeDeleting',
    defaultMessage: 'Change default work category before deleting',
    description: 'Validation error message for deleting default work category',
  },
  changeDefaultBeforeInactive: {
    id: 'errors.workCategories.changeDefaultBeforeInactive',
    defaultMessage: 'Change default work category before setting inactive',
    description:
      'Validation error message for setting default work category inactive',
  },
  thereMustBeOneDefaultWorkCategory: {
    id: 'errors.workCategories.thereMustBeOneDefaultWorkCategory',
    defaultMessage: 'There must be one default work category',
    description:
      'Validation error message for to atleast one default work category to exist',
  },
});

export default messages;
