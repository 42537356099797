import CustomError from './CustomError';
import errorMessages from './messages';

class AuthenticationError extends CustomError {
  constructor() {
    super(errorMessages.notAuthenticated.id, {});
    this.name = 'AuthenticationError';
  }
}

export default AuthenticationError;
