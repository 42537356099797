import moment from 'moment';
import { draw } from 'patternomaly';
import { Chart } from 'react-chartjs-2';

const DB_DATE_FORMAT = 'ddd MMM D YYYY HH:mm:ss ZZ';

const getCurrentDayBoundaries = () => {
  const start = moment()
    .utc()
    .startOf('day');
  const end = moment()
    .utc()
    .endOf('day');

  return { start, end };
};

const getCurrentWeekBoundaries = () => {
  const start = moment().startOf('isoWeek');
  const end = moment().endOf('isoWeek');

  return { start, end };
};

const getCurrentMonthBoundaries = () => {
  const start = moment()
    .startOf('month')
    .toDate();
  const end = moment()
    .endOf('month')
    .toDate();

  return { start, end };
};

const getWeekBoundariesByNum = ({ num }) => {
  if (!num) return null;
  const start = moment()
    .week(num)
    .startOf('isoWeek');
  const end = moment()
    .week(num)
    .endOf('isoWeek');

  return { start, end };
};

const getMonthBoundariesByNum = ({ num, year }) => {
  if (!num && num !== 0) return null;
  const start = moment()
    .year(year)
    .month(num)
    .startOf('month');
  const end = moment()
    .year(year)
    .month(num)
    .endOf('month');

  return { start, end };
};

const getMonthBoundariesByDate = ({ date, format = 'DD.MM.YYYY' }) => {
  const currentDate = moment(date, format);
  const start = moment(currentDate)
    .startOf('month')
    .toDate();
  const end = moment(currentDate)
    .endOf('month')
    .toDate();

  return { start, end };
};

const getTimeSlots = ({ interval = 15 } = {}) => {
  const start = moment().startOf('day');
  const end = moment().endOf('day');
  const timeSlots = [];
  for (let i = start; i.isBefore(end); i.add(interval, 'minutes')) {
    timeSlots.push(i.format('HH:mm'));
  }
  return timeSlots;
};

const calculateInitialTimeValue = ({ interval = 15 } = {}) => {
  const now = moment();
  // round down to interval
  const diffUp = interval - now.minute() % interval; // eslint-disable-line prettier/prettier
  const diffDown = now.minute() % interval;
  let roundedTime;
  if (diffUp < diffDown) {
    roundedTime = now.add(diffUp, 'minutes');
  } else {
    roundedTime = now.subtract(diffDown, 'minutes');
  }
  return roundedTime;
};

const getWorkedHoursFromEntries = ({
  hoursEntries = [],
  shortWorkScalingFactor = null,
}) =>
  hoursEntries
    .map(hoursEntry => {
      if (shortWorkScalingFactor && hoursEntry.type !== 'work') {
        return parseFloat(hoursEntry.hours, 10) * shortWorkScalingFactor;
      }
      return parseFloat(hoursEntry.hours, 10);
    })
    .reduce((sum, val) => sum + val, 0);

const calcColorBrightness = color => {
  const rgb = Chart.helpers.color(color);

  const brightness = Math.round(
    (rgb.red() * 299 + rgb.green() * 587 + rgb.blue() * 114) / 1000,
  );
  return brightness > 125 ? 'black' : 'white';
};

const getRGBA = (color, alpha) => {
  const rgb = Chart.helpers.color(color);

  return `rgba(${rgb.red()},${rgb.green()},${rgb.blue()},${alpha})`;
};

const getPattern = (shape, color, alpha, fixToolTipBug) => {
  const rgb = Chart.helpers.color(color);
  return Chart.helpers.extend(
    draw(shape, getRGBA(color, alpha), fixToolTipBug ? getRGBA(color, 0) : getRGBA('#FFF', 0.6)),
    {
      r: rgb.red(),
      g: rgb.green(),
      b: rgb.blue(),
      alpha: rgb.alpha(),
    },
  );
};

export {
  DB_DATE_FORMAT,
  getRGBA,
  getPattern,
  getCurrentDayBoundaries,
  getCurrentWeekBoundaries,
  getCurrentMonthBoundaries,
  getWeekBoundariesByNum,
  getMonthBoundariesByNum,
  getMonthBoundariesByDate,
  getTimeSlots,
  calculateInitialTimeValue,
  getWorkedHoursFromEntries,
  calcColorBrightness,
};
