import React from 'react';

import Login from '../../components/Auth/login';

class LoginWrapper extends React.Component {
  render() {
    return (
      <div>
        <Login />
      </div>
    );
  }
}

export default LoginWrapper;
