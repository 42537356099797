import { defineMessages } from 'react-intl';

const messages = defineMessages({
  name: {
    id: 'month.name',
    defaultMessage: 'Name',
    description: 'Label for name field in month',
  },
  weeklyHours: {
    id: 'month.weeklyHours',
    defaultMessage: 'Weekly Hours',
    description: 'Label for weekly hours field in month',
  },
  start: {
    id: 'month.start',
    defaultMessage: 'Start',
    description: 'Label for start field in month',
  },
});

export default messages;
