exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".qpTIk{position:absolute;top:40%;left:50%;transform:translate(-50%,-50%)!important;height:80px;text-align:center}.qpTIk ._2WtBO{margin-bottom:30px;position:relative;height:80px}.qpTIk .wn2Rx{width:100vw;font-weight:400;font-size:40px;color:#00dcb2}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"notFoundWrapper": "qpTIk",
	"timeIcon": "_2WtBO",
	"timeNotFound": "wn2Rx"
};