import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Alert, { alertOpts } from 'components/Alert';
import Loading from 'components/Loading';

import CreateUserForm from './CreateUserForm';
import s from './CreateUser.scss';
import { usersQuery } from './Table';
import { getErrorsFromApollo } from '../../core/errors/util';
import errorMessages from '../../core/errors/messages';

class CreateUser extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    createUser: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    usersQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          username: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      redirect: null,
      alert: {
        ...alertOpts.success,
      },
    };

    this.closeAlert = this.closeAlert.bind(this);
    this.submitCreateUser = this.submitCreateUser.bind(this);
  }

  async submitCreateUser(formValues) {
    // eslint-disable-next-line prettier/prettier
        const { createUser } = this.props;
    try {
      await createUser({
        user: {
          username: formValues.username,
          email: formValues.email,
        },
      });

      this.setState({
        errors: [],
        redirect: `/admin/users/${formValues.username}/edit`,
        alert: {
          ...alertOpts.success,
          show: true,
        },
      });
    } catch (e) {
      if (__DEV__) {
        console.error(e.message);
      }
      this.setState({
        errors: [],
        redirect: null,
        alert: {
          ...alertOpts.error,
          show: true,
        },
      });

      const errors = getErrorsFromApollo(e);
      this.setState({
        errors: this.state.errors.concat(
          errors.map(err =>
            this.props.intl.formatMessage(
              errorMessages[err.message],
              err.messageValues,
            ),
          ),
        ),
        redirect: null,
        alert: {
          ...alertOpts.error,
          show: true,
        },
      });
    }
  }

  closeAlert() {
    this.setState({ alert: { show: false } });
  }

  render() {
    const { alert, redirect } = this.state;
    if (!alert.show && redirect) {
      const { history } = this.props;
      history.push({ pathname: redirect });
    }
    if (this.props.usersQuery.loading) {
      return <Loading />;
    }
    return (
      <div className={`container ${s.createUserContainer}`}>
        <CreateUserForm
          onSubmit={this.submitCreateUser}
          errors={this.state.errors}
          initialValues={{}}
          users={this.props.usersQuery ? this.props.usersQuery.users : []}
        />
        <Alert {...alert} onConfirm={this.closeAlert} />
      </div>
    );
  }
}

const createUserMutation = gql`
  mutation createUser($user: CreateUserInput!) {
    createUser(user: $user) {
      id
      username
      active
    }
  }
`;

export default compose(
  injectIntl,
  withStyles(s),
  withRouter,
  graphql(usersQuery, {
    name: 'usersQuery',
  }),
  graphql(createUserMutation, {
    props: ({ mutate }) => ({
      createUser: user =>
        mutate({
          variables: { ...user },
          update: (proxy, { data: { createUser: newUser } }) => {
            if (newUser) {
              // TODO: not working yet -> reload required!
              const data = proxy.readQuery({ query: usersQuery });
              data.users.push({
                id: newUser.id,
                username: newUser.username,
                active: newUser.active,
                __typename: 'User',
              });
              data.users = data.users.sort(
                (a, b) => (a.name < b.name ? -1 : 1),
              );
              proxy.writeQuery({ query: usersQuery, data });
            }
          },
        }),
    }),
  }),
)(CreateUser);
