import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import buttonStyle from '../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import s from './ResetPasswordForm.scss';
import render from '../ReduxForm/renderField';
import validations from '../ReduxForm/validations';
import validationMessages from '../ReduxForm/messages';
import { messagesReset } from './messages';

const validate = values => {
  const errors = {};
  if (!validations.required(values.username)) {
    errors.username = <FormattedMessage {...validationMessages.required} />;
  }
  return errors;
};

class ResetPasswordFrom extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  render() {
    const { intl, handleSubmit, errors } = this.props;
    return (
      <div className={s.resetPasswordForm}>
        <h1>
          <FormattedMessage {...messagesReset.heading} />
        </h1>
        <span className={s.subline}>
          <FormattedMessage {...messagesReset.info} />
        </span>
        <form className="default-form" onSubmit={handleSubmit}>
          <fieldset>
            <Field
              id="username"
              name="username"
              type="text"
              label={intl.formatMessage(messagesReset.username)}
              component={render.renderInput}
            />
            {errors.length === 0 ? null : (
              <ul style={{ padding: '0', listStyleType: 'none' }}>
                {errors.map(err => (
                  <li key={err} className="bg-danger">
                    {err}
                  </li>
                ))}
              </ul>
            )}
            <button
              type="submit"
              className={`${buttonStyle['btn-primary']} ${s.button}`}
            >
              <FormattedMessage {...messagesReset.submit} />
            </button>
            <Link to="/login">
              <button className={`${buttonStyle['btn-white-rnd']} ${s.button}`}>
                <FormattedMessage {...messagesReset.cancel} />
              </button>
            </Link>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default withStyles(s)(
  reduxForm({
    form: 're-set-password',
    validate,
    pure: false, // this is necessary to trigger form re-renders if the locale changes
  })(injectIntl(ResetPasswordFrom)),
);
