class CustomError extends Error {
  constructor(message, messageValues = {}) {
    super(message);
    this.message = message;
    this.messageValues = messageValues;
    this.name = 'CustomError';
    this.propagate = false;
    this.update = this.update.bind(this);
    this.toString = this.toString.bind(this);
  }

  toString() {
    return JSON.stringify({
      class: this.name,
      message: this.message,
      messageValues: this.messageValues,
    });
  }
  update(data) {
    Object.assign(this, data);
  }
}

export default CustomError;
