exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "body{background-color:#fbfbfb}._21L8U{margin:auto;margin-top:150px;padding:0 45px;overflow-x:hidden;height:100%;max-width:480px}@media(max-width:1200px){._21L8U{margin-top:50px}}@media(max-width:480px){._21L8U{padding:0 15px}}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"loginContainer": "_21L8U"
};