exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1YoPW>tbody>tr>td{padding-top:10px}._8H8Q_{background-color:#efefef}._3wA_1,._8H8Q_{padding-right:10px}._3wA_1{padding-left:10px;border-bottom:1px #fff}._3wA_1>div{margin:0}._3wA_1 input{border:1px solid #b4b4b4}._23rUa{margin-right:10px;padding:6.5px 12px}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"shortWorkUserTable": "_1YoPW",
	"shortWorkUser": "_8H8Q_",
	"shortWorkUserField": "_3wA_1",
	"trash": "_23rUa"
};