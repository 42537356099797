/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FaPlus, FaEdit, FaEye } from 'react-icons/fa';
import { Row, Col, ToggleButton } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Loading from 'components/Loading';
import tableMessages from 'components/Table/messages';
import Table from 'components/Table';
import layoutStyle from '../../../styles/base/layout.scss';
import messages from './messages';

class ProjectManagementTable extends React.Component {
  static propTypes = {
    managedProjectsQuery: PropTypes.shape({
      id: PropTypes.string,
      totalHoursOffered: PropTypes.number,
      isActive: PropTypes.bool,
      hourlyRate: PropTypes.number,
      deadline: PropTypes.string,
      name: PropTypes.string,
      offeredPrice: PropTypes.number,
      managedProjectPosition: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          visibility: PropTypes.string,
          isLinked: PropTypes.bool,
          managedProjectPositionCategories: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string,
              description: PropTypes.string,
              repositoryUrl: PropTypes.string,
              isLinked: PropTypes.bool,
              active: PropTypes.bool,
            }),
          ),
        }),
      ),
    }).isRequired,
    updateActiveProject: PropTypes.func.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.createColumns = this.createColumns.bind(this);
    this.createToggleButton = this.createToggleButton.bind(this);
    this.invertActive = this.invertActive.bind(this);
  }

  async invertActive(args) {
    const userActiveInput = {
      id: args.id,
      isActive: !args.isActive,
    };
    await this.props.updateActiveProject(userActiveInput);
  }

  createColumns() {
    const { match: { path } } = this.props;

    const columns = [
      {
        Header: <FormattedMessage {...messages.nameHeading} />,
        accessor: 'name',
        width: 350,
        Cell: ({ original }) => <span>{original.name}</span>,
        filterMethod: (filter, row) => {
          const filterString = filter.value.toLowerCase();
          const name = row._original.name.toLowerCase(); // eslint-disable-line no-underscore-dangle

          return name.indexOf(filterString) !== -1;
        },
      },
      {
        Header: <FormattedMessage {...messages.projectHeading} />,
        accessor: 'managedProjectPositions',
        filterable: true,
        width: 350,
        Cell: ({ original }) => <span>{original.managedProjectPositions}</span>,
        filterMethod: (filter, row) => {
          const filterString = filter.value.toLowerCase();
          const managedProjectPositions = row._original.managedProjectPositions.toLowerCase(); // eslint-disable-line no-underscore-dangle

          return managedProjectPositions.indexOf(filterString) !== -1;
        },
      },
      {
        Header: <FormattedMessage {...messages.totalHoursOfferedHeading} />,
        accessor: 'totalHoursOfferedHeading',
        filterable: false,
        width: 200,
        Cell: ({ original }) => <span>{original.totalHoursOffered}</span>,
        Filter: () => null,
      },
      {
        Header: <FormattedMessage {...messages.hourlyRateHeading} />,
        accessor: 'hourlyRateHeading',
        filterable: false,
        width: 200,
        Cell: ({ original }) => <span>{original.hourlyRate}</span>,
        Filter: () => null,
      },
      {
        Header: <FormattedMessage {...messages.offeredPriceHeading} />,
        accessor: 'offeredPriceHeading',
        filterable: false,
        width: 200,
        Cell: ({ original }) => <span>{original.offeredPrice}</span>,
        Filter: () => null,
      },
      {
        Header: <FormattedMessage {...messages.deadlineHeading} />,
        accessor: 'deadlineHeading',
        filterable: false,
        width: 200,
        Cell: ({ original }) => <span>{original.deadline}</span>,
        Filter: () => null,
      },
      {
        Header: <FormattedMessage {...tableMessages.actions} />,
        width: 510,
        Cell: ({ original }) => (
          <Row>
            <Col xs={1}>
              <Link to={`${path}${original.id}/view`}>
                <FaEye />
              </Link>
            </Col>
            <Col xs={1}>
              <Link to={`${path}${original.id}/edit`}>
                <FaEdit />
              </Link>
            </Col>
          </Row>
        ),
        Filter: () => null,
      },
      {
        Header: <FormattedMessage {...messages.activeHeading} />,
        width: 200,
        filterable: false,
        Cell: ({ original }) => this.createToggleButton(original),
      },
    ];

    return columns;
  }

  createToggleButton(args) {
    const message = args.isActive
      ? tableMessages.active
      : tableMessages.inactive;
    return (
      <ToggleButton
        className="tgl-btn"
        checked={args.isActive}
        type="checkbox"
        onChange={() => this.invertActive(args)}
        style={{ width: '125px' }}
      >
        {<FormattedMessage {...message} />}
      </ToggleButton>
    );
  }

  render() {
    const {
      managedProjectsQuery: { loading, managedProjects },
      match: { path },
    } = this.props;
    if (loading) return <Loading />;
    let projectData = [];
    // replace cateogries construct with comma seperated category string
    if (managedProjects)
      projectData = managedProjects.map(managedProject => ({
        ...managedProject,
        managedProjectPositions: managedProject.managedProjectPositions
          .map(
            mpp =>
              mpp.managedProjectPositionCategories.length !== 0
                ? mpp.managedProjectPositionCategories.map(
                    mppc => `${mppc.name} (${mpp.name})`,
                  )
                : mpp.name,
          )
          .join(', '),
      }));
    const columns = this.createColumns();
    return (
      <div>
        <Row className={layoutStyle.noMargin} style={{ marginBottom: '15px' }}>
          <Col xs={12} className={layoutStyle.noPadding}>
            <Link to={`${path}new`} className="btn btn-primary">
              <FaPlus className="icon--prepended icon-button" />
              <FormattedMessage {...messages.createManagedProject} />
            </Link>
          </Col>
        </Row>
        <Row className={layoutStyle.noMargin}>
          <Col xs={12} className={layoutStyle.noPadding}>
            <Table
              keyField="name"
              data={projectData}
              columns={columns}
              filterable
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export const managedProjectsQuery = gql`
  query managedProjects {
    managedProjects(showInactive: true) {
      id
      name
      isActive
      deadline
      totalHoursOffered
      hourlyRate
      offeredPrice
      managedProjectPositions {
        id
        name
        includeAllCategories
        includeHoursBookedOnProject
        projectId
        managedProjectPositionCategories {
          id
          name
        }
      }
    }
  }
`;

const updateActiveMutation = gql`
  mutation updateActiveManagedProject(
    $managedProject: ManagedProjectActiveInput!
  ) {
    updateActiveManagedProject(ManagedProject: $managedProject) {
      id
      isActive
    }
  }
`;

export default compose(
  graphql(managedProjectsQuery, {
    name: 'managedProjectsQuery',
  }),
  graphql(updateActiveMutation, {
    props: ({ mutate }) => ({
      updateActiveProject: managedProject =>
        mutate({
          variables: { managedProject },
        }),
    }),
  }),
)(withStyles(layoutStyle)(ProjectManagementTable));
