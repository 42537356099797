import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment';

import s from './Filter.scss';
import messages from './messages';
import FilterFormManagedProject, {
  messages as formMessages,
} from './FilterFormManagedProject';
import Collapsible from '../../Collapsible';
import {
  dateOnlyToString,
  stringToDateOnly,
  DATEONLY_STRING_FMT_DB,
} from '../../../core/dateonly';

const WA_TIME_VIEW_FILTER = 'WA_TIME_VIEW_FILTER';

class Filter extends React.Component {
  static propTypes = {
    onFilterApply: PropTypes.func.isRequired,
    appliedValues: PropTypes.shape({
      month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      project: PropTypes.string,
    }),
    onExport: PropTypes.func,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    appliedValues: {},
    onExport: null,
  };

  static getFilterOptions({ appliedValues, intl }) {
    const filterOptions = Object.keys(appliedValues)
      .filter(key => appliedValues[key])
      .map(key => {
        const value = appliedValues[key];

        if (key === 'from' || key === 'to') {
          return `${intl.formatMessage(formMessages[key])}: ${dateOnlyToString(
            value,
          )}`;
        }

        return `${intl.formatMessage(formMessages[key])}: ${value}`;
      })
      .join(', ');

    return filterOptions;
  }

  constructor(props) {
    super(props);

    this.state = {
      showFilters: true,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleExport = this.handleExport.bind(this);
  }

  handleSubmit(formValues) {
    const filter = { ...formValues };
    if (formValues.year) filter.year = formValues.year.value;
    if (formValues.type) filter.type = formValues.type.value;
    // from, to
    if (filter.from) filter.from = stringToDateOnly(filter.from);
    if (filter.to) filter.to = stringToDateOnly(filter.to);

    this.props.onFilterApply({ filter });
  }

  handleReset() {
    this.props.onFilterApply({
      filter: {
        from: moment()
          .startOf('month')
          .format(DATEONLY_STRING_FMT_DB),
        to: moment()
          .endOf('month')
          .format(DATEONLY_STRING_FMT_DB),
      },
    });
  }

  handleExport(formValues, format) {
    const { onExport } = this.props;
    if (onExport) {
      onExport({ filter: formValues, format });
    }
  }

  render() {
    const { appliedValues, onExport, intl } = this.props;
    const { showFilters } = this.state;
    const isFiltered = Object.keys(appliedValues).length > 0;
    const filterOptions = Filter.getFilterOptions({
      appliedValues,
      intl,
    });
    const filterValues = { ...appliedValues };

    return (
      <Collapsible
        openStateKey={WA_TIME_VIEW_FILTER}
        headerContent={
          <span className={s.filterHeader}>
            <h2>
              <FormattedMessage {...messages.filterHeading} />
            </h2>
            {isFiltered &&
              showFilters && (
                <div className={s.filteredParameters}>{filterOptions}</div>
              )}
          </span>
        }
        onToggle={open => {
          this.setState({ showFilters: !open });
        }}
      >
        <FilterFormManagedProject
          initialValues={{
            ...filterValues,
            from: filterValues.from
              ? dateOnlyToString(filterValues.from)
              : null,
            to: filterValues.to ? dateOnlyToString(filterValues.to) : null,
            year:
              'year' in filterValues
                ? {
                    value: filterValues.year,
                    label: filterValues.year,
                  }
                : null,
          }}
          onSubmit={this.handleSubmit}
          onReset={this.handleReset}
          onExport={
            onExport
              ? format => {
                  this.handleExport(appliedValues, format);
                }
              : null
          }
        />
      </Collapsible>
    );
  }
}

export default injectIntl(withStyles(s)(Filter));
