/**
 * HOC that checks if the current user is logged in.
 * If so, it renders the wrapped component and
 * passes the current user's information to
 * it via the 'me' prop.
 *
 * Otherwise, it redirects to the homepage.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';
import {
  meQuery,
  oAuthProviderQuery,
  showWorkCategoryQuery,
} from 'components/Auth/withLogin';
import Loading from 'components/Loading';

const AuthenticationWrapper = WrappedComponent => {
  class WithAuthentication extends React.Component {
    static propTypes = {
      meData: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        me: PropTypes.shape({
          id: PropTypes.string.isRequired,
          roles: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              name: PropTypes.string.isRequired,
            }),
          ),
        }),
      }).isRequired,
      oAuthProviderData: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        currentOAuthProvider: PropTypes.string,
      }).isRequired,
      showWorkCategory: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        showWorkCategory: PropTypes.string,
      }).isRequired,
      match: PropTypes.shape({
        path: PropTypes.string.isRequired,
      }).isRequired,
    };

    render() {
      const {
        meData: { loading, me },
        oAuthProviderData,
        showWorkCategory,
      } = this.props;
      if (loading || oAuthProviderData.loading || showWorkCategory.loading)
        return <Loading />;
      if (!me) {
        return <Redirect to="/login" />;
      }
      return (
        <WrappedComponent
          {...this.props}
          me={me}
          currentOAuthProvider={oAuthProviderData.currentOAuthProvider}
          showWorkCategory={showWorkCategory.showWorkCategory}
        />
      );
    }
  }
  return compose(
    graphql(meQuery, {
      name: 'meData',
    }),
    graphql(oAuthProviderQuery, {
      name: 'oAuthProviderData',
    }),
    graphql(showWorkCategoryQuery, {
      name: 'showWorkCategory',
    }),
  )(withRouter(WithAuthentication));
};

export default AuthenticationWrapper;
