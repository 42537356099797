import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

const alertOpts = {
  success: {
    show: false,
    type: 'success',
    title: <FormattedMessage {...messages.successTitle} />,
    msg: <FormattedMessage {...messages.successMessage} />,
  },
  error: {
    show: false,
    type: 'error',
    title: <FormattedMessage {...messages.errorTitle} />,
    msg: <FormattedMessage {...messages.errorMessage} />,
  },
};

class Alert extends React.Component {
  static propTypes = {
    msg: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  };

  static defaultProps = {
    msg: alertOpts.success.msg,
  };

  render() {
    return (
      <SweetAlert {...alertOpts.success} {...this.props}>
        {this.props.msg}
      </SweetAlert>
    );
  }
}

export { Alert as default, alertOpts };
