import _ from 'lodash';

const gate = {
  isAllowed({ user, allowedRoles }) {
    if (!user || !user.roles || user.roles.length === 0) return false;
    return (
      // eslint-disable-next-line prettier/prettier
      _.intersection(user.roles.map(role => role.name), allowedRoles).length !== 0
    );
  },

  hasRole({ user, role }) {
    return user && user.roles && user.roles.length > 0
      ? user.roles.map(r => r.name === role).reduce((acc, prev) => acc || prev)
      : false;
  },
};

export default gate;
