import React from 'react';
import PropTypes from 'prop-types';

import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import RouteBundler from 'components/RouteBundler';
import Dashboard from 'components/Dashboard';
import HoursList from 'components/HoursList';
import User from 'components/User';
import Account from 'components/Account';
import Project from 'components/Project';
import ShortWork from 'components/ShortWork';
import Controlling from 'components/Controlling';

/* eslint-disable prettier/prettier */
const routes = [
  { path: '', component: Dashboard, exact: true },
  { path: 'dashboard', component: Dashboard, exact: true },
  { path: 'hours', component: HoursList, exact: true },
  { path: 'users', component: User },
  { path: 'account', component: Account },
  { path: 'projects', component: Project },
  { path: 'short-work', component: ShortWork },
  { path: 'controlling', component: Controlling },
];
/* eslint-enable prettier/prettier */

class Admin extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    return (
      <RouteBundler
        prefix={`${this.props.match.path}/`}
        routes={routes}
        me={this.props.me}
      />
    );
  }
}

export default AuthenticationWrapper(Admin);
