import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { withRouter } from 'react-router';

import RouteBundler from './RouteBundler';
import routes from '../routes';
import Layout from './Layout';
import AdminLayout from './AdminLayout';
import LicenseExpired from './License/Expired';
import Loading from './Loading';
import UnauthorizedError from '../components/UnauthorizedErrorComponent';

const ContextType = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: PropTypes.func.isRequired,
  // Universal HTTP client
  fetch: PropTypes.func.isRequired,
  query: PropTypes.object,
  // Integrate Redux
  // http://redux.js.org/docs/basics/UsageWithReact.html
  ...ReduxProvider.childContextTypes,
  // Apollo Client
  client: PropTypes.object.isRequired,
  // ReactIntl
  intl: IntlProvider.childContextTypes.intl,
  locale: PropTypes.string,
};

/**
 * The top-level React component setting context (global) variables
 * that can be accessed from all the child components.
 *
 * https://facebook.github.io/react/docs/context.html
 *
 * Usage example:
 *
 *   const context = {
 *     history: createBrowserHistory(),
 *     store: createStore(),
 *   };
 *
 *   ReactDOM.render(
 *     <App context={context}>
 *       <Layout>
 *         <LandingPage />
 *       </Layout>
 *     </App>,
 *     container,
 *   );
 */
class App extends React.PureComponent {
  static propTypes = {
    context: PropTypes.shape(ContextType).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  };

  static childContextTypes = ContextType;

  constructor(props) {
    super(props);

    this.state = {
      config: null,
    };

    this.loadConfig = this.loadConfig.bind(this);
  }

  getChildContext() {
    return this.props.context;
  }

  componentDidMount() {
    const store = this.props.context && this.props.context.store;
    this.loadConfig();
    if (store) {
      this.lastLocale = store.getState().intl.locale;
      this.unsubscribe = store.subscribe(() => {
        const state = store.getState();
        const { newLocale, locale } = state.intl;
        if (!newLocale && this.lastLocale !== locale) {
          this.lastLocale = locale;
          this.forceUpdate();
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
  }

  loadConfig() {
    fetch(`/config.json`)
      .then(response => response.json())
      .then(config => {
        window.config = config;
        this.setState({ config });
      });
  }

  render() {
    if (!this.state.config) return <Loading />;
    const { client } = this.props.context;
    const { location } = this.props;
    const LayoutComponent =
      location.pathname.indexOf('admin') !== -1 ? AdminLayout : Layout;

    const store = this.props.context && this.props.context.store;
    if (store && !store.getState().license.valid) {
      return (
        <Layout>
          <LicenseExpired />
        </Layout>
      );
    }
    const state = store && store.getState();

    this.intl = (state && state.intl) || {};
    const { initialNow, locale, messages } = this.intl;
    const localeMessages = (messages && messages[locale]) || {};

    return (
      <ApolloProvider client={client}>
        <IntlProvider
          initialNow={initialNow}
          locale={locale}
          messages={localeMessages}
          defaultLocale={this.state.defaultLocale}
          location={this.props.location}
        >
          <LayoutComponent>
            <UnauthorizedError />
            <RouteBundler routes={routes} />
          </LayoutComponent>
        </IntlProvider>
      </ApolloProvider>
    );
  }
}

export default withRouter(App);
