exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._22-CL{width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}._22-CL h2{display:inline-block;font-size:1.2em;margin:0}._22-CL ._22tcn{display:inline;margin-left:10px}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"filterHeader": "_22-CL",
	"filteredParameters": "_22tcn"
};