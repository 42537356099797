const current = {
  major: 0,
  minor: 90,
  patch: 0,
};

const buildInfo =
  process.env.BUILDINFO ||
  `v${current.major}.${current.minor}.${current.patch}-develop`;

export default { current, buildInfo };
