import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Loading from 'components/Loading';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { reset } from 'redux-form';

import messages from './messages';
import EditForm from './EditForm';
import { getErrorsFromApollo } from '../../core/errors/util';
import errorMessages from '../../core/errors/messages';
import { usersQuery } from '../User/Table';
import { dateOnlyToString, stringToDateOnly } from '../../core/dateonly';

class ShortWorkEdit extends React.Component {
  static propTypes = {
    shortWorkQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      shortWork: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
        users: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            username: PropTypes.string.isRequired,
            ShortWorkUser: PropTypes.shape({
              id: PropTypes.string.isRequired,
              minWeeklyHours: PropTypes.number.isRequired,
              maxWeeklyHours: PropTypes.number.isRequired,
            }).isRequired,
          }),
        ),
      }),
    }).isRequired,
    usersQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          username: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    updateShortWork: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(formValues) {
    this.setState({ errors: [] });
    const data = {
      id: formValues.id,
      name: formValues.name,
      active: formValues.active,
      start: stringToDateOnly(formValues.start),
      end: stringToDateOnly(formValues.end),
      users: [].concat(formValues.users).map(u => ({
        userId: u.userId,
        minWeeklyHours: u.minWeeklyHours,
        maxWeeklyHours: u.maxWeeklyHours,
      })),
    };
    try {
      await this.props.updateShortWork(data);
      this.props.dispatch(reset('shortWork-edit'));
    } catch (e) {
      const errors = getErrorsFromApollo(e);
      this.setState({
        errors: this.state.errors.concat(
          errors.map(err =>
            this.props.intl.formatMessage(
              errorMessages[err.message],
              err.messageValues,
            ),
          ),
        ),
      });
    }
  }

  render() {
    const {
      shortWorkQuery: { loading, shortWork },
      usersQuery: { users },
    } = this.props;
    if (loading || !users) return <Loading />;
    if (!shortWork) return <div />;
    // build input style for form
    const userProps = shortWork.users.map(u => ({
      userId: u.id,
      minWeeklyHours: u.ShortWorkUser.minWeeklyHours,
      maxWeeklyHours: u.ShortWorkUser.maxWeeklyHours,
    }));
    const initialValues = { ...shortWork };
    // fixup dateonly fields
    initialValues.start = dateOnlyToString(shortWork.start);
    initialValues.end = dateOnlyToString(shortWork.end);
    initialValues.users = userProps;

    return (
      <div>
        <h2>
          <FormattedMessage {...messages.editHeading} />&nbsp;
          {shortWork.name}
        </h2>
        <EditForm
          shortWork={shortWork}
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
          users={users}
          errors={this.state.errors}
        />
      </div>
    );
  }
}

const shortWorkQuery = gql`
  query shortWorkQuery($id: String!) {
    shortWork(id: $id) {
      id
      name
      active
      start
      end
      users {
        id
        username
        ShortWorkUser {
          id
          minWeeklyHours
          maxWeeklyHours
        }
      }
    }
  }
`;

const updateShortWorkMutation = gql`
  mutation updateShortWorkMutation($patch: ShortWorkInput!) {
    updateShortWork(patch: $patch) {
      id
    }
  }
`;

export default compose(
  injectIntl,
  graphql(shortWorkQuery, {
    name: 'shortWorkQuery',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
  }),
  graphql(usersQuery, {
    name: 'usersQuery',
  }),
  graphql(updateShortWorkMutation, {
    props: ({ mutate }) => ({
      updateShortWork: shortWork =>
        mutate({
          variables: { patch: shortWork },
          refetchQueries: ['shortWorkQuery', 'shortWorkListQuery'],
        }),
    }),
  }),
  connect(),
)(ShortWorkEdit);
