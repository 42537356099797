import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import _ from 'lodash';
import moment from 'moment';

import s from './Filter.scss';
import messages from './messages';
import FilterForm, { messages as formMessages } from './FilterForm';
import Collapsible from '../Collapsible';
import {
  dateOnlyToString,
  stringToDateOnly,
  DATEONLY_STRING_FMT_DB,
} from '../../core/dateonly';

const WA_TIME_VIEW_FILTER = 'WA_TIME_VIEW_FILTER';

class Filter extends React.Component {
  static propTypes = {
    onFilterApply: PropTypes.func.isRequired,
    appliedValues: PropTypes.shape({
      month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      project: PropTypes.string,
      type: PropTypes.string,
      shortWork: PropTypes.string,
    }),
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
    shortWorkEntries: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
      }),
    ),
    onExport: PropTypes.func,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    appliedValues: {},
    projects: [],
    onExport: null,
    shortWorkEntries: [],
  };

  static getFilterOptions({ appliedValues, projects, shortWorkEntries, intl }) {
    const filterOptions = Object.keys(appliedValues)
      .filter(key => appliedValues[key])
      .map(key => {
        let value = appliedValues[key];
        if (key === 'project') {
          const project = projects.find(
            filterProject => filterProject.id === appliedValues[key],
          );
          if (project) value = project.name;
        }
        if (key === 'category') {
          const project = projects.find(
            filterProject => filterProject.id === appliedValues.project,
          );
          if (project) {
            const category = project.categories.find(
              filterCategory => filterCategory.id === appliedValues[key],
            );
            if (category) value = category.name;
          }
        }
        if (key === 'shortWork') {
          const shortWork = shortWorkEntries.find(
            entry => entry.id === appliedValues[key],
          );
          if (shortWork) value = shortWork.name;
          return `Short Time Work: ${value}`;
        }
        if (key === 'from' || key === 'to') {
          return `${intl.formatMessage(formMessages[key])}: ${dateOnlyToString(
            value,
          )}`;
        }
        return `${intl.formatMessage(formMessages[key])}: ${value}`;
      })
      .join(', ');

    return filterOptions;
  }

  static mapToProjectSelectValue({ appliedValues, projects }) {
    const { project, category } = appliedValues;
    if (!project) return null;
    let value = appliedValues.project;
    let projectName;
    let label;
    let isCategory = false;
    const foundProject = projects.find(
      filterProject => filterProject.id === project,
    );
    if (foundProject) label = foundProject.name;
    if (category) {
      value = `${value}_${category}`;
      isCategory = true;
      if (foundProject) {
        projectName = foundProject.name;
        const foundCat = foundProject.categories.find(
          filterCategory => filterCategory.id === category,
        );
        if (foundCat) label = foundCat.name;
      }
    }
    const projectSelectValue = {
      value,
      label,
      isCategory,
    };
    if (projectName) projectSelectValue.projectName = projectName;
    return projectSelectValue;
  }

  constructor(props) {
    super(props);

    this.state = {
      showFilters: true,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleExport = this.handleExport.bind(this);
  }

  handleSubmit(formValues) {
    const filter = { ...formValues };
    if (formValues.year) filter.year = formValues.year.value;
    if (formValues.type) filter.type = formValues.type.value;
    if (filter.project) {
      let projectAndCategory = _.get(filter, 'project.value');
      if (projectAndCategory) {
        projectAndCategory = projectAndCategory.split('_');
        filter.project = projectAndCategory[0];
        if (projectAndCategory.length === 2)
          filter.category = projectAndCategory[1];
        else delete filter.category;
      }
    }
    // shortWork
    if (formValues.shortWork) {
      filter.shortWork = formValues.shortWork.value;
    }
    // from, to
    if (filter.from) filter.from = stringToDateOnly(filter.from);
    if (filter.to) filter.to = stringToDateOnly(filter.to);

    this.props.onFilterApply({ filter });
  }

  handleReset() {
    this.props.onFilterApply({
      filter: {
        from: moment()
          .startOf('month')
          .format(DATEONLY_STRING_FMT_DB),
        to: moment()
          .endOf('month')
          .format(DATEONLY_STRING_FMT_DB),
      },
    });
  }

  handleExport(formValues, format) {
    const { onExport } = this.props;
    if (onExport) {
      onExport({ filter: formValues, format });
    }
  }

  render() {
    const {
      appliedValues,
      projects,
      onExport,
      shortWorkEntries,
      intl,
    } = this.props;
    const { showFilters } = this.state;
    const isFiltered = Object.keys(appliedValues).length > 0;
    const filterOptions = Filter.getFilterOptions({
      appliedValues,
      projects,
      shortWorkEntries,
      intl,
    });
    const filterValues = { ...appliedValues };
    const filterShortWork = shortWorkEntries.find(
      entry => entry.id === filterValues.shortWork,
    );
    const filterProject = Filter.mapToProjectSelectValue({
      projects,
      appliedValues,
    });
    if (filterProject) filterValues.project = filterProject;
    return (
      <Collapsible
        openStateKey={WA_TIME_VIEW_FILTER}
        headerContent={
          <span className={s.filterHeader}>
            <h2>
              <FormattedMessage {...messages.heading} />
            </h2>
            {isFiltered &&
              showFilters && (
                <div className={s.filteredParameters}>{filterOptions}</div>
              )}
          </span>
        }
        onToggle={open => {
          this.setState({ showFilters: !open });
        }}
      >
        <FilterForm
          initialValues={{
            ...filterValues,
            from: filterValues.from
              ? dateOnlyToString(filterValues.from)
              : null,
            to: filterValues.to ? dateOnlyToString(filterValues.to) : null,
            year:
              'year' in filterValues
                ? {
                    value: filterValues.year,
                    label: filterValues.year,
                  }
                : null,
            type:
              'type' in filterValues
                ? {
                    value: filterValues.type,
                    label: filterValues.type,
                  }
                : null,
            shortWork: filterShortWork
              ? {
                  value: filterShortWork.id,
                  label: `${filterShortWork.name}: ${dateOnlyToString(
                    filterShortWork.start,
                  )} - ${dateOnlyToString(filterShortWork.end)}`,
                }
              : null,
          }}
          shortWorkEntries={shortWorkEntries}
          projects={projects}
          onSubmit={this.handleSubmit}
          onReset={this.handleReset}
          onExport={
            onExport
              ? format => {
                  this.handleExport(appliedValues, format);
                }
              : null
          }
        />
      </Collapsible>
    );
  }
}

export default injectIntl(withStyles(s)(Filter));
