exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._39Inb tbody tr._3MkwQ:hover td{background-color:#efefef;color:#222}._39Inb tbody tr:hover td{background-color:#efefef}._39Inb tbody>tr:first-child{display:none}tr._1ZH_u td{background-color:#cddc39}tr._2AWL9 td{background-color:#4caf50}tr._3MkwQ td{color:#fff;background-color:#673ab7}tr._15A6f:hover td,tr._15A6f td{color:#fbfbfb;font-weight:700;background-color:#333!important}._2RVkZ,._73GIf{margin:0;width:200px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap}.QJ1Hb ._3ZZER{margin:0;width:7%!important}.QJ1Hb ._1dGYV{margin:0;width:15%!important}@media(max-width:1500px){._1zRb7{display:none}}._1h7wK{min-width:120px}.-dLLT{margin-right:0}.-dLLT [class*=form-group]{margin-bottom:12px}._2BejL{margin:0;border-bottom:3px solid #00dcb2;border-right:3px solid #00dcb2;background-color:rgba(0,220,178,.5);margin-bottom:10px}._2BejL h2{margin-top:12px;font-size:1.2em;display:inline-block}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "_3MkwQ",
	"vacation": "_2AWL9",
	"holiday": "_1ZH_u",
	"hoursListTable": "_39Inb",
	"separator": "_15A6f",
	"description": "_73GIf",
	"issue": "_2RVkZ",
	"managedProjectRow": "QJ1Hb",
	"smallColumn": "_3ZZER",
	"bigColumn": "_1dGYV",
	"hideOnSmall": "_1zRb7",
	"timeSpanColumn": "_1h7wK",
	"hoursList": "-dLLT",
	"listHeader": "_2BejL"
};