import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from 'react-datepicker/dist/react-datepicker.css';
import { DATEONLY_STRING_FMT } from '../../core/dateonly';

class DatePicker extends React.Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.shape({
        id: PropTypes.string,
      }),
      warning: PropTypes.bool,
    }).isRequired,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    placeholder: '',
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    if (moment(date, DATEONLY_STRING_FMT).isValid()) {
      this.props.input.onChange(moment(date).format(DATEONLY_STRING_FMT));
    } else {
      this.props.input.onChange('');
    }
  }

  render() {
    const {
      input,
      placeholder,
      meta: { touched, error, warning },
      ...rest
    } = this.props;

    return (
      <div>
        <ReactDatePicker
          {...rest}
          className="form-control"
          placeholder={placeholder}
          dateFormat="DD.MM.YYYY"
          locale="de-DE"
          selected={
            input.value ? moment(input.value, DATEONLY_STRING_FMT) : null
          }
          onChange={this.handleChange}
        />
        {touched &&
          ((error && <p className="bg-danger">{error}</p>) ||
            (warning && <p className="bg-warning">{warning}</p>))}
      </div>
    );
  }
}

export default withStyles(s)(DatePicker);
