import { defineMessages } from 'react-intl';

const messages = defineMessages({
  username: {
    id: 'user.username',
    defaultMessage: 'Username',
    description: 'Label for username in user',
  },
  startDate: {
    id: 'user.startDate',
    defaultMessage: 'Start Date',
    description: 'Label for users first work day',
  },
  weeklyHours: {
    id: 'user.weeklyHours',
    defaultMessage: 'Weekly Hours',
    description: 'Label for weekly hours in user',
  },
  active: {
    id: 'user.active',
    defaultMessage: 'active',
    description: 'Label for active flag',
  },
  inactive: {
    id: 'user.inactive',
    defaultMessage: 'inactive',
    description: 'Label for inactive flag',
  },
  all: {
    id: 'user.all',
    defaultMessage: 'all',
    description: 'Label for all users flag',
  },
  addUser: {
    id: 'user.addUser',
    defaultMessage: 'User',
    description: 'Label for add user button',
  },
});

export const createUserFormMessages = defineMessages({
  heading: {
    id: 'newUser.heading',
    defaultMessage: 'Add user',
    description: 'Heading for new user view',
  },
  email: {
    id: 'newUser.email',
    defaultMessage: 'Email',
    description: 'Label for email address of create user',
  },
  submit: {
    id: 'newUser.submit',
    defaultMessage: 'Create User',
    description: 'Submit button for create user',
  },
  userAlreadyExists: {
    id: 'newUser.userAlreadyExists',
    defaultMessage: 'Username already exists!',
    description: 'Error message if a username already exists',
  },
});

export default messages;
