import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Link } from 'react-router-dom';

import s from './LoginForm.scss'; // eslint-disable-line css-modules/no-unused-class
import render from '../../ReduxForm/renderField';
import validations from '../../ReduxForm/validations';
import validationMessages from '../../ReduxForm/messages';
import authMessages from '../messages';
import messages from './messages';

const validate = values => {
  const errors = {};
  if (!validations.required(values.username)) {
    errors.username = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.required(values.password)) {
    errors.password = <FormattedMessage {...validationMessages.required} />;
  }

  return errors;
};

class LoginForm extends React.Component {
  static propTypes = {
    provider: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  render() {
    const { errors, intl, handleSubmit } = this.props;
    const { provider } = this.props;
    let infoMessage = null;
    let usernameMessage = null;
    const submitIcon = null;
    let submitMessage = null;
    let submitButtonStyle = null;
    let resetPassword = null;
    infoMessage = messages.infoLocal;
    usernameMessage = authMessages.username;
    submitMessage = messages.submitLocal;
    submitButtonStyle = s.localLoginButton;
    resetPassword = (
      <Link to="/password/reset" className={s.links}>
        <FormattedMessage {...messages.passwordReset} />
      </Link>
    );
    return (
      <div className={s.loginForm}>
        <h1 style={{ margin: '0 0 5px 0' }}>
          <FormattedMessage {...messages.heading} />
        </h1>
        <span className={s.subline}>
          <FormattedMessage {...infoMessage} />
        </span>
        <form
          className="default-form"
          action="/login"
          method="POST"
          onSubmit={handleSubmit}
        >
          <fieldset>
            <Field
              id="username"
              name="username"
              type="text"
              label={intl.formatMessage(usernameMessage)}
              component={render.renderInput}
              autoFocus
            />
            <Field
              id="password"
              name="password"
              type="password"
              label={intl.formatMessage(authMessages.password)}
              component={render.renderInput}
            />
            {errors.length === 0 ? null : (
              <ul style={{ padding: '0', listStyleType: 'none' }}>
                {errors.map(err => (
                  <li key={err} className="bg-danger">
                    {err}
                  </li>
                ))}
              </ul>
            )}
            <div className="btn-group btn-group-justified">
              <div className="btn-group">
                <button
                  type="submit"
                  className={`btn btn-primary ${submitButtonStyle}`}
                >
                  {submitIcon}
                  <FormattedMessage {...submitMessage} values={{ provider }} />
                </button>
              </div>
            </div>
          </fieldset>
          {resetPassword}
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'login',
  validate,
  pure: false, // this is necessary to trigger form re-renders if the locale changes
})(injectIntl(withStyles(s)(LoginForm)));
