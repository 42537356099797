exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "body{background-color:#fbfbfb}._2NBQU{margin-top:50px;overflow-x:hidden;height:100%;max-width:480px}@media(max-width:1500px){._2NBQU{margin-top:10px}}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"resetPasswordContainer": "_2NBQU"
};