import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'issueManagement.heading.title',
    defaultMessage: 'Title',
    description: 'Heading for Name column in table',
  },
  username: {
    id: 'issueManagement.heading.username',
    defaultMessage: 'Username',
    description: 'Heading for Username column in table',
  },
  state: {
    id: 'issueManagement.heading.state',
    defaultMessage: 'State',
    description: 'Heading for State column in table',
  },
  labels: {
    id: 'issueManagement.heading.labels',
    defaultMessage: 'Labels',
    description: 'Heading for Labels column in table',
  },
  desc: {
    id: 'issueManagement.heading.description',
    defaultMessage: 'Description',
    description: 'Heading for Description column in table',
  },
  created: {
    id: 'issueManagement.heading.createdAt',
    defaultMessage: 'Created at',
    description: 'Heading for Created column in table',
  },
  due: {
    id: 'issueManagement.heading.due',
    defaultMessage: 'Due Date',
    description: 'Heading for Due column in table',
  },
  totalTime: {
    id: 'issueManagement.heading.totalTime',
    defaultMessage: 'Time spent | Time estimate',
    description: 'Heading for Total time column in table',
  },
  link: {
    id: 'issueManagement.heading.link',
    defaultMessage: 'Link',
    description: 'Heading for Link column in table',
  },
  mergeRequest: {
    id: 'issueManagement.heading.mergeRequest',
    defaultMessage: 'Mergerequests',
    description: 'Heading for Mergerequests column in table',
  },
  mileStone: {
    id: 'issueManagement.heading.mileStone',
    defaultMessage: 'Milestone',
    description: 'Heading for Milestone column in table',
  },
});

export default messages;
