import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Dropdown, Glyphicon, MenuItem } from 'react-bootstrap';
import qs from 'qs';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Loading from 'components/Loading';
import ManagedProjectList from 'components/HoursList/ManagedProjectList';
import TotalFilteredHours from 'components/Filter/TotalFilteredHours';
import Filter from './Filter';
import layoutStyle from '../../../styles/base/layout.scss';
import {
  DATEONLY_STRING_FMT_DB,
  stringToDateOnlyDB,
} from '../../../core/dateonly';
import NotFound from '../../NotFound';

const DropdownMenu = Dropdown.Menu;
const DropdownToggle = Dropdown.Toggle;

class ManagedProjectView extends React.Component {
  static propTypes = {
    managedProjectQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      managedProject: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.applyFilters = this.applyFilters.bind(this);
    this.handleExport = this.handleExport.bind(this);

    this.state = {
      filter: {
        from: moment()
          .startOf('month')
          .format(DATEONLY_STRING_FMT_DB),
        to: moment()
          .endOf('month')
          .format(DATEONLY_STRING_FMT_DB),
      },
    };
  }

  async applyFilters({ filter }) {
    // force filter to current project
    const filterParams = {
      ...filter,
    };

    this.setState({ filter: filterParams });
    this.props.managedProjectQuery.refetch({
      filter: filterParams,
    });
  }

  handleExport(format) {
    const { match: { params: { id } } } = this.props;
    const filter = qs.stringify({ filter: this.state.filter });
    const exportTab = window.open('', '_blank');
    if (exportTab) {
      const exportUrl = `/export/managedProject/${id}?${filter}&format=${format}`;
      exportTab.location.href = exportUrl;
      exportTab.focus();
    }
  }

  render() {
    const { managedProjectQuery: { loading, managedProject } } = this.props;
    const { filter } = this.state;
    if (loading) return <Loading />;
    const hoursEntries =
      managedProject && managedProject.hoursEntries
        ? managedProject.hoursEntries
        : [];

    const groupedHourEntries = _.groupBy(
      hoursEntries,
      hourEntry => hourEntry.date,
    );

    const exportFormats = [
      {
        label: 'PDF',
        value: 'pdf',
      },
      {
        label: 'XLSX',
        value: 'xlsx',
      },
    ];
    const formats = exportFormats.map(fmt => (
      <MenuItem key={fmt.value} eventKey={fmt.value}>
        {fmt.label}
      </MenuItem>
    ));
    if (!managedProject) {
      return <NotFound />;
    }
    return (
      <div>
        <div className="h3">{managedProject.name}</div>
        <Row className={layoutStyle.noMargin} style={{ marginTop: '15px' }}>
          <Col xs={12} className={layoutStyle.noPadding}>
            <Filter onFilterApply={this.applyFilters} appliedValues={filter} />
          </Col>
          <Col xs={12} className={layoutStyle.noPadding}>
            <TotalFilteredHours filter={filter} hoursEntries={hoursEntries} />
            {Object.keys(filter).length > 0 ? (
              <div style={{ paddingBottom: '15px' }} />
            ) : (
              <div />
            )}
          </Col>
          <Col xs={12} className={layoutStyle.noPadding}>
            <Dropdown
              id="exportDropdown"
              onSelect={value => this.handleExport(value)}
            >
              <DropdownToggle className="btn dropdown-toggle">
                <Glyphicon glyph="export" />
                Export
              </DropdownToggle>
              <DropdownMenu>{formats}</DropdownMenu>
            </Dropdown>
          </Col>
          <Col xs={12} className={layoutStyle.noPadding}>
            {Object.keys(groupedHourEntries)
              .sort((a, b) => {
                const dayA = stringToDateOnlyDB(a);
                const dayB = stringToDateOnlyDB(b);
                return dayA > dayB ? -1 : 0;
              })
              .map(key => {
                const hoursEntriesList = groupedHourEntries[key];
                return key ? (
                  <div key={key}>
                    <ManagedProjectList
                      hoursEntries={hoursEntriesList}
                      filter={filter}
                    />
                  </div>
                ) : null;
              })}
          </Col>
        </Row>
      </div>
    );
  }
}

const managedProjectQuery = gql`
  query managedProject($id: String!, $filter: HoursEntryFilter) {
    managedProject(id: $id) {
      id
      name
      managedProjectPositions {
        id
        name
        includeAllCategories
        includeHoursBookedOnProject
        projectId
        managedProjectPositionCategories {
          id
          name
        }
      }
      hoursEntries(filter: $filter) {
        id
        date
        timeFrom
        hours
        type
        user {
          id
          username
        }
        project {
          id
          name
        }
        issue {
          id
          ticketId
          title
        }
        category {
          id
          name
        }
        description
      }
    }
  }
`;

export default compose(
  graphql(managedProjectQuery, {
    name: 'managedProjectQuery',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
        filter: {
          from: moment()
            .startOf('month')
            .format(DATEONLY_STRING_FMT_DB),
          to: moment()
            .endOf('month')
            .format(DATEONLY_STRING_FMT_DB),
        },
      },
    }),
  }),
)(withStyles(layoutStyle)(ManagedProjectView));
