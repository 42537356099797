exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3au2V>tbody>tr>td{padding-top:10px}._3au2V{table-layout:fixed}.BWGLQ{padding-right:10px;background-color:#efefef}._6HRj_,._25HiA{padding-right:10px;padding-left:10px;border-bottom:1px #fff;width:400px!important}._25HiA{vertical-align:middle!important}._6HRj_>div{margin:0}._6HRj_ input{border:1px solid #b4b4b4;margin:0}._1bC7U{margin-right:10px;padding:6.5px 12px}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"projectPositionTable": "_3au2V",
	"projectPosition": "BWGLQ",
	"projectPositionField": "_6HRj_",
	"projectPositionFieldCheckBox": "_25HiA",
	"trash": "_1bC7U"
};