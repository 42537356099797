import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, ButtonToolbar } from 'react-bootstrap';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { FaTrash } from 'react-icons/fa';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import _ from 'lodash';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import formMessages from 'components/ReduxForm/messages';
import render from 'components/ReduxForm/renderField';
import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from './EditForm.scss';
import validations from '../ReduxForm/validations';
import tableMessages from './messages';

const validate = values => {
  const errors = {};
  if (!validations.required(values.name)) {
    errors.name = <FormattedMessage {...formMessages.required} />;
  }
  if (!validations.required(values.start)) {
    errors.start = <FormattedMessage {...formMessages.required} />;
  }
  if (!validations.required(values.end)) {
    errors.end = <FormattedMessage {...formMessages.required} />;
  }
  if (values.users && values.users.length) {
    const usedUserIds = {};
    const userArrayErrors = [];
    values.users.forEach((u, index) => {
      const userErrors = {};
      if (usedUserIds[u.userId]) {
        userErrors.userId = (
          <FormattedMessage {...tableMessages.duplicateUserId} />
        );
        userArrayErrors[index] = userErrors;
      }
      usedUserIds[u.userId] = true;
      const minWeeklyHours = parseFloat(u.minWeeklyHours, 10);
      const maxWeeklyHours = parseFloat(u.maxWeeklyHours, 10);
      if (!validations.required(minWeeklyHours) && minWeeklyHours !== 0) {
        userErrors.minWeeklyHours = (
          <FormattedMessage {...formMessages.required} />
        );
        userArrayErrors[index] = userErrors;
      }
      if (!validations.required(maxWeeklyHours) && maxWeeklyHours !== 0) {
        userErrors.maxWeeklyHours = (
          <FormattedMessage {...formMessages.required} />
        );
        userArrayErrors[index] = userErrors;
      }
      if (maxWeeklyHours < minWeeklyHours) {
        userErrors.maxWeeklyHours = (
          <FormattedMessage {...tableMessages.maxWeeklyHoursError} />
        );
        userArrayErrors[index] = userErrors;
      }
    });
    errors.users = userArrayErrors;
  }
  return errors;
};

const messages = defineMessages({
  shortWork: {
    id: 'shortWorkEdit.shortWork',
    defaultMessage: 'ShortWork',
    description: 'Label for shortWork field in edit form',
  },
  userName: {
    id: 'shortWorkEdit.userName',
    defaultMessage: 'User Name',
    description: 'Label for user name heading',
  },
  minWeeklyHours: {
    id: 'shortWorkEdit.minWeeklyHours',
    defaultMessage: 'Minimum Weekly Hours',
    description: 'Label for minWeeklyHours field in edit form',
  },
  maxWeeklyHours: {
    id: 'shortWorkEdit.maxWeeklyHours',
    defaultMessage: 'Maximum Weekly Hours',
    description: 'Label for maxWeeklyHours field in edit form',
  },
  submit: {
    id: 'shortWorkEdit.submit',
    defaultMessage: 'Apply',
    description: 'Submit button text in editForm form',
  },
  actions: {
    id: 'shortWorkEdit.actions',
    defaultMessage: 'Actions',
    description: 'Label for actions in editForm form',
  },
});

class EditForm extends React.Component {
  static propTypes = {
    shortWork: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired,
      users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          username: PropTypes.string.isRequired,
          minWeeklyHours: PropTypes.number.isRequired,
          maxWeeklyHours: PropTypes.number.isRequired,
        }),
      ).isRequired,
    }).isRequired,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
      }),
    ).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    errors: [],
  };

  static renderShortWorkUsers({ fields, meta: { error }, disabled, users }) {
    const userOptions = users.map(u => (
      <option value={u.id}>{u.username}</option>
    ));
    return (
      <table className={`table table-striped ${s.shortWorkUserTable}`}>
        <thead>
          <tr>
            <th>
              <FormattedMessage {...messages.userName} />
            </th>
            <th>
              <FormattedMessage {...messages.minWeeklyHours} />
            </th>
            <th>
              <FormattedMessage {...messages.maxWeeklyHours} />
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {fields.map((user, index) => (
            <tr key={user} className={s.shortWorkUser}>
              <td className={s.shortWorkUserField}>
                <Field
                  id={`${user}.userId`}
                  name={`${user}.userId`}
                  component={render.renderSelect}
                  disabled={disabled}
                >
                  {/* {index === lastUserIndex ? userOptions : userOption(user)} */}
                  {userOptions}
                </Field>
              </td>
              <td className={s.shortWorkUserField}>
                <Field
                  id={`${user}.minWeeklyHours`}
                  name={`${user}.minWeeklyHours`}
                  component={render.renderNumber}
                  disabled={disabled}
                />
              </td>
              <td className={s.shortWorkUserField}>
                <Field
                  id={`${user}.maxWeeklyHours`}
                  name={`${user}.maxWeeklyHours`}
                  component={render.renderNumber}
                  disabled={disabled}
                />
              </td>
              <td>
                <button
                  type="button"
                  onClick={() => fields.remove(index)}
                  className={`btn btn-primary ${s.trash}`}
                  disabled={disabled}
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
          {error && <tr className="error">{error}</tr>}
          <tr>
            <td colSpan="4">
              <hr />
              <button
                type="button"
                className="btn btn-green"
                onClick={() => fields.push({ userId: users[0].id })}
                disabled={disabled}
              >
                <i className="fa fa-plus fa--prepended" />
                <FormattedMessage {...formMessages.add} />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  render() {
    const { errors, users } = this.props;
    const isNew = _.isEmpty(this.props.shortWork);
    return (
      <form onSubmit={this.props.handleSubmit}>
        <fieldset>
          <Row className={layoutStyle.noMargin}>
            <Col xs={12} md={10} style={{ paddingLeft: '0px' }}>
              <Field
                id="name"
                name="name"
                label={<FormattedMessage {...tableMessages.name} />}
                component={render.renderInput}
                disabled={!isNew}
              />
            </Col>
            <Col xs={12} md={2} style={{ paddingLeft: '0px' }}>
              <Field
                id="active"
                name="active"
                label={<FormattedMessage {...tableMessages.active} />}
                component={render.renderCheckbox}
                disabled={!isNew}
              />
            </Col>
            <Col xs={12} md={3} style={{ paddingLeft: '0px' }}>
              <Field
                id="start"
                name="start"
                label={<FormattedMessage {...tableMessages.start} />}
                component={render.renderDate}
              />
            </Col>
            <Col xs={12} md={3} style={{ paddingLeft: '0px' }}>
              <Field
                id="end"
                name="end"
                label={<FormattedMessage {...tableMessages.end} />}
                component={render.renderDate}
              />
            </Col>
          </Row>
          <hr />
          <FieldArray
            name="users"
            component={EditForm.renderShortWorkUsers}
            props={{ users }}
          />
          {errors.length === 0 ? null : (
            <ul style={{ padding: '0', listStyleType: 'none' }}>
              {errors.map(err => (
                <li key={err} className="bg-danger">
                  {err}
                </li>
              ))}
            </ul>
          )}
          <hr />
          <ButtonToolbar>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={this.props.submitting}
            >
              <FormattedMessage {...formMessages.submit} />
            </button>
          </ButtonToolbar>
        </fieldset>
      </form>
    );
  }
}

export default reduxForm({
  form: 'shortWork-edit',
  validate,
  enableReinitialize: true,
})(injectIntl(withStyles(s, layoutStyle)(EditForm)));
