import { defineMessages } from 'react-intl';

const messages = defineMessages({
  nameHeading: {
    id: 'managedProject.heading.name',
    defaultMessage: 'Name',
    description: 'Heading for Name column in table',
  },
  projectHeading: {
    id: 'managedProject.heading.project',
    defaultMessage: 'Project positions',
    description: 'Heading for projects column in table',
  },
  totalHoursOfferedHeading: {
    id: 'managedProject.heading.totalHoursOffered',
    defaultMessage: 'Total Hours Offered',
    description: 'Heading for Total Hours Offered column in table',
  },
  hourlyRateHeading: {
    id: 'managedProject.heading.hourlyRateHeading',
    defaultMessage: 'Hourly rate',
    description: 'Heading for Hourly rate column in table',
  },
  offeredPriceHeading: {
    id: 'managedProject.heading.offeredPriceHeading',
    defaultMessage: 'Offered price',
    description: 'Heading for Offered price column in table',
  },
  deadlineHeading: {
    id: 'managedProject.heading.deadline',
    defaultMessage: 'Deadline',
    description: 'Heading for Deadline column in table',
  },
  categoryHeading: {
    id: 'managedProject.heading.categories',
    defaultMessage: 'Project positions categories',
    description: 'Heading for Categories column in table',
  },
  activeHeading: {
    id: 'managedProject.heading.isActive',
    defaultMessage: 'Active',
    description: 'Heading for Active column in table',
  },
  projectCategorySelectHeading: {
    id: 'managedProject.heading.projectCategoryHeading',
    defaultMessage: 'Project Categories',
    description: 'Heading for Active column in table',
  },
  projectSelectHeading: {
    id: 'managedProject.heading.projectSelectHeading',
    defaultMessage: 'Projects',
    description: 'Heading for Active column in table',
  },
  projectSelectPositionHeading: {
    id: 'managedProject.heading.projectSelectPositionHeading',
    defaultMessage: 'Project',
    description: 'Heading for Active column in table',
  },
  projectCategorySelect: {
    id: 'managedProject.heading.projectCategorySelect',
    defaultMessage: 'Select project category',
    description: 'Heading for Active column in table',
  },
  projectSelect: {
    id: 'managedProject.heading.projectSelect',
    defaultMessage: 'Select project',
    description: 'Heading for Active column in table',
  },
  editManagedProject: {
    id: 'managedProject.editManagedProject',
    defaultMessage: 'Edit managed Project',
    description: 'Label for Edit ManagedProject button',
  },
  createManagedProject: {
    id: 'managedProject.createManagedProject',
    defaultMessage: 'Create managed Project',
    description: 'Label for Create ManagedProject button',
  },
  includeAll: {
    id: 'managedProject.includeAll',
    defaultMessage: 'Include all Project categories',
    description: 'Label for includeAll ManagedProject checkbox',
  },
  includeHoursBookedOnProject: {
    id: 'managedProject.includeHoursBookedOnProject',
    defaultMessage: 'Include hours booked on project',
    description:
      'Label for includeHoursBookedOnProject ManagedProject checkbox ',
  },
  submit: {
    id: 'editForm.submit',
    defaultMessage: 'Apply',
    description: 'Submit button text in editForm form',
  },
  noCategory: {
    id: 'editForm.noCategory',
    defaultMessage: 'No Categories available',
    description: 'Submit button text in editForm form',
  },
  filterHeading: {
    id: 'filter.heading',
    defaultMessage: 'Filter',
    description: 'Heading for filter',
  },
});

export default messages;
