exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2b5Q9{position:fixed;top:100px;left:0;width:230px;z-index:1;height:100%;background-color:#fff;margin:0;padding:5px 0 0}@media(max-width:1200px){._2b5Q9{top:75px;display:none!important}}._2b5Q9 .OiqKG{padding-top:5px;padding-bottom:5px}._2b5Q9 ._25-XX,._2b5Q9 .OiqKG,._2b5Q9 .Z5LJ1{background-color:#fff;color:#333;border:none!important}._2b5Q9 ._25-XX,._2b5Q9 .Z5LJ1{margin:0;padding:0}._2b5Q9 ._25-XX{display:block;padding:10px;border-left:4px solid #fff!important}._2b5Q9 ._25-XX svg{margin-bottom:3px;vertical-align:middle}._2b5Q9 ._25-XX a{color:#333!important}._2b5Q9 .WeVqP{display:block;padding:10px;background-color:#efefef;border-left:4px solid #00dcb2!important}._2b5Q9 .WeVqP svg{margin-bottom:3px;vertical-align:middle}._2b5Q9 .WeVqP a{color:#333!important}._2b5Q9 ._25-XX:hover,._2b5Q9 .WeVqP:hover{background-color:#efefef;border-left:4px solid #00dcb2!important}._2b5Q9 ._2jdu3{margin-top:0;padding-left:15px;padding-top:5px;padding-bottom:5px;background-color:#fff;color:#333;border:none!important}._2b5Q9 ._2jdu3 ._2wFPY{display:block;padding:10px;border-left:4px solid #fff!important}._2b5Q9 ._2jdu3 ._2wFPY svg{margin-bottom:3px;vertical-align:middle}._2b5Q9 ._2jdu3 ._2wFPY a{color:#333!important}._3qcAm{float:right}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"sideNav": "_2b5Q9",
	"navPanel": "OiqKG",
	"navHeading": "Z5LJ1",
	"navTitle": "_25-XX",
	"navTitleSelected": "WeVqP",
	"navBody": "_2jdu3",
	"subNav": "_2wFPY",
	"iconRight": "_3qcAm"
};