import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './EmployeeBalanceTable.scss';
import messages from './messages';

class EmployeeBalance extends React.Component {
  static propTypes = {
    total: PropTypes.number.isRequired,
    done: PropTypes.number.isRequired,
    balance: PropTypes.number.isRequired,
  };

  render() {
    const { total, done, balance } = this.props;

    return (
      <table className={`table table-striped ${s.employeeBalanceTable}`}>
        <thead>
          <tr>
            <th>
              <FormattedMessage {...messages.total} />
            </th>
            <th>
              <FormattedMessage {...messages.done} />
            </th>
            <th>
              <FormattedMessage {...messages.balance} />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{total.toFixed(2)}</td>
            <td>{done.toFixed(2)}</td>
            <td>
              {balance > 0 ? `+${balance.toFixed(2)}` : balance.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default withStyles(s)(EmployeeBalance);
