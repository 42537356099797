exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1cFKi h1{font-size:2rem;margin-top:150px;text-transform:uppercase}@media(max-width:768px){._1cFKi h1{margin-top:30px}}@media(max-height:480px){._1cFKi h1{margin-top:30px}}._1cFKi fieldset{margin-top:-10px}._1cFKi .a1OxH{font-size:1.8rem;display:block;font-weight:400;line-height:normal}@media(max-width:480px){._1cFKi .a1OxH{font-size:1.4rem}}._1cFKi ._3Ajop{letter-spacing:-1px;font-size:1.3rem;font-weight:400;display:table;margin:5px 0;color:#00dcb2}._11bkR{background-color:#00dcb2;border-color:#00dcb2}._11bkR:active,._11bkR:focus,._11bkR:hover{color:#00dcb2;border-color:#00dcb2}._2P74O{background-color:#ff4e32;border-color:#ff4e32}._2P74O:active,._2P74O:focus,._2P74O:hover{color:#ff4e32;border-color:#ff4e32}._1Lx_T{background-color:#3f51b5;border-color:#3f51b5}._1Lx_T:active,._1Lx_T:focus,._1Lx_T:hover{color:#3f51b5;border-color:#3f51b5}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"orange": "#ff4e32",
	"sick": "#673ab7",
	"vacation": "#cddc39",
	"holiday": "#4caf50",
	"loginForm": "_1cFKi",
	"subline": "a1OxH",
	"links": "_3Ajop",
	"localLoginButton": "_11bkR",
	"gitlabLoginButton": "_2P74O",
	"atlassianLoginButton": "_1Lx_T"
};