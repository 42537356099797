import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';
import { connect } from 'react-redux';

import messages from './messages';
import EditForm from './EditForm';
import { getErrorsFromApollo } from '../../../core/errors/util';
import errorMessages from '../../../core/errors/messages';
import Loading from '../../Loading';

class WorkCategoryNew extends React.Component {
  static propTypes = {
    createWorkCategory: PropTypes.func.isRequired,
    workCategoriesQuery: PropTypes.shape({
      loading: PropTypes.bool,
      workCategories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          isActive: PropTypes.bool,
          name: PropTypes.string,
          color: PropTypes.string,
          description: PropTypes.string,
        }),
      ),
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(formValues) {
    await this.setState({ errors: [] });
    const payload = _.omit(formValues, ['__typename']);
    try {
      const result = await this.props.createWorkCategory(payload);
      const id = _.get(result, 'data.createWorkCategory.id');
      this.props.history.push({ pathname: `${id}/edit` });
    } catch (e) {
      const errors = getErrorsFromApollo(e);
      this.setState({
        errors: this.state.errors.concat(
          errors.map(err =>
            this.props.intl.formatMessage(
              errorMessages[err.message],
              err.messageValues,
            ),
          ),
        ),
      });
    }
  }

  render() {
    if (this.props.workCategoriesQuery.loading) return <Loading />;

    return (
      <div>
        <h2>
          <FormattedMessage {...messages.createWorkCategory} />
        </h2>
        <EditForm
          workCategory={{}}
          onSubmit={this.handleSubmit}
          errors={this.state.errors}
        />
      </div>
    );
  }
}

export const createWorkCategory = gql`
  mutation createWorkCategory($workCategory: CreateWorkCategoryInput!) {
    createWorkCategory(workCategory: $workCategory) {
      id
      name
      description
      color
      isActive
    }
  }
`;

export const workCategoriesQuery = gql`
  query workCategories {
    workCategories(showInactive: true) {
      id
      name
      isActive
      isDefault
      description
      color
    }
  }
`;

export default compose(
  injectIntl,
  graphql(createWorkCategory, {
    props: ({ mutate }) => ({
      createWorkCategory: workCategory =>
        mutate({
          variables: { workCategory },
          update: (proxy, data) => {
            const newWorkCategory = data.data
              ? data.data.createWorkCategory
              : null;

            if (newWorkCategory) {
              const workCategoriesData = proxy.readQuery({
                query: workCategoriesQuery,
              });
              workCategoriesData.workCategories.push({
                ...newWorkCategory,
              });
              workCategoriesData.workCategories = workCategoriesData.workCategories.sort(
                (a, b) => (a.name < b.name ? -1 : 1),
              );
              proxy.writeQuery({
                query: workCategoriesQuery,
                data: workCategoriesData,
              });
            }
          },
        }),
    }),
  }),
  graphql(workCategoriesQuery, {
    name: 'workCategoriesQuery',
  }),
  connect(),
)(WorkCategoryNew);
