import { defineMessages } from 'react-intl';

const messages = defineMessages({
  dashboard: {
    id: 'sidebar.dashboard',
    defaultMessage: 'Dashboard',
    description: 'Label for dashboard link in sidebar',
  },
  hours: {
    id: 'sidebar.hours',
    defaultMessage: 'Hours',
    description: 'Label for hours link in sidebar',
  },
  account: {
    id: 'sidebar.account',
    defaultMessage: 'Account',
    description: 'Label for account link in sidebar',
  },
  users: {
    id: 'sidebar.users',
    defaultMessage: 'Users',
    description: 'Label for users link in sidebar',
  },
  shortWork: {
    id: 'sidebar.shortWork',
    defaultMessage: 'Short Work',
    description: 'Label for short work link in sidebar',
  },
  projects: {
    id: 'sidebar.projects',
    defaultMessage: 'Projects',
    description: 'Label for projects link in sidebar',
  },
  controlling: {
    id: 'sidebar.controlling',
    defaultMessage: 'Controlling',
    description: 'Label for controlling link in sidebar',
  },
  projectManagement: {
    id: 'sidebar.projectManagement',
    defaultMessage: 'Project Management',
    description: 'Label for projectManagement link in sidebar',
  },
  issueManagement: {
    id: 'sidebar.issueManagement',
    defaultMessage: 'Issue Management',
    description: 'Label for issueManagement link in sidebar',
  },
  changePassword: {
    id: 'nav.changePassword',
    defaultMessage: 'Change Password',
    description: 'Label for change password redirect in top navigation menu',
  },
  workCategoryManagement: {
    id: 'nav.workCategoryManagement',
    defaultMessage: 'Work Categories',
    description: 'Label for workCategoryManagement link in sidebar',
  },
});

export default messages;
