import React from 'react';
import PropTypes from 'prop-types';
import { FaDotCircle } from 'react-icons/fa';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Navbar,
  SplitButton,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
} from 'react-bootstrap';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import Loading from 'components/Loading';
import logo from 'components/Auth/login/clock.svg';
import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import { withLogout } from 'components/Auth/withLogout';
import showNav from './navPermissions';
import s from './TopNavigation.scss'; // eslint-disable-line css-modules/no-unused-class
import navTree from './navTree';
import messages from './messages';
import LanguageSwitcher from '../LanguageSwitcher';

class TopNavigation extends React.Component {
  static propTypes = {
    oAuthProviderQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      currentOAuthProvider: PropTypes.string,
    }),
    showWorkCategory: PropTypes.bool.isRequired,
    me: PropTypes.shape({
      username: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    logout: PropTypes.func.isRequired,
  };

  static defaultProps = {
    oAuthProviderQuery: {},
  };

  handleSelect(eventKey) {
    switch (eventKey) {
      case 2.0:
        this.props.history.push('/password/change');
        break;
      case 2.1:
        this.props.logout();
        break;
      default:
    }
  }

  render() {
    const {
      me: { username, profile },
      oAuthProviderQuery: { currentOAuthProvider, loading },
      showWorkCategory,
    } = this.props;
    if (loading) return <Loading />;
    const showChangePassword = currentOAuthProvider === 'local';
    const dropdownDisplay =
      profile && profile.avatarUrl
        ? [
            <img
              key="avatar"
              src={profile.avatarUrl}
              style={{ height: 25 }}
              alt=""
            />,
            ' ',
            username,
          ]
        : [username];
    return (
      <Navbar
        className={s.navbar}
        fixedTop
        fluid
        collapseOnSelect
        onSelect={k => this.handleSelect(k)}
      >
        <Navbar.Header>
          <Navbar.Brand className={s.logoContainer}>
            <Link to="/admin/dashboard">
              <div className={`${s.logoIcon} ${s.hideMobile}`}>
                <img src={logo} alt="" />
                <span>Waltzing Time</span>
              </div>
              <div className={`${s.logoIcon} ${s.showMobile}`}>
                <img src={logo} alt="" />
              </div>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse className={s.navbarCollapse}>
          <Nav className={s.showMobile}>
            {navTree(showWorkCategory).map((nav, index) => {
              const { mainNav, subNavs } = nav;

              const showMainNav = showNav(
                mainNav,
                currentOAuthProvider,
                this.props.me,
              );

              if (
                !showMainNav ||
                (mainNav.oAuthProvider &&
                  mainNav.oAuthProvider !== currentOAuthProvider)
              )
                return [];

              return nav.subNavs.length > 1 ? ( // only render sub panel if it contains more than 1 entry
                <div
                  className={s.splitButtonDropDown}
                  key={mainNav.name.defaultMessage}
                >
                  <SplitButton
                    title={<FormattedMessage {...mainNav.name} />}
                    href={showMainNav ? mainNav.path : '#'}
                    id={index} // eslint-disable-line react/no-array-index-key
                  >
                    {subNavs
                      .filter(subNav =>
                        showNav(subNav, currentOAuthProvider, this.props.me),
                      )
                      .map((subNav, subIndex) => (
                        <MenuItem
                          key={`${subIndex}-${subNav.name}`} // eslint-disable-line react/no-array-index-key
                          className={s.subNav}
                          href={subNav.path}
                        >
                          <FaDotCircle className="fa--prepended" />{' '}
                          <FormattedMessage {...subNav.name} />
                        </MenuItem>
                      ))}
                  </SplitButton>
                </div>
              ) : (
                <NavItem
                  className={s.navDropDown}
                  key={index} // eslint-disable-line react/no-array-index-key
                  href={showMainNav ? mainNav.path : '#'}
                >
                  <FormattedMessage {...nav.name} />
                </NavItem>
              );
            })}
          </Nav>
          <Nav pullRight>
            <NavDropdown
              className={s.navDropDown}
              eventKey={2}
              title={dropdownDisplay}
              id="nav-dropdown"
            >
              {showChangePassword && (
                <MenuItem eventKey={2.0} key="changePassword">
                  <FormattedMessage {...messages.changePassword} />
                </MenuItem>
              )}
              <MenuItem eventKey={2.1} key="logout">
                Logout
              </MenuItem>
              <LanguageSwitcher />
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

const oAuthProviderQuery = gql`
  query oAuthProviderQuery {
    currentOAuthProvider
  }
`;

export default compose(
  injectIntl,
  graphql(oAuthProviderQuery, {
    name: 'oAuthProviderQuery',
  }),
  AuthenticationWrapper,
  withLogout,
  withStyles(s),
)(TopNavigation);
