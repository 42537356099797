import React from 'react';
import PropTypes from 'prop-types';
import reactTableStyle from 'react-table/react-table.css';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import Pagination from 'rc-pagination';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import Loader from 'react-spinners/FadeLoader';
// eslint-disable-next-line css-modules/no-unused-class
import s from './IssueList.scss';

const gitlabUserQuery = gql`
  query GitlabUser($userId: Int, $filter: IssueFilter) {
    gitlabUser(userId: $userId) {
      id
      name
      avatar_url
      gitlabIssues(filter: $filter) {
        issues {
          id
          title
          state
          labels
          web_url
          due_date
          description
          created_at
          references {
            short
          }
          milestone {
            title
            web_url
          }
          mergeRequests {
            web_url
            references {
              short
            }
          }
          time {
            human_total_time_spent
            human_time_estimate
          }
        }
        pagination {
          currentPage
          prevPage
          pageSize
          lastPage
          totalItems
        }
      }
    }
  }
`;

class Issues extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    gitlabUserQuery: PropTypes.shape({
      refetch: PropTypes.func,
      gitlabUser: PropTypes.shape({
        username: PropTypes.string,
        name: PropTypes.string,
        state: PropTypes.string,
        avatar_url: PropTypes.string,
        gitlabIssues: PropTypes.shape({
          pagination: PropTypes.shape({
            currentPage: PropTypes.number,
            prevPage: PropTypes.number,
            pageSize: PropTypes.number,
            lastPage: PropTypes.number,
            totalItems: PropTypes.number,
          }),
          issues: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              state: PropTypes.string,
              labels: PropTypes.arrayOf(PropTypes.string),
              issue_type: PropTypes.string,
              web_url: PropTypes.string,
              due_date: PropTypes.string,
              type: PropTypes.string,
              has_tasks: PropTypes.bool,
              task_status: PropTypes.string,
              created_at: PropTypes.string,
              severity: PropTypes.string,
              milestone: PropTypes.arrayOf(
                PropTypes.shape({
                  title: PropTypes.string,
                  web_url: PropTypes.string,
                  due_date: PropTypes.string,
                  state: PropTypes.string,
                }),
              ),
              mergeRequests: PropTypes.arrayOf(
                PropTypes.shape({
                  title: PropTypes.string,
                  web_url: PropTypes.string,
                  labels: PropTypes.arrayOf(PropTypes.string),
                  merge_status: PropTypes.string,
                  state: PropTypes.string,
                }),
              ),
              time: PropTypes.shape({
                time_estimate: PropTypes.number,
                total_time_spent: PropTypes.number,
                human_time_estimate: PropTypes.string,
                human_total_time_spent: PropTypes.string,
              }),
            }),
          ),
        }),
      }),
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.paginationCallback = this.paginationCallback.bind(this);
  }

  async paginationCallback(current, pageSize, gitlabUser) {
    await this.props.gitlabUserQuery.refetch({
      filter: { page: current, pageSize },
      userId: gitlabUser.id,
    });
  }

  render() {
    const { gitlabUserQuery: { gitlabUser, loading } } = this.props;

    if (!gitlabUser)
      return (
        <>
          <tbody>
            <tr>
              <td colSpan={10} align="center">
                <Loader />
              </td>
            </tr>
          </tbody>
        </>
      );

    return (
      <>
        <tbody>
          {gitlabUser.gitlabIssues.issues.map(issue => (
            <tr key={issue.id} className={s.issueRow}>
              <td className={s.bigColumn}>{issue.title}</td>
              <td className={s.smallColumn}>
                {issue.labels.length !== 1
                  ? issue.labels.map(
                      (label, index) =>
                        index === issue.labels.length - 1
                          ? label
                          : `${label}, `,
                    )
                  : issue.labels[0]}
              </td>
              <td className={s.smallColumn}>
                {`${issue.time.human_total_time_spent || 0} ${
                  issue.time.human_time_estimate
                    ? `| ${issue.time.human_time_estimate}`
                    : ''
                }`}
              </td>
              <td className={s.smallColumn}>
                {moment(issue.created_at)
                  .format('YYYY-MM-DD HH:mm')
                  .toString()}
              </td>
              <td className={s.smallColumn}>{issue.due_date}</td>
              <td className={`${s.smallColumn}`}>
                <div title={issue.references.short} />
                <Link to={issue.web_url}>{issue.references.short}</Link>
                <div />
              </td>
              <td className={`${s.smallColumn}`}>
                {issue.mergeRequests.map(mr => (
                  <>
                    <div title={mr.references.short} />
                    <Link to={mr.web_url}>{mr.references.short}</Link>
                    <div />
                  </>
                ))}
              </td>
              <td className={`${s.smallColumn}`}>
                {issue.milestone ? (
                  <td className={`${s.bigColumn} ${s.hideOnSmall}`}>
                    <div title={issue.milestone.title} />
                    <Link to={issue.milestone.web_url}>
                      {issue.milestone.title}
                    </Link>
                    <div />
                  </td>
                ) : (
                  <div />
                )}
              </td>
              <td className={`${s.bigColumn} ${s.hideOnSmall}`}>
                <div
                  title={issue.description}
                  className={`${s.description} ${s.hideOnSmall}`}
                >
                  {issue.description}
                </div>
              </td>
            </tr>
          ))}
          <tr
            className={`${s.hoverDisabled} ${gitlabUser.gitlabIssues.issues
              .length === 0 && s.seperatorEmpty}`}
          >
            <td colSpan={10} />
          </tr>
          {loading && (
            <div className={s.loaderWrapper}>
              <Loader />
            </div>
          )}
        </tbody>
        {gitlabUser.gitlabIssues.issues.length !== 0 && (
          <tr className={s.paginationWrapper}>
            <td colSpan={10} align="center">
              {
                <Pagination
                  onChange={(current, pageSize) =>
                    this.paginationCallback(current, pageSize, gitlabUser)
                  }
                  simple
                  disabled={loading}
                  defaultPageSize={10}
                  hideOnSinglePage
                  currentPage={gitlabUser.gitlabIssues.pagination.currentPage}
                  pageSize={gitlabUser.gitlabIssues.pagination.pageSize}
                  total={gitlabUser.gitlabIssues.pagination.totalItems}
                  prevIcon={<FaArrowLeft />}
                  nextIcon={<FaArrowRight />}
                  locale="de_DE"
                />
              }
            </td>
          </tr>
        )}
      </>
    );
  }
}

export default compose(
  graphql(gitlabUserQuery, {
    name: 'gitlabUserQuery',
    options: props => ({
      variables: {
        filter: {
          pageSize: 10,
          page: 1,
        },
        userId: props.gitlabUser.id,
      },
    }),
  }),
)(withStyles(s, reactTableStyle)(Issues));
