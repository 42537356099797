/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { FaPlus, FaEdit } from 'react-icons/fa';
import { Row, Col, ToggleButton } from 'react-bootstrap';

import Loading from 'components/Loading';
import tableMessages from 'components/Table/messages';
import Table from 'components/Table';

import messages from './messages';
import layoutStyle from '../../styles/base/layout.scss';

class UserTable extends React.Component {
  static propTypes = {
    oAuthProviderQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      currentOAuthProvider: PropTypes.string.isRequired,
    }).isRequired,
    usersQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          username: PropTypes.string.isRequired,
          active: PropTypes.bool.isRequired,
        }),
      ),
    }).isRequired,
    updateActive: PropTypes.func.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.createColumns = this.createColumns.bind(this);
    this.createToggleButton = this.createToggleButton.bind(this);
  }

  async invertActive(args) {
    const userActiveInput = {
      id: args.id,
      active: !args.active,
    };
    await this.props.updateActive(userActiveInput);
  }

  createToggleButton(args) {
    const message = args.active ? tableMessages.active : tableMessages.inactive;
    return (
      <ToggleButton
        className="tgl-btn"
        checked={args.active}
        type="checkbox"
        onChange={() => this.invertActive(args)}
        style={{ width: '125px' }}
      >
        {<FormattedMessage {...message} />}
      </ToggleButton>
    );
  }

  createColumns() {
    const { match: { path }, intl } = this.props;
    const columns = [
      {
        Header: <FormattedMessage {...messages.username} />,
        width: 350,
        Cell: ({ original }) => <span>{original.username}</span>,
        filterable: true,
        filterMethod: (filter, row) => {
          const filterString = filter.value.toLowerCase();
          const name = row._original.username.toLowerCase(); // eslint-disable-line no-underscore-dangle

          return name.indexOf(filterString) !== -1;
        },
      },
      {
        Header: <FormattedMessage {...tableMessages.actions} />,
        width: 210,
        Cell: ({ original }) => (
          <Link to={`${path}${original.username}/edit`}>
            <FaEdit />
          </Link>
        ),
      },
      {
        Header: <FormattedMessage {...messages.active} />,
        accessor: 'active',
        id: 'status',
        width: 510,
        Cell: ({ original }) => this.createToggleButton(original),
        filterable: true,
        filterMethod: (filter, row) => {
          if (filter.value === 'all') {
            return true;
          }
          if (filter.value === 'active') {
            return row[filter.id] === true;
          }
          return row[filter.id] === false;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : 'all'}
          >
            <option value="all">{intl.formatMessage(messages.all)}</option>
            <option value="active">
              {intl.formatMessage(messages.active)}
            </option>
            <option value="inactive">
              {intl.formatMessage(messages.inactive)}
            </option>
          </select>
        ),
      },
    ];

    return columns;
  }

  render() {
    const { usersQuery: { loading, users }, match: { path } } = this.props;
    if (loading) return <Loading />;

    const { oAuthProviderQuery } = this.props;
    let addUser = null;
    if (
      !oAuthProviderQuery.loading &&
      oAuthProviderQuery.currentOAuthProvider === 'local'
    ) {
      addUser = (
        <Row className={layoutStyle.noMargin} style={{ marginBottom: '15px' }}>
          <Col xs={12} className={layoutStyle.noPadding}>
            <Link to={`${path}new`} className="btn btn-primary">
              <FaPlus className="icon--prepended icon-button" />
              <FormattedMessage {...messages.addUser} />
            </Link>
          </Col>
        </Row>
      );
    }
    const columns = this.createColumns();
    return (
      <div>
        {addUser}
        <Row className={layoutStyle.noMargin}>
          <Col xs={12} className={layoutStyle.noPadding}>
            <Table
              keyField="username"
              data={users}
              columns={columns}
              defaultFiltered={[{ id: 'status', value: 'active' }]}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export const usersQuery = gql`
  query users {
    users {
      id
      username
      active
    }
  }
`;

const oAuthProviderQuery = gql`
  query oAuthProviderQuery {
    currentOAuthProvider
  }
`;

const updateActiveMutation = gql`
  mutation updateUser($user: UserActiveInput!) {
    updateActive(user: $user) {
      id
      active
    }
  }
`;

export default compose(
  graphql(usersQuery, {
    name: 'usersQuery',
  }),
  graphql(oAuthProviderQuery, {
    name: 'oAuthProviderQuery',
  }),
  graphql(updateActiveMutation, {
    props: ({ mutate }) => ({
      updateActive: user =>
        mutate({
          variables: { user },
        }),
    }),
  }),
)(injectIntl(UserTable));
